import React from 'react';

function MyWallet() {
 
  return (
    <div>
      <h2><strong>My Wallet</strong></h2>
    
      <h3>Available Balance in Wallet:</h3>
  
    </div>
);
    
}

export default MyWallet;