import React, { useState } from 'react';

function TrackOrders() {
  const [orderNumber, setOrderNumber] = useState('');
  const [email, setEmail] = useState('');
  const [orderStatus, setOrderStatus] = useState(null);

  const handleOrderNumberChange = (e) => {
    setOrderNumber(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (orderNumber === '123456' && email === 'example@email.com') {
      setOrderStatus('Shipped');
    } else {
      setOrderStatus('Not Found');
    }
  };

  return (
    <div className="form-group text_box">
      <h2><strong>Track Order, Schedule Delivery, Start Return</strong></h2>
      <form onSubmit={handleFormSubmit}>
        <input className='form-control'
            type="text"
            value={orderNumber}
            onChange={handleOrderNumberChange}
            placeholder="Enter Order Number"
          />
       
        <br />
          <input className='form-control'
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Enter Email"
          />
        <br />
        <button className="common-btn"type="submit">Track Order</button>
      </form>
      {orderStatus !== null && (
        <p>
          Order Status: <strong>{orderStatus}</strong>
        </p>
      )}
    </div>
  );
}

export default TrackOrders;
