import React, {Component} from "react";
import LDRtoAEC from "../../video/Ldr To Aec.mp4";
import LCDtoAEC from "../../video/Lcd to Aec.mp4";
import withRouter from "../../hooks/withRouter";


class OurCurriculum extends Component {
    render(){
        return(
            <section className="tracking_product_area sec_pad">
                <div className="container">
                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>How to connect LDR sensor to AEC Microcontroller Board</h3>
                                    <p>Unlock the world of light-dependent resistors (LDR) with our step-by-step guide on connecting this sensor to an AEC Board. Dive into the fascinating realm of electronics as we walk you through the process, demystifying the connections and empowering you to harness the power of light sensing technology effortlessly. Watch and learn how to integrate LDR sensors seamlessly with AEC Boards, opening doors to exciting possibilities in your projects.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <video controls className="curriculum-video">
                                    <source src={LDRtoAEC} type="video/mp4" />
                                    Your browser does not support the video.
                                </video>
                            </div>
                        </div>
                    </div>

                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <video controls className="curriculum-video">
                                    <source src={LCDtoAEC} type="video/mp4" />
                                    Your browser does not supports the video.
                                </video>
                            </div>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>How to integrate LCD(I2C) Module to AEC Microcontroller Board</h3>
                                    <p>Unlock the potential of your AEC Microcontroller Board by learning how to seamlessly integrate an I2C LCD Module with this easy-to-follow tutorial! In this video, we'll guide you through the step-by-step process of connecting your LCD module to the AEC Microcontroller, enabling you to display information with clarity and precision.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default withRouter(OurCurriculum);
