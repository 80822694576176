import React, {Component} from 'react';

class ProductFilter extends Component {
    render() {
    var {listOfCategory,filterCategoryTypes,setFilterCategoryTypes,filterPriceRange,setFilterPriceRange,listOfBudgetType,filterBudgetType,setFilterBudgetType, setFromClassFilter, fromClassFilter, setToClassFilter,toClassFilter} = this.props;
        const handleOnChangeCategoryFilter = (data) => {
            const isChecked = filterCategoryTypes.some(checkedCheckbox => checkedCheckbox === data?.id)
            if (isChecked) {
                setFilterCategoryTypes(
                    filterCategoryTypes.filter(
                        (checkedCheckbox) => checkedCheckbox !== data?.id
                    )
                );
            } else {
                setFilterCategoryTypes(filterCategoryTypes.concat(data?.id));
            }

        };
        const handleOnChangeBudgetTypeFilter = (data) => {
            const isChecked = filterBudgetType.some(checkedCheckbox => checkedCheckbox === data?.id)
            if (isChecked) {
                setFilterBudgetType(
                    filterBudgetType.filter(
                        (checkedCheckbox) => checkedCheckbox !== data?.id
                    )
                );
            } else {
                setFilterBudgetType(filterBudgetType.concat(data?.id));
            }

        };
        const handleOnChangeClassTypeFilter = (data) => {


        };
        const handleOnChangePricingFilter = (data) => {
            const isChecked = filterPriceRange.some(checkedCheckbox => checkedCheckbox === data)
            if (isChecked) {
                setFilterPriceRange(
                    filterPriceRange.filter(
                        (checkedCheckbox) => checkedCheckbox !== data
                    )
                );
            } else {
                setFilterPriceRange(filterPriceRange.concat(data));
            }

        };

        return (
            <>
                <div>
                    {/*/////// CATEGORY FILTER SECTION////*/}
                    <section className="product-slider-panel product-filter-section">
                        <header className="panel-heading"> Type</header>
                        <ul className="filter_checkbox_list pl-25 pr-25 ">
                            {
                                listOfBudgetType.map((budgeTypeContent, index) => (
                                    <li key={index+'_'+budgeTypeContent?.name} className="filter_checkbox_tab">
                                        <label className="animcheck" htmlFor={index+'_'+budgeTypeContent?.name}>
                                            <input className="animinput ckbtype"
                                                   type="checkbox"
                                                   id={index+'_'+budgeTypeContent?.name}
                                                   checked={filterBudgetType.some(checkedCheckbox => checkedCheckbox === budgeTypeContent?.id)}
                                                   onChange={(e)=>handleOnChangeBudgetTypeFilter(budgeTypeContent)}
                                                   name="budgetType"
                                                   value={budgeTypeContent?.id} />
                                            <div className="animlabel"><span className="animicon"></span><span
                                                className="animtext">{budgeTypeContent?.name}</span></div>
                                        </label>
                                    </li>
                                ))
                            }

                        </ul>
                    </section>
                    {/*/////// CATEGORY FILTER SECTION////*/}
                     {/*/////// CLASS TYPE CATEGORY FILTER SECTION////*/}
                    <section className="product-slider-panel pl-0">
                        <header className="panel-heading">For Class</header>
                        <div className={"mt-2 "}>From </div>
                        <select name="fromClass" className={"form-control"} id="fromClassFilter" value={fromClassFilter} onChange={(e)=>setFromClassFilter(e.target.value)}>
                        <option value="">Select</option>
                        <option value="1">1St</option>
                        <option value="2">2nd</option>
                        <option value="3">3rd</option>
                        <option value="4">4th</option>
                        <option value="5">5th</option>
                        <option value="6">6th</option>
                        <option value="7">7th</option>
                        <option value="8">8th</option>
                        <option value="9">9th</option>
                        <option value="10">10th</option>
                        <option value="11">11th</option>
                        <option value="12">12th</option>
                        </select>
                        <div className={"mt-2"}> To </div>
                        <select name="toClass" id="toClassFilter" className={"form-control"} value={toClassFilter} onChange={(e)=>setToClassFilter(e.target.value)}>
                            <option value="">Select</option>
                            <option value="1">1St</option>
                            <option value="2">2nd</option>
                            <option value="3">3rd</option>
                            <option value="4">4th</option>
                            <option value="5">5th</option>
                            <option value="6">6th</option>
                            <option value="7">7th</option>
                            <option value="8">8th</option>
                            <option value="9">9th</option>
                            <option value="10">10th</option>
                            <option value="11">11th</option>
                            <option value="12">12th</option>
                        </select>
                    </section>
                    {/*/////// CATEGORY FILTER SECTION////*/}

                    {/*/////// CATEGORY FILTER SECTION////*/}
                    <section className="product-slider-panel">
                        <header className="panel-heading"> Category</header>
                        <ul className="filter_checkbox_list pl-25 pr-25 ">
                            {
                                listOfCategory.map((categoryContent, index) => (

                                    <li key={index+'_'+categoryContent?.name} className="filter_checkbox_tab">
                                        <label className="animcheck" htmlFor={index+'_'+categoryContent?.name}>
                                            <input className="animinput ckbtype"
                                                   type="checkbox"
                                                   id={index+'_'+categoryContent?.name}
                                                   checked={filterCategoryTypes.some(checkedCheckbox => checkedCheckbox === categoryContent?.id)}
                                                   onChange={(e)=>handleOnChangeCategoryFilter(categoryContent)}
                                                   name="btype"
                                                   value={categoryContent?.id} />
                                            <div className="animlabel"><span className="animicon"></span><span
                                                className="animtext">{categoryContent?.name}</span></div>
                                        </label>
                                    </li>
                                ))
                            }

                        </ul>
                    </section>
                    {/*/////// CATEGORY FILTER SECTION////*/}
                </div>
                <div className="col-md-12">
                    <section className="product-slider-panel">
                        <header className="panel-heading">Pricing</header>
                        <ul className="filter_checkbox_list pl-25 pr-25 ">
                            <li className="filter_checkbox_tab">
                                <label className="animcheck" htmlFor="price0">
                                    <input className="animinput ckbtype" type="checkbox" id="price0"
                                           checked={filterPriceRange.some(checkedCheckbox => checkedCheckbox === '0-5000')}
                                           onChange={(e)=>handleOnChangePricingFilter('0-5000')}
                                           name="btype" value="0-5000"  />
                                    <div className="animlabel"><span className="animicon"></span><span
                                        className="animtext">Under ₹ 5000</span></div>
                                </label>
                            </li>
                            <li className="filter_checkbox_tab">
                                <label className="animcheck" htmlFor="price1">
                                    <input className="animinput ckbtype" type="checkbox" id="price1"
                                           checked={filterPriceRange.some(checkedCheckbox => checkedCheckbox === '5000-10000')}
                                           onChange={(e)=>handleOnChangePricingFilter('5000-10000')}
                                           name="btype" value="5000-10000" />
                                    <div className="animlabel"><span className="animicon"></span><span
                                        className="animtext font14 color414 ">₹ 5000 - ₹ 10,000</span>
                                    </div>
                                </label>
                            </li>
                            <li className="filter_checkbox_tab">
                                <label className="animcheck" htmlFor="price2">
                                    <input className="animinput ckbtype" type="checkbox" id="price2"
                                           checked={filterPriceRange.some(checkedCheckbox => checkedCheckbox === '10000-20000')}
                                           onChange={(e)=>handleOnChangePricingFilter('10000-20000')}
                                           name="btype" value="10000-20000" />
                                    <div className="animlabel"><span className="animicon"></span><span
                                        className="animtext font14 color414 ">₹ 10,000 - ₹ 20,000</span>
                                    </div>
                                </label>
                            </li>
                            <li className="filter_checkbox_tab">
                                <label className="animcheck" htmlFor="price3">
                                    <input className="animinput ckbtype" type="checkbox" id="price3"
                                           checked={filterPriceRange.some(checkedCheckbox => checkedCheckbox === '20000-30000')}
                                           onChange={(e)=>handleOnChangePricingFilter('20000-30000')}
                                           name="btype" value="20000-30000" />
                                    <div className="animlabel"><span className="animicon"></span><span
                                        className="animtext font14 color414 ">₹ 20,000 - ₹ 30,000</span>
                                    </div>
                                </label>
                            </li>
                            <li className="filter_checkbox_tab">
                                <label className="animcheck" htmlFor="price4">
                                    <input className="animinput ckbtype" type="checkbox" id="price4"
                                           checked={filterPriceRange.some(checkedCheckbox => checkedCheckbox === '30001-99999999')}
                                           onChange={(e)=>handleOnChangePricingFilter('30001-99999999')}
                                           name="btype" value="30000-99999999" />
                                    <div className="animlabel"><span className="animicon"></span><span
                                        className="animtext font14 color414 ">Over ₹ 3,0000</span></div>
                                </label>
                            </li>
                        </ul>
                    </section>
                </div>
              {/*  <div className="col-md-12">
                    <section className="product-slider-panel">
                        <header className="panel-heading">Class wise</header>
                        <ul className="filter_checkbox_list pl-25 pr-25 ">
                            <li className="filter_checkbox_tab">
                                <label className="animcheck" htmlFor="btype3">
                                    <input className="animinput ckbtype" type="checkbox" id="btype3"
                                           name="btype" value="sellertype|Exporters" />
                                    <div className="animlabel"><span className="animicon"></span><span
                                        className="animtext font14 color414 ">Exporters</span></div>
                                </label>
                            </li>
                            <li className="filter_checkbox_tab">
                                <label className="animcheck" htmlFor="btype4">
                                    <input className="animinput ckbtype" type="checkbox" id="btype4"
                                           name="btype" value="sellertype|Wholesalers" />
                                    <div className="animlabel"><span className="animicon"></span><span
                                        className="animtext font14 color414 ">Wholesalers</span></div>
                                </label>
                            </li>
                            <li className="filter_checkbox_tab">
                                <label className="animcheck" htmlFor="btype5">
                                    <input className="animinput ckbtype" type="checkbox" id="btype5"
                                           name="btype" value="sellertype|Dealers" />
                                    <div className="animlabel"><span className="animicon"></span><span
                                        className="animtext font14 color414 ">Dealers</span></div>
                                </label>
                            </li>
                        </ul>
                    </section>
                </div>*/}
                <div className="col-md-12">
                    <section className="product-slider-panel">
                        <header className="panel-heading">Availability</header>
                        <ul className="filter_checkbox_list pl-25 pr-25 ">
                            <li className="filter_checkbox_tab">
                                <label className="animcheck" htmlFor="avalibilty">
                                    <input className="animinput ckbtype" type="checkbox" id="avalibilty"
                                           name="btype" value="sellertype|All" />
                                    <div className="animlabel"><span className="animicon"></span><span
                                        className="animtext">Include Out of Stock</span></div>
                                </label>
                            </li>
                        </ul>
                    </section>
                </div>
            </>
        )
    }
}

export default ProductFilter;
