import React, {Component} from "react";
import CustomNavbar from "../components/layout/CustomNavbar";
import Footer from "../components/layout/Footer";
import Product from "../components/product/Product";

class Products extends Component {
    render() {
        return (
            <div className="body_wrapper">
                <CustomNavbar mClass="menu_four hosting_menu" nClass="w_menu m-auto" slogo="sticky_logo" hbtnClass="event_btn"/>
                
                <Product/>
                <Footer/>
            </div>
        )
    }
}
export default Products;
