import {
    CATEGORY_DATA_CP,
    POST_DATA,
    SUB_CATEGORY_LIST,
    SUB_CHILD_CATEGORY_LIST,
    PRODUCT_DETAILS_DATA_CP,
    CLASS_TYPE_DATA_CP,
    SEO_META_DATA,
    URL_SLUG_DATA,
    BRAND_DATA,
    PRODUCT_DATA_CP, BUDGET_TYPE_DATA_CP,
    PRODUCT_REVIEW_DATA
} from "../constant/productConstant";

const initialState = {
    sidebarShow: true,
    sidebarUnfoldable:true,
    postData:[],
    brandData:[],
    categoryData:[],
    listOfBudgetType:[],
    listOfClassType:[],
    productData:{data:[],loading:true},
    productDetailsData:{data:[],loading: true},
    subCategoryData:[],
    subChildCategoryData:[],
    seoMetaData:{},
    urlSlug:{}
}
export  const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'set':
            return { ...state, ...action.rest }
        case POST_DATA:
            return { ...state, postData: action.payload };
        case BRAND_DATA:
            return { ...state, brandData: action.payload };
         case CATEGORY_DATA_CP:
            return { ...state, categoryData: action.payload };
         case BUDGET_TYPE_DATA_CP:
            return { ...state, listOfBudgetType: action.payload };
        case CLASS_TYPE_DATA_CP:
            return { ...state, listOfClassType: action.payload };
        case PRODUCT_DATA_CP:
            return { ...state, productData: action.payload };
        case PRODUCT_DETAILS_DATA_CP:
            return { ...state, productDetailsData: action.payload };
        case SUB_CATEGORY_LIST:
            return { ...state, subCategoryData: action.payload };
        case SUB_CHILD_CATEGORY_LIST:
            return { ...state, subChildCategoryData: action.payload };
        case SEO_META_DATA:
            return { ...state, seoMetaData: action.payload };
        case URL_SLUG_DATA:
            return { ...state, urlSlug: action.payload };
        case PRODUCT_REVIEW_DATA:
            return { ...state, selectedProductReviewData: action.payload };
        default:
            return state
    }
}
