import React, { useEffect, useState} from "react";
import { useParams} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import CustomNavbar from "../components/layout/CustomNavbar";
import PreLoader from "../components/product/PreLoader";
import Footer from "../components/layout/Footer";
import {useDispatch, useSelector} from "react-redux";
import ProductComming from "../components/product/ProductComming";
import Productimg from "../components/product/productimg";
import ProductRelated from "../components/product/ProductRelated";
import "../card.css";
import {useEffectOnce} from "../hooks/useEffectOnce";
import {actionToGetProductDetailsDataApiCall} from "../store/actions/productAction";
import CryptoJS from "crypto-js";
import ProductRatingAndReviews from "../components/product/ProductRatingReviews";
import ProductFaq from "../components/product/ProductFaq";

const ENCRYPTION_KEY = "XkhZG4fW2t2W";

export default function CommingSoon() {
    let para = useParams();
    const [loadingData,setLoadingData]= useState(true);
    const productDetailsData = useSelector((state) => state.productReducer.productDetailsData);

    let id= Object.values(para)[0];
    let dispatch = useDispatch();
    useEffectOnce(()=>{
        let decryptId = CryptoJS.AES.decrypt(id, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
        dispatch(actionToGetProductDetailsDataApiCall({'id':decryptId}))
    })
    useEffect(() => {
        if(productDetailsData?.data){
            setTimeout(function(){
                setLoadingData(productDetailsData?.loading);

            },500)
        }

    },[productDetailsData])

        return (
            <div className="body_wrapper">
                <CustomNavbar mClass="menu_four hosting_menu" nClass="w_menu m-auto" slogo="sticky_logo" hbtnClass="event_btn"/>

                <section className="sec_without_breadcrumb">
                <div className="container_account container-fluid">
                    <div className="container">
                        <div className="row">
                        <div className="col-md-12 account-heading-title"><h1 className="mb-0">Product Overview Details</h1></div>
                        </div>
                     </div>
                </div>


                    {
                        loadingData ? <div className={"container mt-5"}>
                <div className="row g-0">
                    <div className={"col-md-12"}> <PreLoader /></div>
                </div></div>:<>

            <div className={"container custom_container p0 mt-5"}>
                <div className={"row"}>
                  <div className={"col-md-4"}>
                        <Productimg productDetailsData={productDetailsData?.data}/>
                  </div>
                    <div className={"col-md-8"}>
                     <ProductComming productDetailsData={productDetailsData?.data}/>
                 </div>
                </div>
                <ProductRelated relatedProduct={productDetailsData?.data?.related_products}/>
                <ProductRatingAndReviews productDetailsData={productDetailsData?.data}/>
                <ProductFaq productDetailsData={productDetailsData?.data}/>
            </div>
        </>
        }
        </section>

    
                <Footer/>
                </div>
            
            
        );
};


