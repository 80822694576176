import React from 'react';
import CustomNavbar from "../components/layout/CustomNavbar";
import SignUpForm from '../components/signup/SignUpForm';
import Footer from "../components/layout/Footer";
import {useNavigate} from 'react-router-dom';
import SignUpImage from "../components/signup/SignUpImage";
import {useEffectOnce} from "../hooks/useEffectOnce";
import SignInImage from "../components/signup/SignInImage";
import LoginForm from "../components/Login/LoginForm";

const SignUp = () => {
    const navigate = useNavigate();
    useEffectOnce(()=>{
        if(localStorage.getItem('user')){
            navigate("/");
        }

    })
    return(
        <>
            <CustomNavbar mClass="menu_four hosting_menu" nClass="w_menu m-auto" slogo="sticky_logo" hbtnClass="event_btn"/>
            <section className="sign_in_area bg_color sec_without_breadcrumb sign_info">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 pt-5 pb-5">
                            <div className={"container"}>
                                <div className="row">
                                    <div className="col-lg-5">
                                        <SignUpImage/>
                                    </div>
                                    <div className={"col-md-7"}>
                                        <SignUpForm/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}
export default SignUp;
