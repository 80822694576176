import React, {Component} from "react";
import CustomNavbar from "../../components/layout/CustomNavbar";
import Breadcrumb from "../../components/layout/Breadcrumb";
import Footer from "../../components/layout/Footer";
import CreatedSolutionsContent from "../curriculum/CreatedSolutionsContent";

class CreatedSolutions extends Component {
    render() {
        return (
            <div className="body_wrapper">
                <CustomNavbar mClass="menu_four hosting_menu" nClass="w_menu m-auto" slogo="sticky_logo" hbtnClass="event_btn"/>
                <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Our Work" Pdescription="Some glimpse of our Created Solutions"/>
                <CreatedSolutionsContent/>
                <Footer/>
            </div>
        )
    }
}
export default CreatedSolutions;
