import React from 'react'

function PdfPreview4() {
  return (
    <div>
      <h5 className='mb-4 container fw-bold'>Shipping Information</h5>
      <h6 className='mb-2 container fw-bold'>Name</h6>
      <h6 className='mb-4 container text-dark'>Name</h6>
      <h6 className='mb-2 container fw-bold'>Address</h6>
      <h6 className='mb-4 container text-dark'>nbffvhedgr sdchch sdvh</h6>
    </div>
  )
}

export default PdfPreview4
