import React, {useState} from 'react';
import moment from "moment/moment";
import {useDispatch}  from "react-redux";
import OrderTracking from "./OrderTracking";
import {
    actionToDownloadInvoicePDFByOrderId,
    actionToOpenPayAndUploadInvoiceModal
} from "../../store/actions/shopAction";
import {useParams} from "react-router-dom";
import PayAndUploadInvoiceComponent from "../payment/PayAndUploadInvoiceComponent";
import {useEffectOnce} from "../../hooks/useEffectOnce";
import {NEW_ORDER_PLACE} from "../../store/constant/cartConstant";

function MyOrdercard({userCurrentOrderData}) {

    let param = useParams();
    let encryptedId = Object.values(param)[0];
    const orderDate = userCurrentOrderData?.[0]?.orderDate;
    const dispatch  = useDispatch();
    console.log(userCurrentOrderData,"orderDate")
    const payAndUploadReceipt=(orderData)=>{
        dispatch(actionToOpenPayAndUploadInvoiceModal(orderData));
    }
    useEffectOnce(()=>{
        dispatch({ type: NEW_ORDER_PLACE, payload:{loading:true,data:[]} });
    });
    const downloadInvoicePdfByOrderId = (id) =>{
        dispatch(actionToDownloadInvoicePDFByOrderId({id,isFileFormat:false}));
    }
    // const onFileChange = (event) => {
    //     if(event.target.files[0]) {
    //         setPaymentReceipt(event.target.files[0]);
    //         setFileError("");
    //     }else{
    //         setFileError("Please upload a file.")
    //     }
    // };
    // const uploadReceipt =()=>{
    //     if(paymentReceipt) {
    //         dispatch(actionToUploadPaymentReceipt({
    //             photo: paymentReceipt,
    //             id: userCurrentOrderData?.id
    //         })).then((response) => {
    //             console.log(response, "response");
    //             if (response !== '') {
    //                 dispatch(actionGetOrderDetailsData({'id':encryptedId}));
    //                 toast.success('Payment receipt is uploaded successfully. Wait for admin approval.', {
    //                     position: toast.POSITION.BOTTOM_LEFT
    //                 });
    //             }
    //         });
    //     }else{
    //         setFileError("Please  upload a file.")
    //     }
    // }
  return (
    <div>
        <div className={"alert alert-success text-center"}>
            <h5>Your Order has been processed Successfully!</h5>
            {(userCurrentOrderData?.paymentStatus ==='0' && userCurrentOrderData?.direct_payment_transaction_id && !userCurrentOrderData?.direct_payment_attachment_path) ? <h5>Kindly make payment and upload payment receipt to complete your Order.</h5>:""}
        </div>
       <table className="table mb-3 m-0" border={"1"}>
          <thead>
            <tr>
              <th>
                  <h5 className='mb-0'>Order ID: {userCurrentOrderData?.order_number}</h5>
              </th>
                <th className={"text-end"}>
                    <div className={'d-inline-flex align-items-center'}>
                    <h6 className='mb-0'>Order date: {moment(orderDate).format("ll")}</h6>
                    <button className='common-btn1' onClick={()=>downloadInvoicePdfByOrderId(userCurrentOrderData?.id)}><svg xmlns="http://www.w3.org/2000/svg" width={"20"} viewBox="0 0 640 512" fill={"#fff"}><path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-167l80 80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39 39V184c0-13.3-10.7-24-24-24s-24 10.7-24 24V318.1l-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9z"/></svg> Download Invoice</button>
                    </div>
                </th>
            </tr>
          </thead>
           {
               (userCurrentOrderData?.products?.length > 0) ?
                   userCurrentOrderData?.products.map((productData, index) => {
                       return (
                           <tbody key={'order_item'+index}>
                           <tr>
                                <td scope="row">
                                    <div className="d-flex">
                                       <img src={productData?.photo} height={150} width={150}   alt="..."/>
                                       <h6 className='mt-2'>{productData?.name}</h6>
                                    </div>
                                </td>
                                <td className='mt-3 text-end'>
                                   <div className={"border-bottom p-3"}>
                                       {/*<input type="text" className="form-control" id="inputZip" placeholder='Qty' value={orderData?.productQty} readOnly />*/}
                                       <span><b>Quantity:</b>  {productData?.qty}</span>
                                   </div>
                                    <div className={'p-3'}><b>₹</b> {productData?.unite_price*productData?.qty}</div>
                               </td>
                           </tr>
                           </tbody>
                       )
                   })
                   : ''

           }
       </table>
       <div className={"container-fluid p-0 mb-5"}>
               <div className={"row"}>
                   <div className={"col-md-6"}>
                       <div className={'gray-box-light'}>
                       <h5 className='mb-3'>Total Amount</h5>
                       <div className='mb-3'>₹{parseFloat(parseFloat(parseFloat(userCurrentOrderData?.netAmount)-parseFloat(userCurrentOrderData?.shippingCharge))+parseFloat(userCurrentOrderData?.discountAmount)).toFixed(2)}</div>
                       {(userCurrentOrderData?.discountCoupon)?
                           <>
                       <h5 className='mb-3'>Discount Amount</h5>
                       <div className='mb-3'>-₹{userCurrentOrderData?.discountAmount}
                       <span> ({userCurrentOrderData?.discountCoupon})</span>
                       </div></>
                           :""}
                           {(parseFloat(userCurrentOrderData?.shippingCharge)>0)?
                               <>
                                   <h5 className='mb-3'>Shipping Charge</h5>
                                   <div className='mb-3'>₹{userCurrentOrderData?.shippingCharge}
                                   </div></>
                               :""}
                       <h5 className='mb-3'>Net Amount</h5>
                       <div className='mb-3'>₹{userCurrentOrderData?.netAmount}</div>
                       <h5 className='mb-3'>Payment Status</h5>
                       <div className='mb-3'>{(userCurrentOrderData?.paymentStatus ==='1') ? "Paid":"Unpaid"}</div>
                       {(userCurrentOrderData?.paymentStatus ==='0' && userCurrentOrderData?.direct_payment_transaction_id && !userCurrentOrderData?.direct_payment_attachment_path) ?
                       <>
                       <div className={'mb-2'}>
                           <button className='common-btn m-0' onClick={()=>payAndUploadReceipt(userCurrentOrderData)}>Pay & Upload Invoice</button>
                       </div>

                       </>:""
                       }
                       </div>
                   </div>
                   <div className={"col-md-6"}>
                       <h5 className='mb-2'>Delivery Address</h5>
                       <div className='mb-3 gray-box-light'>
                           {userCurrentOrderData?.shiippingFullname}, {userCurrentOrderData?.shippingAddress}<br></br>
                           {userCurrentOrderData?.shippingCity}, {userCurrentOrderData?.shippingStates}, {userCurrentOrderData?.shippingCountry} {userCurrentOrderData?.shippingPincode}<br></br>
                           {userCurrentOrderData?.shippingPhoneNo}
                       </div>
                       <h5 className='mb-3'>Status</h5>
                        <div className='mb-3 w-100'><OrderTracking status={userCurrentOrderData?.status} /></div>
                   </div>
               </div>
           </div>

        <PayAndUploadInvoiceComponent/>
    </div>
  )
}

export default MyOrdercard
