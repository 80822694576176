import React, {useState} from 'react'
import profile from "../../image/profile.png";
import {useEffectOnce} from "../../hooks/useEffectOnce";
import {useNavigate} from "react-router-dom";
import AuthService from "../services/auth.service";


function MyAccountProfile() {
  const userInfo = localStorage.getItem('user')
  const [userData, setUserData] = useState([]);
  const navigate = useNavigate();
  useEffectOnce(()=>{
    let userRawData =   AuthService.parseJwt(JSON.parse(userInfo)?.accessToken);
    setUserData(userRawData?.user)
    setActiveMenu(window.location.href.substring(window.location.href.lastIndexOf('/') + 1));
  },[]);
  const [activeMenu, setActiveMenu] = useState('personal-info');
  const openUrl=(url)=>{
    setActiveMenu(url);
    navigate(`/my-account/${url}`)
  }
  const logout =async()=>{
    await AuthService.logout();
  }
  return (
    <>
      <div className={'myprofile'}>
        <div className='mb-3 text-center border-bottom pb-3'>
          <img src={profile} height={150} width={150}   alt="..."/>
        </div>
        <div className='mb-0 p-2 boo'>{userData?.name}</div>
        <div className='mb-0 p-2'>{userData?.email}</div>
      </div>
      <ul className={"list-group personal-info-list mb-4"}>
        <li className={(activeMenu ==='personal-info') ? 'list-group-item list-group-item-action active ':'list-group-item list-group-item-action'} onClick={()=>openUrl('personal-info')}>Personal Information</li>
        <li className={(activeMenu ==='order-history') ? 'list-group-item list-group-item-action active ':'list-group-item list-group-item-action'} onClick={()=>openUrl('order-history')}>Order History</li>
        <li className={(activeMenu ==='account-settings') ? 'list-group-item list-group-item-action active ':'list-group-item list-group-item-action'} onClick={()=>openUrl('account-settings')}>Account Settings</li>
        <li className={(activeMenu ==='billing-payments') ? 'list-group-item list-group-item-action active ':'list-group-item list-group-item-action'} onClick={()=>openUrl('billing-payments')}> Billing & Payments</li>
        {/*<li className={(activeMenu ==='my-wallet') ? 'list-group-item list-group-item-action active ':'list-group-item list-group-item-action'} onClick={()=>openUrl('my-wallet')}> My Wallet</li>*/}
        {/*<li className={(activeMenu ==='track-orders') ? 'list-group-item list-group-item-action active ':'list-group-item list-group-item-action'} onClick={()=>openUrl('track-orders')}> Track Orders/Schedule Delivery</li>*/}
        <li className={(activeMenu ==='gift-cards') ? 'list-group-item list-group-item-action active ':'list-group-item list-group-item-action'} onClick={()=>openUrl('gift-cards')}>Gift Cards</li>
      </ul>

    <button onClick={logout} className="common-btn btn-signout">Sign Out</button>
    </>
  )
}

export default MyAccountProfile
