import React, { useState, useEffect } from 'react';

const Contact = () => {
    const [result, setResult] = useState("");
    const [isFormValid, setIsFormValid] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
        contact_no: ''
    });

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    useEffect(() => {
        // Check if all fields are filled to enable submit button
        setIsFormValid(
            formData.name && formData.email && formData.contact_no && formData.subject && formData.message
        );
    }, [formData]);

    const submitForm = async (event) => {
        event.preventDefault();
        setResult("Sending....");

        const formDataToSend = new FormData();
        formDataToSend.append("access_key", "a2f335da-2b07-49c9-9131-0da54c972a4f");
        Object.keys(formData).forEach(key => {
            formDataToSend.append(key, formData[key]);
        });

        const response = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            body: formDataToSend
        });

        const data = await response.json();

        if (data.success) {
            setResult("We will contact you soon");
            setFormData({ name: '', email: '', subject: '', message: '', contact_no: '' });
        } else {
            console.log("Error", data);
            setResult(data.message);
        }
    };

    return (
        <>
            <h2 className="f_p f_size_22 t_color3 f_600 l_height28 mb_40">Book your Meeting Now...</h2>
            <form onSubmit={submitForm} className="contact_form_box" method="post" id="contactForm">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="form-group text_box">
                            <input
                                type="text"
                                id="name"
                                name="name"
                                required
                                placeholder="Your Name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group text_box">
                            <input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Your Email"
                                required
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group text_box">
                            <input
                                type="text"
                                id="contact_no"
                                name="contact_no"
                                required
                                placeholder="Contact No"
                                value={formData.contact_no}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group text_box">
                            <input
                                type="text"
                                id="subject"
                                name="subject"
                                placeholder="Subject"
                                value={formData.subject}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group text_box">
                            <textarea
                                name="message"
                                id="message"
                                cols="30"
                                rows="10"
                                placeholder="Enter Your Message . . ."
                                value={formData.message}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <button type="submit" className="btn_three" disabled={!isFormValid}>Send Message</button>
            </form>
            <span>{result}</span>
        </>
    );
};

export default Contact;