import React from 'react';

function ProductFaq({productDetailsData}) {
    let productFaqs =productDetailsData?.product_faqs;

    return (
        (productFaqs && productFaqs?.length>0) ?
            <>
                <div>
                    <div className="gray-box-light mt-5">
                        <section>

                            <h5 className='mb-5 border-bottom-1 pb-3 border-bottom-1'>Faqs:</h5>
                            <div className="card-group col-md-12">
                                <>
                                    {productFaqs?.map((row,key) => (
                                        <div className="d-block" key={key+row?.id}>
                                            <div className="card-body">
                                                <div className="faq-question price">{row?.question}</div>
                                                <div className="faq-question mt-2">{row?.answer}</div>
                                                <div className="mt-2"><span className="product-likes">Like: {row?.like_count}</span>&nbsp;&nbsp;&nbsp;&nbsp;<span className="product-dislikes">Dislike: {row?.dislike_count}</span></div>
                                            </div>
                                        </div>
                                    ))}
                                </>

                            </div>

                        </section>
                    </div>
                </div>
            </>:""
    );
};

export default ProductFaq
