import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faWhatsapp,
  faInstagram,
  faLinkedin,} from '@fortawesome/free-brands-svg-icons';
import './SocialSites.css';




function SocialSites() {
    return (
        <section>
        <div className="main1">
         <div className="social-icons">
      <a href="https://www.facebook.com/people/Shikshak-Solutions/100092264165249/" className="fb">
        <FontAwesomeIcon icon={faFacebookF} />
        <span>Facebook</span>
      </a>
      <a href="https://www.instagram.com/shikshaksolutions/" className="insta">
        <FontAwesomeIcon icon={faInstagram} />
        <span>Instagram</span>
      </a>
      <a href="https://www.linkedin.com" className="linkedin">
        <FontAwesomeIcon icon={faLinkedin} />
        <span>LinkedIn</span>
      </a>
      <a href="https://api.whatsapp.com/send/?phone=9599185056&text&type=phone_number&app_absent=0" className="whatsapp">
        <FontAwesomeIcon icon={faWhatsapp} />
        <span>WhatsApp</span>
      </a>
    </div>
    </div>
        </section>
    );
}
export default SocialSites;