import {
    USER_CART_DATA,
    NEW_ORDER_PLACE,
    UPDATE_CART_COUNT,
    USER_ORDERS,
    USER_SAVE_ADDRESSES,
    USER_CURRENT_ORDER_DATA, OPEN_CLOSE_PAY_UPLOAD_INVOICE_MODAL, ALL_PINCODE_DETAILS
} from "../constant/cartConstant";
import {ALL_STATE_LIST} from "../constant/commonConstant";

const INIT_STATE = {
    carts :[],
    cartsCount:0,
    userCartData:{data:[],loading:true},
    userOrdersData:[],
    placeNewOder:{loading:true,data:[]},
    userSavedAddressesData:[],
    userCurrentOrderData:[],
    openClosePayUploadInvoiceModal:{open:false,orderData:[]},
    allStateList:{data:[]},
};


export const cartReducer = (state=INIT_STATE,action)=>{
    switch(action.type){
        case "ADD_CART":
            const IteamIndex = state.carts.findIndex((iteam)=> iteam.id === action.payload.id);

            if(IteamIndex >= 0){
                state.carts[IteamIndex].qnty +=1
            }else{
                const temp = {...action.payload,qnty:1}
                return {
                    ...state,
                    carts:[...state.carts,temp]
                }
            }
        break;
        case "RMV_CART":
             const data = state.carts.filter((el)=>el.id !== action.payload);

             return {
                ...state,
                carts:data
             }

    case "RMV_ONE":
            const IteamIndex_dec = state.carts.findIndex((iteam)=> iteam.id === action.payload.id);
             if(state.carts[IteamIndex_dec].qnty >= 1){
                const dltitem = state.carts[IteamIndex_dec].qnty -= 1
                console.log([...state.carts,dltitem]);
                return{
                    ...state,
                    carts:[...state.carts]
                }
             }else if(state.carts[IteamIndex_dec].qnty === 1){

                const data = state.carts.filter((el)=>el.id !== action.payload);

                return {
                   ...state,
                   carts:data
                }
             }
            break;
        case UPDATE_CART_COUNT:
            return { ...state, cartsCount: action.payload };
        case USER_CART_DATA:
            return { ...state, userCartData: action.payload };
        case NEW_ORDER_PLACE:
            return { ...state, placeNewOder: action.payload };
        case USER_SAVE_ADDRESSES:
            return { ...state, userSavedAddressesData: action.payload };
        case USER_ORDERS:
            return { ...state, userOrdersData: action.payload };
        case USER_CURRENT_ORDER_DATA:
            return { ...state, userCurrentOrderData: action.payload };
        case ALL_PINCODE_DETAILS:
            return { ...state, allPinCodeData: action.payload };
        case OPEN_CLOSE_PAY_UPLOAD_INVOICE_MODAL:
            return { ...state, openClosePayUploadInvoiceModal: action.payload };
        case ALL_STATE_LIST:
            console.log(action?.payload,"action?.payload")
            return { ...state, allStateList: action.payload };

    default :
            return state
    }
}
