export const POST_DATA = "POST_DATA";
export const CATEGORY_DATA_CP = "CATEGORY_DATA_CP";
export const BUDGET_TYPE_DATA_CP = "BUDGET_TYPE_DATA_CP";
export const CLASS_TYPE_DATA_CP = "CLASS_TYPE_DATA_CP";
export const BRAND_DATA = "BRAND_DATA";
export const PRODUCT_DATA_CP = "PRODUCT_DATA_CP";
export const PRODUCT_DETAILS_DATA_CP = "PRODUCT_DETAILS_DATA_CP";
export const SUB_CATEGORY_LIST = "SUB_CATEGORY_LIST";
export const SUB_CHILD_CATEGORY_LIST = "SUB_CHILD_CATEGORY_LIST";
export const SEO_META_DATA = "SEO_META_DATA";
export const URL_SLUG_DATA = "URL_SLUG_DATA";
export const PRODUCT_REVIEW_DATA = "PRODUCT_REVIEW_DATA";



