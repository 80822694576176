import React, {Component} from "react";
import withRouter from "../../hooks/withRouter";
import BalVatika from "../../image/pmshri/balvatika.png";
import LinkageSector from "../../image/pmshri/linkagesector.png";
import Internship from "../../image/pmshri/internship.png";
import SkillSector from "../../image/pmshri/skillsector.png";
import DigitalLibrary from "../../image/pmshri/digitallibrary.png";
import MathsCircle from "../../image/pmshri/mathscircle.png";
import Agriculture from "../../image/pmshri/agriculture.png";
import STEM from "../../image/pmshri/stem.png";
import SportsKit from "../../image/pmshri/sports.png";
import ToyAccesories from "../../image/pmshri/toyaccesories.png";
import ToysLibrary from "../../image/pmshri/toyslibrary.png";
import TLM from "../../image/pmshri/tlm.png";
import JaduiPitara from "../../image/pmshri/jaduipitara.png";
import ScienceCircle from "../../image/pmshri/sciencecircle.png";
import MathsKit from "../../image/pmshri/mathskit.png";
import ScienceKit from "../../image/pmshri/sciencekit.png";
import RawMaterials from "../../image/pmshri/rawmaterials.png";
class PMSHRI extends Component {
    render(){
        return(
            <section className="tracking_product_area sec_pad">
                <div className="container">
                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>Bal Vatika</h3>
                                    <p>"Bal Vatika" is like a special playground for kids under the PM Shri Scheme. It's a fun and colorful place where children can learn and play. They get to do lots of different activities, like painting, storytelling, and dancing. It helps them grow smart, creative, and friendly. And parents and the community get involved too, making sure kids have everything they need to become great people. Through Shikshak Solutions initiative, schools receive access to engaging materials such as educational toys, books, art supplies, and interactive learning modules. We also organize storytelling sessions, music and dance workshops, and other cultural activities to enrich the students' experiences.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <img className='pm-shri-image' src={BalVatika} alt="bal-vatika"/>
                            </div>
                        </div>
                    </div>

                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <img className='pm-shri-image' src={JaduiPitara} alt="jadui-pitara"/>
                            </div>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>Jadui Pitara</h3>
                                    <p>Our company specializes in setting up Jadui Pitaras in schools as part of the PM Shri Scheme. We provide magical treasure chests filled with educational tools, toys, and resources to make learning enjoyable for children. Through our initiative, schools receive access to a diverse range of materials aimed at sparking children's curiosity and imagination. </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>TLM(Teaching Learning Material)</h3>
                                    <p>TLM encompasses a wide range of resources, including educational aids, visual aids, and interactive materials designed to make learning more effective and engaging for students. Through the PM Shri Scheme, schools receive access to TLM to support educators in delivering quality education and to facilitate better understanding and retention of concepts among students. By partnering with us, schools can enhance their teaching practices and improve student outcomes through the effective use of Teaching-Learning Materials provided under the PM Shri Scheme.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <img className='pm-shri-image' src={TLM} alt="TLM"/>
                            </div>
                        </div>
                    </div>

                    {/*<div className={`tracking_pr_item`}>*/}
                    {/*    <div className={`row align-items-center`}>*/}
                    {/*        <div className="col-lg-6">*/}
                    {/*            <div className="tracking_content wow fadeInDown">*/}
                    {/*                <h3>Linkage Sector</h3>*/}
                    {/*                <p>The Linkage Sector in the PM Shri Scheme acts like a bridge, connecting different groups like government, businesses, schools, and communities. It helps them communicate and work together smoothly. By joining forces and sharing resources, the Linkage Sector helps promote overall development, new ideas, and eco-friendly growth in various areas. Its goal is to create partnerships that solve problems and make life better for everyone under the PM Shri Scheme. Through our strategic networking and coordination efforts, we empower schools to leverage the collective strengths of various sectors and stakeholders to enhance educational outcomes and promote overall development under the PM Shri Scheme.</p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="col-lg-6">*/}
                    {/*            <img className='pm-shri-image' src={LinkageSector} alt="linkage-sector"/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <img className='pm-shri-image' src={SkillSector} alt="skill-sector"/>
                            </div>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>Skill Sector</h3>
                                    <p>The Skill Sector in the PM Shri Scheme helps people get the skills they need to find good jobs. It offers training programs and courses to teach practical skills that employers are looking for. By learning these skills, people can improve their chances of getting hired and contribute to the country's economic growth. The Skill Sector connects education with job opportunities, helping to build a skilled workforce and boost development under the PM Shri Scheme. By teaming up with us, schools can make sure their students get top-notch training and materials in skills that help them find jobs. This prepares them for work and helps the country grow economically through the PM Shri Scheme.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>Internship</h3>
                                    <p>
                                        Our company helps schools by offering internships and training as part of the PM Shri Scheme. We provide students with practical work experience in their chosen fields, giving them hands-on learning in real work settings. These internships help students develop important skills, making them more likely to get hired and succeed in their careers. By teaming up with us, schools can ensure their students are ready for good jobs and can help the country grow. </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <img className='pm-shri-image' src={Internship} alt="internship"/>
                            </div>
                        </div>
                    </div>

                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <img className='pm-shri-image' src={RawMaterials} alt="raw-materials"/>
                            </div>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>Raw Materials</h3>
                                    <p>"Raw Materials" in the PM Shri Scheme are basic resources needed for making things like products and buildings. These materials, like metals or wood, are crucial for manufacturing and construction projects. By providing these materials, the PM Shri Scheme helps industries grow, makes them more capable, and encourages new ideas in different areas. This support ensures that businesses have what they need to keep working, be productive, and compete well, ultimately helping the country's overall development. By providing raw materials to schools, we help them become better at teaching and learning. We encourage creativity and productivity among students and teachers. Ultimately, our support aims to improve education and contribute to the overall development of schools and communities.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>Toys Library</h3>
                                    <p>A Toy Library in the PM Shri Scheme is like a fun store where kids can borrow toys and games. They have all sorts of toys, puzzles, and games that help kids learn while they play. The idea is to make kids more creative, imaginative, and smarter. These Toy Libraries are important because they give kids a chance to learn and have fun at the same time, make friends, and grow up healthy and happy.Through our partnership with schools, we ensure that children have access to engaging and educational toys that support their growth and learning journey.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <img className='pm-shri-image' src={ToysLibrary} alt="toys-library"/>
                            </div>
                        </div>
                    </div>

                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <img className='pm-shri-image' src={ToyAccesories} alt="toy-accesories"/>
                            </div>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>Accesories for Toy</h3>
                                    <p>"Accessories for Toys" in the PM Shri Scheme are extra items that make playing with toys more fun and educational for kids. These could be things like clothes for dolls, furniture for toy houses, blocks for building, or small figures to add to playsets. By providing these accessories, the PM Shri Scheme helps kids use their imagination, be creative, and learn new things while they play. These items also encourage kids to play together, share, and talk to each other, which helps them develop social and communication skills. Ultimately, accessories for toys make playtime better for kids and help them grow in many ways. Through our partnership with schools, we ensure that children have access to engaging and educational accessories that contribute to their overall growth and development.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>STEM Kit</h3>
                                    <p>The STEM Kit in the PM Shri Scheme is a special toolbox full of fun activities and experiments to help students learn about science, technology, engineering, and math (STEM). It's filled with hands-on materials that make learning these subjects exciting and practical. The goal is to help students understand STEM topics better, become better problem solvers, and spark their creativity and innovation. These kits let students apply what they learn in school to real-life situations, getting them interested in STEM subjects and ready for future jobs in these fields. Our initiative offers students the opportunity to apply theoretical knowledge to real-world scenarios, igniting their curiosity and interest in STEM fields. Ultimately, our STEM Kits contribute to building a strong foundation in STEM education, preparing students for future careers in science, technology, engineering, and mathematics.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <img className='pm-shri-image' src={STEM} alt="stem-kit"/>
                            </div>
                        </div>
                    </div>

                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <img className='pm-shri-image' src={Agriculture} alt="agriculture"/>
                            </div>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>Agriculture</h3>
                                    <p>The Agriculture Sector in the PM Shri Scheme helps farmers by providing support and resources to improve their farming. This includes programs, subsidies, and technology to make farming more productive and sustainable. Farmers get access to things like seeds, fertilizers, machinery, and training to help them grow more crops and earn more income. By supporting farmers and encouraging new ideas in agriculture, the PM Shri Scheme aims to help rural communities thrive, reduce poverty, and improve overall development. We ensure that schools receive essential resources such as seeds, fertilizers, machinery, and irrigation facilities to facilitate practical learning. Additionally, our training programs offer guidance and advisory services to improve agricultural techniques and maximize yields.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>Digital Library</h3>
                                    <p>The Digital Library in the PM Shri Scheme is like an online library with lots of books, articles, videos, and educational materials. It's a place where people can find information on many topics, from school subjects to career development. The aim of the Digital Library is to make education accessible to everyone and encourage learning throughout life. You can access it anytime, anywhere using computers, tablets, or smartphones. This helps bridge the gap between those who have access to information and those who don't, giving everyone the chance to improve their skills and opportunities. We ensure that schools have the necessary digital infrastructure and support to make the Digital Library accessible to students anytime, anywhere. By providing this resource, we aim to democratize education and promote lifelong learning opportunities for students across the country.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <img className='pm-shri-image' src={DigitalLibrary} alt="digital-library-vatika"/>
                            </div>
                        </div>
                    </div>

                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <img className='pm-shri-image' src={SportsKit} alt="sports-kit"/>
                            </div>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>Sports Kit</h3>
                                    <p>The Sports Kit provided by the PM Shri Scheme contains all the gear needed for various sports and activities, like balls, bats, and protective gear. It's meant to encourage people of all ages to stay active and healthy by participating in sports and games. These kits offer opportunities for fun, teamwork, and skill development in sports. Overall, the Sports Kit initiative promotes a culture of physical activity, benefiting the health and well-being of communities.Our initiative offers schools the opportunity to engage students in recreational activities, develop teamwork skills, and nurture talent in sports. Additionally, we contribute to fostering a culture of sports and physical activity within schools, ultimately improving the overall health and well-being of students and the school community.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>Math's Circle</h3>
                                    <p>The Maths Circle in the PM Shri Scheme is a special program to help students get better at math and enjoy it more. It's like a friendly group where students can do math activities, solve puzzles, and talk about math together. The Maths Circle helps students think critically, solve problems, and be creative while learning different math ideas. By joining the Maths Circle, students become more confident in their math skills and understand math better. The goal of the Maths Circle is to make students good at math and ready for success in school and beyond. Our Maths Circle initiative provides students with opportunities to develop confidence in their mathematical abilities and gain a deeper understanding of mathematical principles. By participating in the Maths Circle, students not only improve their mathematical literacy and proficiency but also prepare themselves for success in academics and beyond.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <img className='pm-shri-image' src={MathsCircle} alt="maths-circle"/>
                            </div>
                        </div>
                    </div>

                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <img className='pm-shri-image' src={ScienceCircle} alt="science-circle"/>
                            </div>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>Science Circle</h3>
                                    <p>
                                        The Science Circle in the PM Shri Scheme is like a club where students can learn and enjoy science. It's a fun and interactive environment where they can explore different scientific ideas, do experiments, and talk about science with others. The Science Circle helps students think critically, solve problems, and learn by asking questions. By joining the Science Circle, students understand science better, improve their thinking skills, and develop a strong interest in science. The goal of the Science Circle is to make students good at science and ready for success in school and careers in STEM fields.By participating in our Science Circle initiative, students have the opportunity to enhance their critical thinking, problem-solving, and inquiry-based learning skills. Our program covers various scientific disciplines and topics, fostering a deeper understanding of scientific principles and promoting scientific literacy among students.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>Math's Kit </h3>
                                    <p>The Maths Kit in the PM Shri Scheme is like a toolbox filled with fun math tools and games to help students learn. It has things like blocks, shapes, measuring tools, and games that make math easier to understand and more enjoyable. The aim of the Maths Kit is to make learning math hands-on and interactive, encouraging students to explore, experiment, and solve problems. By using the Maths Kit, students can get better at math, understand math concepts more deeply, and feel more confident in their math skills. Ultimately, the Maths Kit initiative wants to help students love math and do well in school and beyond. Through our initiative, schools gain access to Maths Kits that promote interactive and hands-on learning experiences, encouraging exploration, experimentation, and problem-solving. These kits offer students opportunities to develop a deeper understanding of mathematical principles, improve their mathematical skills, and build confidence in their abilities.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <img className='pm-shri-image' src={MathsKit} alt="maths-kit"/>
                            </div>
                        </div>
                    </div>

                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <img className='pm-shri-image' src={ScienceKit} alt="science-kit"/>
                            </div>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>Science Kit</h3>
                                    <p>The Science Kit provided by the PM Shri Scheme is like a big box full of tools and materials for doing hands-on science experiments. It has all sorts of equipment and experiment kits covering many scientific topics. The aim is to help students explore, discover, and understand science better. With the Science Kit, students can do interactive experiments, make observations, and analyze results, which helps them think critically, solve problems, and learn how to ask scientific questions. Overall, the Science Kit initiative wants to make students curious about science, get them excited about it, and prepare them for success in school and future careers in science and technology.</p>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </section>
        )
    }
}

export default withRouter(PMSHRI);
