import React, {Component} from "react";
import CustomNavbar from "../components/layout/CustomNavbar";
import Breadcrumb from "../components/layout/Breadcrumb";
import Footer from "../components/layout/Footer";
import FeedbackForm from "../components/feedback/FeedbackForm";

class Feedback extends Component {
    render() {
        return (
            <div className="body_wrapper">
                <CustomNavbar mClass="menu_four hosting_menu" nClass="w_menu m-auto" slogo="sticky_logo" hbtnClass="event_btn"/>
                <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Trainers Feedback Form" Pdescription="We are committed to providing you with the best learning experience possible, so your feedback is valuable to us."/>
                <FeedbackForm/>
                <Footer/>
            </div>
        )
    }
}
export default Feedback;
