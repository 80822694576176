import React, {Component} from "react";
import CustomNavbar from "../components/layout/CustomNavbar";
import Breadcrumb from "../components/layout/Breadcrumb";
import Footer from "../components/layout/Footer";
import OurCurriculum from "../components/curriculum/OurCurriculum";

class Curriculum extends Component {
    render() {
        return (
            <div className="body_wrapper">
                <CustomNavbar mClass="menu_four hosting_menu" nClass="w_menu m-auto" slogo="sticky_logo" hbtnClass="event_btn"/>
                <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Our Curriculum" Pdescription="Start an exciting learning adventure with our carefully designed curriculum, meant to spark curiosity, encourage critical thinking, and empower you as a lifelong learner."/>
                <OurCurriculum />
                <Footer/>
            </div>
        )
    }
}
export default Curriculum;
