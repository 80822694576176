import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {removeProductFromCart, updateProductQuantityOnCart} from "../../store/actions/shopAction";
import IconButton from '@mui/material/IconButton';
//import DeleteIcon from '@mui/icons-material/delete';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import moment from "moment";

function CartTable({listOfCart}) {

  const dispatch  = useDispatch();
  const userAllCartData = listOfCart;

  const removeFromCart = (cartId,qty,productId)=>{
    dispatch(removeProductFromCart({cartId,qty,productId}));
  }

  const updateQuantity = (cartId, quantity,type,productId) =>{
    let payload = {
      'cartId': cartId,
      'qty': parseInt(quantity),
      'type': type,
      'productId':productId
    }
    dispatch(updateProductQuantityOnCart(payload));
  }

  return (
    <div>


<table className="table mt-5" border={"0"} cellPadding={"5"} cellSpacing={0}>
  <thead>
    <tr>
      <th scope="col">PRODUCT</th>
      <th scope="col">PRICE</th>
      <th scope="col">QUANTITY</th>
      <th scope="col">SUBTOTAL</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    {userAllCartData.map((userCartData, index) => (
                <tr key={index+"_cart_"+userCartData.id}>{console.log(userCartData,"userCartData")}
                  <td scope="row">
                    <div className={"product-cart d-fline-inline"}>
                    <img src={userCartData.photo} height={100} width={100}  alt="..."/>
                    <div className={"text"} >{userCartData.name}</div>
                    </div>
                  </td>

                  <td><span className={"price"}>₹ {userCartData.price}</span></td>
                  <td>
                    <div className={"product-quantity"}>
                    {(userCartData.qty > 1) ?
                    <>
                        <span className={"delete-icon"} onClick={()=>updateQuantity(userCartData?.id, (userCartData.qty) - 1,'decrease',userCartData?.productId)}>
                          <IconButton aria-label="minus">
                          <RemoveIcon /></IconButton>
                        </span>
                    </> : <>
                        <span onClick={()=>removeFromCart(userCartData?.id,userCartData.qty,userCartData?.productId)}>
                          <IconButton aria-label="delete">
                          <DeleteIcon /></IconButton></span>
                        </>
                    }
                    {userCartData.qty}
                    <span className={"add-icon"} onClick={()=>updateQuantity(userCartData?.id, (userCartData.qty) + 1,'increase',userCartData?.productId)}>
                      <IconButton aria-label="add">
                          <AddIcon /></IconButton></span>
                    </div>
                  </td>
                  <td><span className={"subtotal"}>₹ {(userCartData.price)*(userCartData.qty)}</span></td>
                  <td ><button type="button" className="common-btn" onClick={()=>removeFromCart(userCartData?.id,userCartData.qty,userCartData?.productId)}>Remove</button></td>
                </tr>
            )

      )
    }

  </tbody>
</table>


    </div>
   
  )
}

export default CartTable
