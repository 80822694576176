import React from 'react'
import Bulb from '../../image/Lego.png';
function SignInImage() {
    return (
        <section>
          <div className='loginimg'>
            <img src={Bulb} alt="..."/>
            </div>    
        </section>
    )
}

export default SignInImage;
