import React, {useEffect, useState} from 'react'
import Footer from "../components/layout/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import PreLoader from "../components/product/PreLoader";
import CustomNavbar from "../components/layout/CustomNavbar";
import CartTable from '../components/Cart/CartTable';
import Cartorder from '../components/Cart/Cartorder';
import {useEffectOnce} from "../hooks/useEffectOnce";
import {actionToGetTotalCartCount, getUserCartDetails} from "../store/actions/shopAction";
import {useDispatch, useSelector} from "react-redux";
import AuthService from "../components/services/auth.service";
import CryptoJS from "crypto-js";

export default function Cart(){
    const  dispatch = useDispatch();
    const ENCRYPTION_KEY = "XkhZG4fW2t2W";
    const [listOfCart, setListOfCart] = useState([]);
    const [cartSubTotal, setCartSubTotal] = useState(0);
    const [cartAmountTotal, setCartAmountTotal] = useState(0);
    const [loadingData, setLoadingData] = useState(true);
    let totalCheckoutAmount = 0.00;
    let finalDiscountAmount = 0.00;
    let unAuthDecryptCartData = localStorage.getItem('SS_UN_AUTH_CART_DATA') ? JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('SS_UN_AUTH_CART_DATA'), ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8)) : {};
    useEffectOnce(()=>{
        if(!localStorage.getItem('user')){;
            setListOfCart(Object.values(unAuthDecryptCartData));
            setLoadingData(false);
        }else {
            const userInfo = localStorage.getItem('user');
            let userRawData = AuthService.parseJwt(JSON.parse(userInfo)?.accessToken);
            dispatch(getUserCartDetails(userRawData?.user?.id));
            // Updating cart count
            dispatch(actionToGetTotalCartCount(userRawData?.user?.id));
        }
    });
    const userCartData = useSelector((state) => state.cartReducer.userCartData)
    useEffect(()=>{
        if(localStorage.getItem('user')) {
            console.log(userCartData?.data, "userCartData?.data")
            setListOfCart(userCartData?.data);
            setLoadingData(userCartData?.loading);
        }
    },[userCartData]);
    useEffect(()=>{
        if(listOfCart?.length>0) {
            listOfCart?.map((cartData, index) => {

                totalCheckoutAmount = parseFloat(parseFloat(totalCheckoutAmount) + parseFloat((cartData.price) * (cartData.qty))).toFixed(2);
                setCartSubTotal(totalCheckoutAmount);
                setCartAmountTotal(totalCheckoutAmount);

            });
        }
    },[listOfCart])
    useEffect(()=>{
        if(!localStorage.getItem('user')) {
            setListOfCart(Object.values(unAuthDecryptCartData));
        }
    },[unAuthDecryptCartData])



  return (
    <div className="body_wrapper">
    <CustomNavbar mClass="menu_four hosting_menu" nClass="w_menu m-auto" slogo="sticky_logo" hbtnClass="event_btn"/>
    
    <section className="sec_without_breadcrumb ">
        <div className={"container custom_container p0"}>
                {(loadingData)? <PreLoader />:
                <>
                {(listOfCart && (listOfCart.length > 0 || Object.keys(listOfCart).length>0)) ?
                <>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <CartTable listOfCart={listOfCart}/>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-md-6"}></div>
                    <div className={"col-md-6"}>
                        <table className="order border-1" cellSpacing={"0"} cellPadding={"10"} border={"0"} width={"100%"}>
                            <thead>
                                <th scope="col" className={"border-bottom"} colSpan={"2"}><h6>Cart summary</h6></th>
                            </thead>
                              <tbody>
                                <tr>
                                  <td scope="row"><div>Sub total</div></td>
                                  <td align={"right"}><div className={"subtotal"}>₹ {cartSubTotal}</div></td>
                                </tr>
                              <tr>
                                  <td scope="row"><div className={"mb-0"}>Amount total</div></td>
                                  <td align={"right"}><p className={"price mb-0"}>₹ {cartAmountTotal}</p></td>
                              </tr>
                                <tr>
                                  <td scope="row" colSpan={"2"}>
                                     <p className={"border-top pt-2"}>Taxes and shipping calculated at checkout</p>
                                  </td>
                                </tr>
                                <tr>
                                    <td scope="row" colSpan={"2"} align={"right"}>
                                        <Cartorder/>
                                    </td>
                                </tr>
                              </tbody>
                        </table>
                    </div>
                </div>
                </>
                :
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <h4 className={"mb-5 container fw-bold mt-5"}>Your Cart is Empty</h4>
                    </div>
                </div>
            }
            </>}
        </div>
    </section>
    <Footer/>
    </div>
  )



}






