import React, {useEffect, useState} from 'react';
import {useEffectOnce} from "../../hooks/useEffectOnce";
import AuthService from "../services/auth.service";
import {useDispatch,useSelector} from "react-redux"
import {getUserCartDetails, getUserSavedAddress, updateDefaultAddress} from "../../store/actions/shopAction";
import {NotificationManager} from "react-notifications";

function UpdateEmailForm() {
  const [currentEmail, setCurrentEmail] = useState('');
  const [userSavedAddressesData, setUserSavedAddressesData] = useState('');
  const [existingDeliveryAddressIndex, setExistingDeliveryAddressIndex] = useState(0);
  const [newEmail, setNewEmail] = useState('');
  const [confirmNewEmail, setConfirmNewEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [ConfirmNewPassword, setConfirmNewPassword] = useState('');
  const [changePasswordServerError, setChangePasswordServerError] = useState(false);
  const [openManagePasswordForm, setOpenManagePasswordForm] = useState(false);
  const [openManageAddressForm, setOpenManageAddressForm] = useState(true);
  const [loadingForm, setLoadingForm] = useState(true);
  const [currentPasswordErrorMsg, setCurrentPasswordErrorMsg] = useState("");
  const [newPasswordErrorMsg, setNewPasswordErrorMsg] = useState("");
  const [confirmPasswordErrorMsg, setConfirmPasswordErrorMsg] = useState('');
  const userSavedAddresses= useSelector((state) => state.cartReducer.userSavedAddressesData);
  const userInfo =  localStorage.getItem('user');
  let userRawData =   AuthService.parseJwt(JSON.parse(userInfo)?.accessToken);
  const dispatch = useDispatch();
  const pwdRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
  useEffectOnce(()=>{
    dispatch(getUserSavedAddress(userRawData?.user?.id));
  });
  useEffect(()=>{
  if(userSavedAddresses.length>0){
    userSavedAddresses?.map((address,index)=> {
      if(address?.default_shipping_address ==="1" || address?.default_billing_address ==="1"){
        setExistingDeliveryAddressIndex(index);
      }
    });
    setUserSavedAddressesData(userSavedAddresses);
    setLoadingForm(false);

  }
  },[userSavedAddresses]);
  const handelAddressOptionChange = async (index) => {
    let payload={'userId':userRawData?.user?.id,'ids':'"'+userSavedAddresses[index].billing_address_id+'","'+userSavedAddresses[index].shipping_address_id+'"'}
     await dispatch(updateDefaultAddress(payload)).then(async () => {
       NotificationManager.success('Default address is updated successfully.');
       dispatch(getUserSavedAddress(userRawData?.user?.id));
     });

  }
  const handleCurrentEmailChange = (e) => {
    setCurrentEmail(e.target.value);
  }

  const handleNewEmailChange = (e) => {
    setNewEmail(e.target.value);
  }

  const handleConfirmNewEmailChange = (e) => {
    setConfirmNewEmail(e.target.value);
  }

  const handleCurrentPasswordChange = (e) => {
    setCurrentPassword(e.target.value);
  }

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  }

  const handleConfirmNewPasswordChange = (e) => {
    setConfirmNewPassword(e.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    changePassword();
    // console.log('userRawData?.user:', userRawData?.user);
    // console.log('Current Email:', currentEmail);
    // console.log('New Email:', newEmail);
    // console.log('Confirm New Email:', confirmNewEmail);
    // console.log('Current Password:', currentPassword);
    // console.log('New Password:', newPassword);
    // console.log('Confirm New Password:', ConfirmNewPassword);



    // setCurrentEmail('');
    // setNewEmail('');
    // setConfirmNewEmail('');
    // setCurrentPassword('');
    // setNewPassword('');
    // setConfirmNewPassword('');
  }
  const changePassword = () => {
    setChangePasswordServerError(false);
    setNewPasswordErrorMsg('');
    setCurrentPasswordErrorMsg('');
    setConfirmPasswordErrorMsg('');
    let hasError =false;
    //let regexForPasswordValidation = /^(?=(?:.*[a-zA-Z])+)([a-zA-Z\d]{5,15})$/;
    if(currentPassword === "") {
      setCurrentPasswordErrorMsg('Please Enter Current Password');
      hasError=true;
    }
    if(newPassword === "") {
      setNewPasswordErrorMsg('Please enter your new password');
      hasError=true;
    }
    if(ConfirmNewPassword === "") {
      setConfirmPasswordErrorMsg('This field is empty');
      hasError=true;
    }
    else if(!newPassword.match(pwdRegex)) {
      setNewPasswordErrorMsg('Password should be between 6 to 15 characters and should include at least one lowercase letter, one uppercase letter and one number.');
      hasError=true;
    }
    if(ConfirmNewPassword !== newPassword && newPassword !== "" && ConfirmNewPassword !== "") {
      setConfirmPasswordErrorMsg('Confirm password should be same as new password.');
      hasError=true;
    }
    if(currentPassword === newPassword){
      setNewPasswordErrorMsg('Current password and new password should not be same');
      hasError=true;
    }
    if(!hasError){
      AuthService.updateUserPassword(userRawData?.user?.id,currentPassword,newPassword,callFunctionToFinishChangePasswordProcess);
    }
  }
  const callFunctionToFinishChangePasswordProcess = (serverStatus,message = '')=>{
    console.log(serverStatus,message,'serverStatus message');
    addServerErrorValidation(serverStatus,message)
  }
  const addServerErrorValidation = (serverStatus,message = '',)=>{
    if(serverStatus === 0){
      setChangePasswordServerError(true);
    }else if(serverStatus === 3){
      setNewPasswordErrorMsg(message)
    }else if(serverStatus === 2){
      setCurrentPasswordErrorMsg(message)
    }else if(serverStatus === 1){
      NotificationManager.success('Password is updated successfully.');
      setNewPasswordErrorMsg('');
      setCurrentPasswordErrorMsg('');
      setConfirmPasswordErrorMsg('');
      setNewPassword('');
      setCurrentPassword('');
      setConfirmNewPassword('');
    }else{
      setChangePasswordServerError(true);
    }

  }
  const handelOpenForm = async (formName) => {
    if(formName==='address'){
    setOpenManageAddressForm(true);
    setOpenManagePasswordForm(false);
    }
    if(formName==='password'){
      setOpenManageAddressForm(false);
      setOpenManagePasswordForm(true);
      setNewPasswordErrorMsg('');
      setCurrentPasswordErrorMsg('');
      setConfirmPasswordErrorMsg('');
      setNewPassword('');
      setCurrentPassword('');
      setConfirmNewPassword('');
    }

  }

  return (
<div>
    <div className="form-group text_box" style={{display:"none"}}>
      <div className={"border-bottom pb-2"}><h5>Update Email</h5></div>
      <form onSubmit={handleSubmit} className="login-form sign-in-form">
        <div>
          <label htmlFor="currentEmail"></label>
          <input className='form-control'
            type="email"
            placeholder='Current Email'
            id="currentEmail"
            name="currentEmail"
            value={currentEmail}
            onChange={handleCurrentEmailChange}
            required
          />
        </div>
        <div>
          <label htmlFor="newEmail"></label>
          <input className='form-control'
            placeholder='New Email'
            type="email"
            id="newEmail"
            name="newEmail"
            value={newEmail}
            onChange={handleNewEmailChange}
            required
          />
        </div>
        <div>
          <label htmlFor="confirmNewEmail"></label>
          <input className='form-control'   
            type="email"
            id="confirmNewEmail"
            name="confirmNewEmail"
            placeholder='Confirm New Email'
            value={confirmNewEmail}
            onChange={handleConfirmNewEmailChange}
            required
          />
        </div>
        <div>
          <button className="common-btn mt-3 m-0" type="submit">Update Email</button>
        </div>
      </form>
    </div>
  <div>
    <ul class="nav nav-tabs border-bottom-0 set-default-address-tabs" id="myTab" role="tablist">
      <li className={"menu "+(openManageAddressForm === true ? 'nav-item active' : '')} onClick={()=>handelOpenForm('address')}><button className={"nav-link m-0 active"} data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Set Default Address</button></li>
    <li className={"menu "+(openManagePasswordForm === true ? 'nav-item active' : '')} onClick={()=>handelOpenForm('password')}><button className={"nav-link m-0"} data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Update Password</button></li>
    </ul>
  </div>
  {(loadingForm) ? <p> Loading please wait.....</p>:
      <>
  {(openManageAddressForm)?
  <div className="form-group text_box tab-content gray-box-light">
    <div className={"d-inline-flex w-100 border-bottom"}>
      <h5 className='m-0 pb-2'>Set Default Address</h5>
    </div>
    {(userSavedAddressesData?.length)?
        <>
          {userSavedAddressesData?.map((address,index)=>(
              <div key={index}>
                {/*<label htmlFor="address" className="form-label">*/}
                <div className={"delivery-address d-inline-flex p-2 w-100 pb-0"}>
                  {(existingDeliveryAddressIndex=== index) ? <b>Default Delivery Address</b>:<button className={"m-0"} onClick={()=>handelAddressOptionChange(index)}>Make it default Address</button>}
                  {/*<div className={"mr-5"}><input type="radio" className="form-check-input pr-2" name="address" value={index} onChange={(e)=>handelAddressOptionChange(e)}  checked={ existingDeliveryAddressIndex=== index}/></div>*/}
                  {/*<div className={"pl-2"}><h6 className="pl-2 mb-0 pl-2">Delivery Address</h6></div>*/}
                </div>
                <div id="address" className={"p-2 w-100"}>
                  <div>{address?.shipping_address_full_name}</div>
                  <div>{address?.shipping_address_phone}</div>
                  <div>{address?.shipping_address_address},</div>
                  <div>{address?.shipping_address_city},</div>
                  <div>{address?.shipping_address_state},</div>
                  <div>{address?.shipping_address_country}</div>
                  <div>{address?.shipping_address_pin_code}</div>
                </div>
                {/* </label>*/}
              </div>
          ))}
        </>:<h3>No address  added on your account</h3>}
  </div>
      :""}
  {(openManagePasswordForm) ?
    <div className="form-group text_box">
      <form className="login-form sign-in-form" onSubmit={handleSubmit}>
        <div className={"border-bottom pb-2"}><h5>Update Password</h5></div>
        <div>
          <label htmlFor="currentPassword"></label>
          <input className='form-control'
            placeholder='Current Password'
            type="password"
            id="currentPassword"
            name="currentPassword"
            value={currentPassword}
            onChange={handleCurrentPasswordChange}
            required
          />
          <span className="errorMessage">{currentPasswordErrorMsg}</span>
        </div>
        <div>
          <label htmlFor="newPassword"></label>
          <input className='form-control'
            type="password"
            placeholder='New Password'
            id="newPassword"
            name="newPassword"
            value={newPassword}
            onChange={handleNewPasswordChange}
            required
          />
          <span className="errorMessage">{newPasswordErrorMsg}</span>
        </div>
        <div>
          <label htmlFor="ConfirmNewPassword"></label>
          <input className='form-control'
            placeholder='Confirm New Password'
            type="password"
            id="ConfirmNewPassword"
            name="ConfirmNewPassword"
            value={ConfirmNewPassword}
            onChange={handleConfirmNewPasswordChange}
            required
          />
          <span className="errorMessage">{confirmPasswordErrorMsg}</span>
        </div>
        <div>
          <button className='common-btn mt-3 m-0' type="submit">Update Password</button>
        </div>
      </form>
    </div>
      :""}
      </>
  }
</div>
  );
}

export default UpdateEmailForm;
