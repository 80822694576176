import {api, apiWithoutHeader} from "../../hooks/api/ApiConfig";
import {handleWebSocketEvent} from '../../helper/WebSoketHelper';
import {
    NEW_ORDER_PLACE,
    UPDATE_CART_COUNT,
    USER_ORDERS,
    USER_SAVE_ADDRESSES,
    USER_CURRENT_ORDER_DATA, USER_CART_DATA, OPEN_CLOSE_PAY_UPLOAD_INVOICE_MODAL, ALL_PINCODE_DETAILS
} from "../constant/cartConstant";
import AuthService from "../../components/services/auth.service";
import AWS from 'aws-sdk';
import logo from '../../image/logo2.jpg';
import JsPDF from "jspdf";
import CryptoJS from "crypto-js";
import {API_URL} from "../../hooks/config";
import {ALL_STATE_LIST} from "../constant/commonConstant";
const AWS_ACCESS_KEY_ID='AKIA5BAMSD7MAS6P43HN';
const AWS_SECRET_ACCESS_KEY='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';
const AWS_DEFAULT_REGION='eu-north-1';
const AWS_BUCKET = 'shikshak-solutions';
const ENCRYPTION_KEY = "XkhZG4fW2t2W";
const S3Bucket = new AWS.S3({
    params: {Bucket: AWS_BUCKET},
    region: AWS_DEFAULT_REGION,
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_SECRET_ACCESS_KEY,
    encryptionKey : ENCRYPTION_KEY,
})

export const actionToUploadDataToS3BucketStore = (payload) => async () => {
    const params = {
        ACL: 'public-read',
        Body: payload.blobData,
        Bucket: AWS_BUCKET,
        Key: payload.key,
        ContentType:payload.type
    };
    S3Bucket.putObject(params)
        .send((err) => {
            console.log(err,'error');
        })
    return `https://shikshak-solutions.s3.eu-north-1.amazonaws.com/${payload.key}`;
}
export const actionAddToCart = (payload) => async (dispatch) => {
    let cartCCountLocalStorage = (localStorage.getItem('cartCount') && !isNaN(localStorage.getItem('cartCount'))) ? localStorage.getItem('cartCount') : 0;

    if(payload.userId !==undefined) {
        const {data} = await api.post(`products/actionToCartDataByProductAndUserId`, {payload:CryptoJS.AES.encrypt(JSON.stringify(payload), ENCRYPTION_KEY).toString()});
        if (data?.response?.success) {
            dispatch({type: UPDATE_CART_COUNT, payload: parseInt(cartCCountLocalStorage) + parseInt(payload?.qty)});
            return true;
        } else {
           return false;
        }
    }
    else{
        let unAuthUserCartData = localStorage.getItem('SS_UN_AUTH_CART_DATA') ? JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('SS_UN_AUTH_CART_DATA'), ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8)) : {};
        console.log(unAuthUserCartData[payload?.productId],"unAuthDecData")
        if(unAuthUserCartData[payload?.productId]){
            unAuthUserCartData[payload?.productId]['qty'] = parseInt(unAuthUserCartData[payload?.productId]['qty'])+parseInt(payload?.qty);
            localStorage.setItem('SS_UN_AUTH_CART_DATA',CryptoJS.AES.encrypt(JSON.stringify(unAuthUserCartData), ENCRYPTION_KEY).toString());
        }else{
            unAuthUserCartData[payload?.productId] =payload;
            localStorage.setItem('SS_UN_AUTH_CART_DATA',CryptoJS.AES.encrypt(JSON.stringify(unAuthUserCartData), ENCRYPTION_KEY).toString());
        }
        dispatch({type: UPDATE_CART_COUNT, payload: parseInt(cartCCountLocalStorage) + parseInt(payload?.qty)});
        return true;
    }

}
export const actionAddUnAuthCartDataToCartAfterLogin= (payload) => async (dispatch) => {
    let unAuthUserCartData = localStorage.getItem('SS_UN_AUTH_CART_DATA') ? JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('SS_UN_AUTH_CART_DATA'), ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8)) : {};
    if(unAuthUserCartData && Object.values(unAuthUserCartData)?.length>0) {
        Object.values(unAuthUserCartData).map(async (cartItem, index) => {
            if (payload.userId) {
                let dataToAdd = {
                    'productId': cartItem?.productId,
                    'qty': cartItem?.qty,
                    'createdAt': payload?.createdAt,
                    'userId': payload?.userId
                };
                const {data} = await api.post(`products/actionToCartDataByProductAndUserId`, {payload:CryptoJS.AES.encrypt(JSON.stringify(dataToAdd), ENCRYPTION_KEY).toString()});
                dispatch(actionToGetTotalCartCount(payload?.userId));
            }
        });
        localStorage.removeItem('SS_UN_AUTH_CART_DATA');
        dispatch(actionToGetTotalCartCount(payload));
    }

}
export const actionToGetTotalCartCount = (payload) => async (dispatch) => {
    const {data} = await api.post(`products/actionToGetAllCartCountDataByUserId`,{payload:CryptoJS.AES.encrypt(JSON.stringify({'userId':payload}), ENCRYPTION_KEY).toString()});
    localStorage.setItem('cartCount',(data[0]?.total_qty === undefined) ? 0 : data[0]?.total_qty);
    dispatch({ type: UPDATE_CART_COUNT, payload: (data[0]?.total_qty === undefined) ? 0 : data[0]?.total_qty});
}

export const handleWebSocketEventCall = (data) => async (dispatch,getState) => {
    handleWebSocketEvent(dispatch,getState(),data);
}

export const getUserCartDetails = (payload) => async (dispatch) => {
    const {data} = await api.post(`products/actionToGetUserCartDataByUserId`,{payload:CryptoJS.AES.encrypt(JSON.stringify(payload), ENCRYPTION_KEY).toString()});
    let requestDataString = CryptoJS.AES.decrypt(data, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
    let cartData = JSON.parse(requestDataString)
    dispatch({ type: USER_CART_DATA, payload: {data:cartData,loading:false}});
    return data;
}

export const removeProductFromCart = (payload) => async (dispatch) => {
    const userInfo =  localStorage.getItem('user');
    let cartCCountLocalStorage = (localStorage.getItem('cartCount') && !isNaN(localStorage.getItem('cartCount'))) ? localStorage.getItem('cartCount') : 0;
    if(userInfo) {
        await api.post(`products/actionDeleteProductFromCartByCartId`, {payload:CryptoJS.AES.encrypt(JSON.stringify({'cartId': payload?.cartId}), ENCRYPTION_KEY).toString()});
        let userRawData = AuthService.parseJwt(JSON.parse(userInfo)?.accessToken);
        dispatch(getUserCartDetails(userRawData?.user?.id));
        dispatch({type: UPDATE_CART_COUNT, payload: parseInt(cartCCountLocalStorage) - parseInt(payload?.qty)});
        localStorage.setItem('cartCount', parseInt(cartCCountLocalStorage) - parseInt(payload?.qty));
    }else{
        let unAuthUserCartData = localStorage.getItem('SS_UN_AUTH_CART_DATA') ? JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('SS_UN_AUTH_CART_DATA'), ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8)) : {};
        if(unAuthUserCartData[payload?.productId]){
            dispatch({type: UPDATE_CART_COUNT, payload: parseInt(cartCCountLocalStorage) - unAuthUserCartData[payload?.productId]['qty']});
            delete unAuthUserCartData[payload?.productId];
            localStorage.setItem('SS_UN_AUTH_CART_DATA',CryptoJS.AES.encrypt(JSON.stringify(unAuthUserCartData), ENCRYPTION_KEY).toString());
            dispatch({type: UPDATE_CART_COUNT, payload: parseInt(cartCCountLocalStorage) - parseInt(payload?.qty)});
            localStorage.setItem('cartCount', parseInt(cartCCountLocalStorage) - parseInt(payload?.qty));
        }
    }
}

export const updateProductQuantityOnCart = (payload) => async (dispatch) => {
    const userInfo =  localStorage.getItem('user');
    let cartCCountLocalStorage = (localStorage.getItem('cartCount') && !isNaN(localStorage.getItem('cartCount'))) ? localStorage.getItem('cartCount') : 0;
    if(userInfo) {
        await api.post(`products/actionUpdateProductQuantityOnCartByCartId`, {payload:CryptoJS.AES.encrypt(JSON.stringify(payload), ENCRYPTION_KEY).toString()});
        let userRawData = AuthService.parseJwt(JSON.parse(userInfo)?.accessToken);
        dispatch(getUserCartDetails(userRawData?.user?.id));
        if (payload?.type === 'decrease') {
            dispatch({type: UPDATE_CART_COUNT, payload: parseInt(cartCCountLocalStorage) - 1});
        } else {
            dispatch({type: UPDATE_CART_COUNT, payload: parseInt(cartCCountLocalStorage) + 1});
        }
    }else{
        let unAuthUserCartData = localStorage.getItem('SS_UN_AUTH_CART_DATA') ? JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('SS_UN_AUTH_CART_DATA'), ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8)) : {};
        if(unAuthUserCartData[payload?.productId]){
            if (payload?.type === 'decrease') {
                dispatch({type: UPDATE_CART_COUNT, payload: parseInt(cartCCountLocalStorage) - 1});
                unAuthUserCartData[payload?.productId]['qty'] = parseInt(unAuthUserCartData[payload?.productId]['qty'])-1;
                localStorage.setItem('SS_UN_AUTH_CART_DATA',CryptoJS.AES.encrypt(JSON.stringify(unAuthUserCartData), ENCRYPTION_KEY).toString());
            } else {
                unAuthUserCartData[payload?.productId]['qty'] = parseInt(unAuthUserCartData[payload?.productId]['qty'])+1;
                dispatch({type: UPDATE_CART_COUNT, payload: parseInt(cartCCountLocalStorage) + 1});
                localStorage.setItem('SS_UN_AUTH_CART_DATA',CryptoJS.AES.encrypt(JSON.stringify(unAuthUserCartData), ENCRYPTION_KEY).toString());
            }
        }
    }
}

export const actionToPlaceOrderAndSaveData = (payload) => async () => {
    const {data} = await api.post(`products/actionProceedCartProductsPayment`,{payload:CryptoJS.AES.encrypt(JSON.stringify(payload), ENCRYPTION_KEY).toString()});
    if (data){
        const responseData = CryptoJS.AES.decrypt(data, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
       return JSON.parse(responseData)
    }
    /*const userInfo =  localStorage.getItem('user');
    let userRawData =   AuthService.parseJwt(JSON.parse(userInfo)?.accessToken);
    dispatch(getUserCartDetails(userRawData?.user?.id));*/
}
export const createCheckOutOrder = (payload) => async (dispatch) => {
    const {data} = await api.post(`payment/createOrder`,{payload:CryptoJS.AES.encrypt(JSON.stringify(payload), ENCRYPTION_KEY).toString()});
    const responseData = CryptoJS.AES.decrypt(data, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
    return JSON.parse(responseData);

}
export const createCheckOutPonePayOrder = (payload) => async () => {
    const {data} = await api.post(`payment/payment`,{payload:CryptoJS.AES.encrypt(JSON.stringify(payload), ENCRYPTION_KEY).toString()});
    const responseData = CryptoJS.AES.decrypt(data, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
    return JSON.parse(responseData);

}
export const actionToCreateAndSavePaymentGatewayTransaction = (payload) => async () => {
    const {data} = await api.post(`payment/createAndSavePaymentGatewayTransaction`,{payload:CryptoJS.AES.encrypt(JSON.stringify(payload), ENCRYPTION_KEY).toString()});
    const responseData = CryptoJS.AES.decrypt(data, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
    return JSON.parse(responseData);

}
export const actionToUpdatePaymentGatewayTransactionAfterPayment = (payload) => async (dispatch) => {
  return await api.post(`payment/updatePaymentGatewayTransactionAfterPayment`,{payload:CryptoJS.AES.encrypt(JSON.stringify(payload), ENCRYPTION_KEY).toString()});

}
export const actionToUpdateOrderIdInCart = (payload) => async (dispatch) => {
    let data= await api.post(`products/updateOrderIdInCart`,{payload:CryptoJS.AES.encrypt(JSON.stringify(payload), ENCRYPTION_KEY).toString()});
    if(data){
        dispatch({ type: NEW_ORDER_PLACE, payload:{loading:false,data:payload} });
    }

}
export const getUserSavedAddress = (payload) => async (dispatch) => {
    const {data} = await api.post(`products/actionToGetUserSavedAddressDataByUserId`,{payload:CryptoJS.AES.encrypt(JSON.stringify({'userId':payload}), ENCRYPTION_KEY).toString()});
    let requestDataString = CryptoJS.AES.decrypt(data, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
    let addressData = JSON.parse(requestDataString)
    dispatch({ type: USER_SAVE_ADDRESSES, payload: addressData});
}
export const actionToGetAllUserOrdersByUserId = (payload) => async (dispatch) => {
    dispatch({ type: USER_ORDERS, payload:{loading:true,data:[]}});
    const {data} = await apiWithoutHeader.post(`products/actionToGetUserOrdersDataByUserId`,{payload:CryptoJS.AES.encrypt(JSON.stringify({'userId':payload}), ENCRYPTION_KEY).toString()});
    if(data){
        let requestDataString = CryptoJS.AES.decrypt(data, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
        let ordersData = JSON.parse(requestDataString)
        dispatch({ type: USER_ORDERS, payload:{loading:false,data:ordersData}});
    }

}
// export const actionToDownloadInvoicePDFByOrderId = (payload) => async () =>{
//     const {data} = await api.blogpost(`products/actionGetOrderDetailsData`,{'orderId':payload?.id});
//     console.log(data[0]['orderData']);
//     if(data)
//     generateInvoice(data[0]['orderData']);
//
// }
export const actionToDownloadInvoicePDFByOrderId = (payload) => async () => {
    const {data} = await api.post(`products/actionGetOrderDetailsData`,{'orderId':CryptoJS.AES.encrypt(JSON.stringify(payload?.id), ENCRYPTION_KEY).toString()});
    // console.log(data[0]['orderData']);
    let orderData = data[0]['orderData'];
     orderData.netAmount = parseFloat(parseFloat(orderData?.netAmount)-parseFloat(orderData?.shippingCharge)).toFixed(2)
    let subTotalAmount = parseFloat(parseFloat(parseFloat(orderData?.netAmount)+parseFloat(orderData?.discountAmount))- parseFloat(((parseFloat(parseFloat(orderData?.netAmount)+parseFloat(orderData?.discountAmount)) * 18) / 100))).toFixed(2);
    function GetProductRow(orderData){
        let row = '';
        orderData?.products?.map((product,indexKey)=> {
            row+=`<tr key={"product_details_" + orderData?.order_number + indexKey}>
                <td width="10%" align="left">${indexKey+1}</td>
                <td width="15%" align="left">${product?.name}</td>
                <td width="10%"
                    align="left">${(product?.unite_price) ? parseFloat(parseFloat(product?.unite_price) - parseFloat(((product?.unite_price * 18) / 100))).toFixed(2) : '0.00'}</td>
                <td width="10%" align="left">${product?.qty}</td>
                <td width="10%"
                    align="left">${(product?.unite_price) ? parseFloat(parseFloat(parseFloat(product?.unite_price) - parseFloat(((product?.unite_price * 18) / 100))) * product?.qty).toFixed(2) : 0.00}</td>
                <td width="10%"
                    align="left">${(orderData?.shippingStates === 'HARYANA') ? parseFloat(parseFloat(((product?.unite_price * 9) / 100))* product?.qty).toFixed(2) : '0.00'}</td>
                <td width="10%"
                    align="left">${(orderData?.shippingStates === 'HARYANA') ? parseFloat(parseFloat(((product?.unite_price * 9) / 100))* product?.qty).toFixed(2) : '0.00'}</td>
                <td width="10%"
                    align="left">${(orderData?.shippingStates !== 'HARYANA') ? parseFloat( parseFloat(((product?.unite_price * 18) / 100))* product?.qty).toFixed(2) : '0.00'}</td>
                <td width="10%" align="left">${parseFloat(product?.qty * product?.unite_price).toFixed(2)}</td>
            </tr>`;
        });
        return row;
    }
    // return false;
    //let isFileFormat=false;
    let reportName= orderData?.order_number;
    let reportHtmlData= `<table style="border-width: 0px; border-spacing: 0px; margin:0px 0px; font-family:'Arial';" border="0"
           cellPadding="0" cellSpacing="0">
           <tr>
           <td>
           <table style="border-width: 0px; width:96%; border-spacing: 0px; margin:0px 0px 0px 0px; font-family:'Arial';" border="0" cellPadding="2" cellSpacing="0">
               <tr style="border-width: 0px;">
                   <td style="margin: 0px; padding: 0px; border-bottom-width:0px; border-right-width: 0px; border-left-width: 0px; border-top-width:0px; background-color:#0099ff"
                        align="center" >
                       <div style="size:7; color:#ffffff; margin:0px; padding:0px; font-weight:700">Tax Invoice</div>
                    </td>
                </tr>
            </table>
            <td>
            </tr>
           <tr style="border-width: 0px;">
               <td style="margin: 0px; padding: 0px; border-bottom-width:0px; border-right-width: 0px; border-left-width: 0px; border-top-width:0px;"
                    align="left">
                   <table style="border-width: 0px; width:85vh; border-spacing: 0px; margin:5px 0px 0px 0px; font-family:'Arial';" border="0" cellPadding="2" cellSpacing="0">
                        <tr style="border-width: 0px;">                           
                            <td style="text-align: right; margin: 0px; padding: 0px; border-bottom-width:0px; border-right-width: 0px; border-left-width: 0px; border-top-width:0px;"
                            align="right">
                            <table style="border-width: 0px; border-spacing: 0px; width:35vh; margin:0px 0px; font-size:7px"
                                   border="0" cellPadding="2" cellSpacing="0" align="right">
                                <tr style="border-width: 0px;">
                                    <td style="border-width: 0px;" align="left"><b>INVOICE NO:</b></td>
                                    <td style="border-width: 0px;" align="left"> INV-${orderData?.order_number}</td>
                                </tr>
                                <tr style="border-width: 0px;">
                                    <td style="border-width: 0px;" align="left"><b>INVOICE DATE:</b></td>
                                    <td style="border-width: 0px;" align="left">${orderData?.createdAt}</td>
                                </tr>                                
                            </table>
                            </td>
                        </tr>
                   </table>
                </td>
            </tr>
            <tr style="border-width: 0px;">
                <td style="margin: 0px; padding: 0px; border-bottom-width:0px; border-right-width: 0px; border-left-width: 0px; border-top-width:0px;">
                    <table style="border-width: 0px; border-spacing: 0px; margin:5px 0px 0px 0px; font-family:'Arial'; font-size:7px; width:85vh"
                            border="1" cellPadding="2" cellSpacing="0">
                            <tr style="border-width: 0px;">
                                <td style="margin: 0px; width:59%; padding: 0px; border-bottom-width:0px; border-right-width: 0px; border-left-width: 0px; border-top-width:0px;"
                                    align="left">
                                    <table style="border-width: 0px; border-spacing: 0px; width:100%; margin:0px 0px; font-size:7px"
                                           border="1" cellPadding="1" cellSpacing="0">                                        
                                        <tr style="border-width: 0px;">
                                            <td style="border-width: 0px; width:40%" align="left"><b>GSTIN:</b></td>
                                            <td style="border-width: 0px; width:60%" align="left">06GEPPS412E1Z7</td>
                                        </tr>
                                        <tr style="border-width: 0px;">
                                            <td style="border-width: 0px;" align="left"><b>STATE:</b></td>
                                            <td style="border-width: 0px;" align="left">SSORD-36437</td>
                                        </tr>
                                        <tr style="border-width: 0px;">
                                            <td style="border-width: 0px;" align="left"><b>PIN: </b></td>
                                            <td style="border-width: 0px;" align="left">110055</td>
                                        </tr>
                                        <tr style="border-width: 0px;">
                                            <td style="border-width: 0px;" align="left"><b>E-MAIL:</b></td>
                                            <td style="border-width: 0px;" align="left"><a
                                                href="mailto:info@shikshaksolutions.com">info@shikshaksolutions.com</a></td>
                                        </tr>
                                        <tr style="border-width: 0px;">
                                            <td style="border-width: 0px;" align="left"><b>Phone:</b></td>
                                            <td style="border-width: 0px;" align="left">+915999185056</td>
                                        </tr>
                                        <tr style="border-width: 0px;">
                                            <td style="border-width: 0px;" align="left"><b>Billing Address: </b>
                                            </td>
                                            <td style="border-width: 0px;" align="left">${orderData?.billingFullname}
                                            </td>
                                        </tr>
                                        <tr style="border-width: 0px;">
                                            <td style="border-width: 0px;" align="left"><b>GSTIN:</b></td>
                                            <td style="border-width: 0px;" align="left">${orderData?.billingGstno}</td>       
                                        </tr>
                                        <tr style="border-width: 0px;">
                                            <td style="border-width: 0px;" align="left">
                                                <b>ADDRESS: </b>                                                
                                            </td>
                                            <td style="border-width: 0px;" align="left">                                                
                                                <div>
                                                   ${orderData?.billingCity}
                                                </div>
                                            </td>
                    
                                        </tr>
                                        <tr style="border-width: 0px;">
                                            <td style="border-width: 0px;" align="left"><b>STATE:</b></td>
                                            <td style="border-width: 0px;" align="left"> ${orderData?.billingStates}</td>
                                        </tr>
                                        <tr style="border-width: 0px;">
                                            <td style="border-width: 0px;" align="left"><b>PIN:</b></td>
                                            <td style="border-width: 0px;" align="left">${orderData?.billingPincode}</td>
                                        </tr>
                                        <tr style="border-width: 0px;">
                                            <td style="border-width: 0px;" align="left"><b>EMAIL:</b></td>
                                            <td style="border-width: 0px;" align="left"> ${orderData?.user_email}</td>    
                                        </tr>
                                        <tr style="border-width: 0px;">
                                            <td style="border-width: 0px;" align="left"><b>Phone:</b> </td>
                                            <td style="border-width: 0px;" align="left"> ${orderData?.billingPhoneNo}</td>
                                        </tr>
                                    </table>
                                </td>
                                <td style="text-align:right; width:41%; margin: 0px; padding: 0px; border-bottom-width:0px; border-right-width: 0px; border-left-width: 0px; border-top-width:0px;"
                                    align="right" valign="top">
                                    <table style="border-width: 0px; border-spacing: 0px; width:32vh; margin:0px 0px; font-size:7px; text-align: right;"
                                           border="1" cellPadding="3" cellSpacing="0">
                                        <tr style="border-width: 0px;">
                                            <td style="border-width: 0px;" align="left"><b>Shipping Address: </b>                                                
                                            </td>
                                             <td style="border-width: 0px;" align="left">
                                                ${orderData?.shiippingFullname}
                                            </td>
                                        </tr>
                                        <tr style="border-width: 0px;">
                                            <td style="border-width: 0px;" align="left"><b>GSTIN:</b></td>
                                             <td style="border-width: 0px;" align="left">${orderData?.shippingGstno}</td>
                                        </tr>
                                        <tr style="border-width: 0px;">
                                            <td style="border-width: 0px;" align="left">
                                                <b>ADDRESS:</b>                                                
                                            </td>
                                            <td style="border-width: 0px;" align="left">                                                
                                                <div>
                                                    ${orderData?.shippingCity}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style="border-width: 0px;">
                                            <td style="border-width: 0px;" align="left"><b>STATE:</b></td>
                                            <td style="border-width: 0px;" align="left"> ${orderData?.shippingStates}</td>                    
                                        </tr>
                                        <tr style="border-width: 0px;">
                                            <td style="border-width: 0px;" align="left"><b>PIN:</b></td>
                                            <td style="border-width: 0px;" align="left">${orderData?.shippingPincode}</td> 
                                        </tr>
                                        <tr style="border-width: 0px;">
                                            <td style="border-width: 0px;" align="left"><b>EMAIL:</b></td>
                                            <td style="border-width: 0px;" align="left"> ${orderData?.user_email}</td>
                                        </tr>
                                        <tr style="border-width: 0px;">
                                            <td style="border-width: 0px;" align="left"><b>Phone:</b></td>
                                            <td style="border-width: 0px;" align="left"> ${orderData?.shippingPhoneNo}</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                    </table>
                </td>
            </tr>
        <tr style="border-width: 0px;">
            <td style="margin: 0px; padding: 0px; border-bottom-width:0px; border-right-width: 0px; border-left-width: 0px; border-top-width:0px;">
                  <table style="border-width: 0px; border-spacing: 0px; margin:20px 0 0px 0px; font-family:'Arial'; font-size:7px; width:81.5vh"
                    border="0" cellPadding="2" cellSpacing="0">
                    <thead style="background-color:#6CB4EE">
                    <tr>
                        <th width="10%" align="left" style="color:#ffffff">SL</th>
                        <th width="10%" align="left" style="color:#ffffff">Product</th>
                        <th width="15%" align="left" style="color:#ffffff">Unite Price</th>
                        <th width="10%" align="left" style="color:#ffffff">Qty</th>
                        <th width="15%" align="left" style="color:#ffffff">Net Amount</th>
                        <th width="10%" align="left" style="color:#ffffff">CGST</th>
                        <th width="10%" align="left" style="color:#ffffff">SGST</th>
                        <th width="10%" align="left" style="color:#ffffff">IGST</th>
                        <th width="10%" align="left" style="color:#ffffff">Total</th>
                    </tr>
                    </thead>
                    <tbody style="background-color:#F0F8FF">
                                                   
                   ${GetProductRow(orderData)}
                    <tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td style="margin: 0px; text-align: right; padding:20px 20px 0 0px; border-bottom-width:0px; border-right-width: 0px; border-left-width: 0px; border-top-width:0px;"
                align="right">
                <table style="border-width: 1px; border-spacing: 0px; margin:0px 0px; font-size:7px; width:40%" border="0"
                       cellPadding="2" cellSpacing="0" align="right">
                    <tr style="border-width: 0px;">
                        <td style="border-width: 0px;" align="left"><b>Sub Total: </b></td>
                        <td style="border-width: 0px;">Rs ${parseFloat(parseFloat(parseFloat(orderData?.netAmount)+parseFloat(orderData?.discountAmount))- parseFloat(((parseFloat(parseFloat(orderData?.netAmount)+parseFloat(orderData?.discountAmount)) * 18) / 100))).toFixed(2)}</td>
                    </tr>`;
                    if(orderData?.shippingStates === 'HARYANA'){
                        reportHtmlData+=`<tr style="border-width: 0px;">
                        <td style="border-width: 0px;" align="left"><b>CGST Rs.</b></td>
                        <td style="border-width: 0px;">Rs.${(orderData?.shippingStates === 'HARYANA') ? parseFloat(parseFloat(((parseFloat(parseFloat(orderData?.netAmount)+parseFloat(orderData?.discountAmount)) * 9) / 100))).toFixed(2) : '0.00'}</td>
                    </tr>
                    <tr style="border-width: 0px;">
                        <td style="border-width: 0px;" align="left"><b>SGST Rs.</b></td>
                        <td style="border-width: 0px;">Rs.${(orderData?.shippingStates === 'HARYANA') ? parseFloat(parseFloat(((parseFloat(parseFloat(orderData?.netAmount)+parseFloat(orderData?.discountAmount)) * 9) / 100))).toFixed(2) : '0.00'}</td>
                    </tr>`
                    }else{
                        reportHtmlData+=`<tr style="border-width: 0px;">
                        <td style="border-width: 0px;" align="left"><b>IGST Rs.</b></td>
                        <td style="border-width: 0px;">Rs.${(orderData?.shippingStates !== 'HARYANA') ? parseFloat(parseFloat(((parseFloat(parseFloat(orderData?.netAmount)+parseFloat(orderData?.discountAmount)) * 18) / 100))).toFixed(2) : '0.00'}</td>
                    </tr>`
                    }

    reportHtmlData+=`<tr style="border-width: 0px;">
                        <td style="border-width: 0px;" align="left"><b>Total GST Rs.</b></td>
                        <td style="border-width: 0px;">Rs.${ parseFloat(parseFloat(((parseFloat(parseFloat(orderData?.netAmount)+parseFloat(orderData?.discountAmount)) * 18) / 100))).toFixed(2)}</td>
                    </tr>`;
                    if(parseFloat(orderData?.discountAmount)>0){
                        reportHtmlData+=`<tr style="border-width: 0px;">
                        <td style="border-width: 0px;" align="left"><b>Discount.</b></td>
                        <td style="border-width: 0px;">Rs. ${parseFloat(orderData?.discountAmount).toFixed(2)}</td>
                    </tr>`;
                    }
    if(parseFloat(orderData?.shippingCharge)>0){
        reportHtmlData+=`<tr style="border-width: 0px;">
                        <td style="border-width: 0px;" align="left"><b>Shipping.</b></td>
                        <td style="border-width: 0px;">Rs. ${parseFloat(orderData?.shippingCharge).toFixed(2)}</td>
                    </tr>`;
    }
    reportHtmlData+=`<tr style="border-width: 0px;">
                        <td style="border-width: 0px;" align="left"><b>Grand Total Rs.</b></td>
                        <td style="border-width: 0px;">Rs.${parseFloat((parseFloat(orderData?.netAmount)+parseFloat(orderData?.shippingCharge))).toFixed(2) }</td>
                    </tr>
                    <tr style="border-width: 0px; ">
                        <td style="border-width: 0px; padding-top:15px" colSpan="2" align="left"><b>For SHIKSHAK
                            SOLUTIONS</b></td>
                    </tr>
                    <tr style="border-width: 0px;">
                        <td style="border-width: 0px; padding-top:15px" colSpan="2" align="left"><b>Authorised
                            Signatory</b></td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>`;
    let filename = `${reportName}.pdf`;
    let wrapper= document.createElement('div');
    wrapper.innerHTML = reportHtmlData;
    let tableHTML = wrapper.firstChild;
    const doc = new JsPDF('p', 'px', 'a4');
    doc.setDisplayMode(2);
    doc.addImage(logo, 'jpg',10, 45, 170, 30, '', false);
    if(!payload?.isFileFormat) {
        doc.html(tableHTML, {
            async callback(doc) {
                await doc.save(filename);
            },
            x: 10,
            y: 10,
            autoPaging: 'text',
            html2canvas: { scale: 1.2},
            width: 700,
            margin: [0, 0, 26, 0],
        });
    }else{
        return new Promise(resolve => {
            return doc.html(tableHTML, {
                async callback(doc) {
                    let blobData = doc.output("blob");
                    let blob = new Blob([blobData], { type: 'application/pdf' });
                    let file = new File([blob], filename, {type: "application/pdf"});
                    console.log(file,"file")
                    resolve(file);
                },
                x: 10,
                y: 10,
                autoPaging: 'text',
                html2canvas: {scale: 1.2},
                width: 700,
                margin: [0, 0, 26, 0],
            });
        })
    }
}

export const actionGetOrderDetailsData = (payload) => async (dispatch) => {
    const {data} = await api.post(`products/actionGetOrderDetailsData`,{'orderId':payload?.id});
    dispatch({ type: USER_CURRENT_ORDER_DATA, payload: data[0]?.orderData});
    return data;
}
export const actionToCancelOrderAndRefundAmount = (payload)=> async ()=>{
    const {data} = await api.post(`products/actionToCancelOrderAndRefund`,{'orderId':payload});
    return data;

}
export const actionToGetCouponCodeDetailsByCouponCode = (payload)=> async ()=>{
    const {data} = await api.post(`products/actionToGetCouponDetalsByCouponCode`,{payload:CryptoJS.AES.encrypt(JSON.stringify(payload), ENCRYPTION_KEY).toString()});
    return data;

}
export const actionToSavePaymentData = (payload)=> async ()=>{
    console.log(payload,"payload")
    const {data} = await api.post(`payment/savePaymentAPICall`,{payload:CryptoJS.AES.encrypt(JSON.stringify(payload), ENCRYPTION_KEY).toString()});
    return data;

}
export const actionToSendOrderPlacedEmailWithInvoice = (formData)=> async ()=>{
    fetch(API_URL+'payment/sendOrderPlacedEmailWithInvoiceAPICall', {method: 'POST', body: formData}).then(()=>{
    }).catch(err => {
        console.error(err);
    });

}
export const actionToUploadPaymentReceipt =(payload)=>async (dispatch)=>{
    let filename = `payment_receipt/ss-receipt-${new Date().getTime()}.png`;
    let imagePayload = {
        blobData:payload?.photo,
        key:filename,
        type:'img/png',
    }
    let image = await dispatch(actionToUploadDataToS3BucketStore(imagePayload));
    if(image){
        const {data} = await api.post(`payment/updateTransactionAttachment`,{payload:CryptoJS.AES.encrypt(JSON.stringify({path:image,id:payload?.id}), ENCRYPTION_KEY).toString()});
        return data;
    }
    return image;

}
 export const actionToOpenPayAndUploadInvoiceModal=(orderData)=>async (dispatch)=>{
     dispatch({ type: OPEN_CLOSE_PAY_UPLOAD_INVOICE_MODAL, payload: {open:true,orderData:orderData}});
 }
export const actionToClosePayAndUploadInvoiceModal=()=>async (dispatch)=>{
    dispatch({ type: OPEN_CLOSE_PAY_UPLOAD_INVOICE_MODAL, payload: {open:false,orderData:[]}});
}
export const actionToSendOrderPlacedEmail=(orderData)=>async(dispatch)=>{
    let fileData = null;
    let sendReportMessage = `<body style="margin: 0;   padding: 0;">    
                        <table width="100%" cellspacing="0" cellpadding="0" border="0">
                          <tbody>
                            <tr>
                              <td style="line-height: normal;border:0;padding: 60px;" bgcolor="#EDF5FA" align="center">
                               <h1>Thank you For shopping with us</h1>
                               <h5>Dear Customer,<br/>
                               Your Order ${orderData?.order_number} has benn placed successfully.
                               Go To My account Section to order details.
                               <br/>
                               <br/>
                               <br/>
                               Thank You 
                               Team Shikshak Solution
                               </h5>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                    </body>`;
    let sendReportMessageText = `Thank you For shopping with us`;
    await dispatch(actionToDownloadInvoicePDFByOrderId({
        id: orderData.id,
        isFileFormat: true
    })).then((invoiceFile) => {
        fileData = invoiceFile;
        const formData = new FormData();
        formData.append('file', fileData);
        formData.append('email_address', orderData?.user_email);
        formData.append('order_id', orderData.id);
        formData.append('html', sendReportMessage);
        formData.append('text', sendReportMessageText);
        dispatch(actionToSendOrderPlacedEmailWithInvoice(formData));
        // Updating cart count
    });
}
export const updateDefaultAddress = (payload) => async () => {
    const {data} = await api.post(`products/actionToUpdateDefaultDeliveryAddress`,{payload:CryptoJS.AES.encrypt(JSON.stringify({'userId':payload?.userId,'ids':payload?.ids}), ENCRYPTION_KEY).toString()});
  return data;
}
export const getAllPincodeDetails = () => async (dispatch) => {
    const {data} = await api.post(`common/actionToGetAllPincodeDetails`,);
    // // let requestDataString = CryptoJS.AES.decrypt(data, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
    // // let addressData = JSON.parse(requestDataString)
    // console.log(data,"addressData")
    dispatch({ type: ALL_PINCODE_DETAILS, payload: data?.response});
}
export const actionToCheckDeliveryAvailability = (payload,callFunctionToFinishCheckDeliveryAvailabilityProcess) => async (dispatch) => {
    const {data} = await api.post(`common/actionToCheckDeliveryAvailabilityOnPincode`,{payload:CryptoJS.AES.encrypt(JSON.stringify({'pinCode':payload?.pinCode}), ENCRYPTION_KEY).toString()});
    console.log(data,"data")
    if(data?.response?.length>0) {
        if (data.response[0]?.is_shipping_available === '0') {
            callFunctionToFinishCheckDeliveryAvailabilityProcess('0', 'Shipping is not available at your pin code.',0,0,0)
        }else{
            if(data.response[0]?.is_cod_available === '1'){
                callFunctionToFinishCheckDeliveryAvailabilityProcess('11', 'Shipping available at your pin code with cod.',data.response[0]?.is_free_shipping,data.response[0]?.shipping_charge,data.response[0]?.minimum_order_amount_to_free_shipping)
            }else{
                callFunctionToFinishCheckDeliveryAvailabilityProcess('10', 'Shipping available at your pin code without cod.',data.response[0]?.is_free_shipping,data.response[0]?.shipping_charge,data.response[0]?.minimum_order_amount_to_free_shipping)
            }
        }
    }else{
        callFunctionToFinishCheckDeliveryAvailabilityProcess('0', 'Shipping is not available at your pin code.',0,0,0)
    }
}
export const actionToGetAllStateList = () => async (dispatch) => {
    const response = await api.post(`common/actionToGetAllStateList`);
    dispatch({ type: ALL_STATE_LIST, payload: response.data?.data});
}