import React, {Component} from 'react';
import {useNavigate} from 'react-router-dom';
import {encryptData} from "../../helper/CommonHelper";
import CryptoJS from "crypto-js";
import moment from "moment";
import {actionAddToCart} from "../../store/actions/shopAction";
import {toast} from "react-toastify";
import AuthService from "../services/auth.service";
import {useDispatch} from "react-redux";
import {NotificationManager} from "react-notifications";

class ProductList extends Component{
    render(){
        let {item}= this.props;

        const userInfo =  localStorage.getItem('user');
        let userRawData =   AuthService.parseJwt(JSON.parse(userInfo)?.accessToken);
        const ENCRYPTION_KEY = "XkhZG4fW2t2W";
        const openLinkPage = (url) => {
            this.props.navigate(url);
        }
        const openProductDetails =(e,id)=>{
            console.log(e,"e");
            if(e.target.className!=='btnAddToCart' && e.target.className!=='fas fa-shopping-cart btnAddToCart'){
                openLinkPage(`/product/${CryptoJS.AES.encrypt(
                    JSON.stringify(id),
                    ENCRYPTION_KEY
                ).toString()}`)
            }

        }
        const addToCart = (productDetailsData) => {
                let payload = {
                    'productId': productDetailsData?.id,
                    'qty': 1,
                    'userId': userRawData?.user?.id,
                    'createdAt': moment().format("YYYY-MM-DD HH:mm:ss"),
                    'name': productDetailsData?.name,
                    'photo': productDetailsData?.photo,
                    'price': productDetailsData?.price,
                }
                console.log(payload,"payload")
            this.props.dispatch(actionAddToCart(payload)).then(async (responseAddToCart)=>{
                    if(responseAddToCart){
                        NotificationManager.success('Product has been added to cart.');
                    }else{
                        NotificationManager.error('Product can`t add to cart please try again later.');
                    }

                })

        }
        
        return(

            <a className={"openProductDetais"} onClick={(e)=>openProductDetails(e,item?.id)}>
            <section className={`product-slider-panel1`}>
                {/*<span className="pro-title2">{item?.name}</span>*/}
                <div className="pro-img-box">
                       <img src={item?.photo} alt="" /></div>
                    <div className="product-slide-textbox">
                        <span className="pro-title">{item?.name}</span>
                        <br/>
                        <span className={"star-rating"}>
                            <span>{item?.avg_rating ? parseFloat(item?.avg_rating).toFixed(1):"5.0"}
                            &nbsp; <svg xmlns="http://www.w3.org/2000/svg" height="12" width="14" viewBox="0 0 576 512"><path fill={"#ffffff"} d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg>
                            </span>
                        </span>
                            <span> {item?.total_product_review} Reviews</span>

                        <div className="product-slider-pricebox">
                            <div className="product-slider-price">
                                <ul className={"list_item"}>
                                    <li><b>Category:</b> {item?.category}</li>
                                    <li><b>For Class:</b> {item?.min_class} To {item?.max_class}</li>
                                </ul>
                                <span className="product-slider-price_inner price"><h6>
                                    <span className="product-rupees">₹ </span> {item?.price}
                                {/* - <span className="product-rupees">₹ </span> 1,999*/}
                                </h6></span>
                            </div>
                        </div>
                        <div className='d-inline-flex align-items-center justify-content-between w-100'>
                        <button className='buynowbtn' type='submit'>BUY NOW</button>
                        <button className='cartbtn btnAddToCart ml-auto' onClick={()=>addToCart(item)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                 className="bi bi-bag-check-fill" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                      d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0m-.646 5.354a.5.5 0 0 0-.708-.708L7.5 10.793 6.354 9.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"/>
                            </svg>
                        </button>
                        </div>
                    </div>
            </section>
            </a>
        )
    }
}
function WithNavigate(props) {
    let navigate = useNavigate();
    const dispatch  = useDispatch();
    return <ProductList {...props} navigate={navigate} dispatch={dispatch} />
}
export default WithNavigate;
