import React from 'react';
import {useNavigate} from 'react-router-dom';
import CryptoJS from "crypto-js";

function ProductRelated({relatedProduct}) {
    let navigate = useNavigate();
    const ENCRYPTION_KEY = "XkhZG4fW2t2W";
    const openLinkPage = (url) => {
        navigate(url);
        window.location.reload();
    }
  return (
    <div>
        <div className="gray-box-light mt-5">
        <section>
            {(relatedProduct && relatedProduct?.length>0) ?
                <>
        <h5 className='mb-5 border-bottom-1 pb-3 border-bottom-1'>Related Products:</h5>
          <div className="card-group col-md-12">
              <>
              {relatedProduct?.map((row,key) => (
              <div className="d-block" key={key+row?.title}  onClick={()=>openLinkPage(`/product/${CryptoJS.AES.encrypt(
                  JSON.stringify(row?.id),
                  ENCRYPTION_KEY
              ).toString()}`)}>
                  <div className="card mb-6 px-10">
                      <img className='p-24' src={row?.photo} height={200} width={200}   alt="..."/>
                  </div>
                  <div className="card-body">
                      <div className="mt-2">{row?.title}</div>
                      <div className="price">₹ {row?.price}</div>
                  </div>
              </div>
              ))}
                  </>

          </div>
                </>:""}
    
    </section>
    </div>
    </div>
  )
}

export default ProductRelated
