import {useParams} from "react-router-dom";
import CryptoJS from "crypto-js";
import React, {useEffect, useState} from "react";
import moment from "moment";
import {actionGetProductReviewDetails, actionSaveProductReview} from "../store/actions/productAction";

import {NotificationManager} from "react-notifications";
import {useDispatch, useSelector} from "react-redux";
import AuthService from "../components/services/auth.service";
import {useEffectOnce} from "../hooks/useEffectOnce";
import {cloneDeep} from "lodash";
import CustomNavbar from "../components/layout/CustomNavbar";
import WriteProductReviewAndRatting from "../components/product/WriteProductReviewAndRatting";
import Footer from "../components/layout/Footer";
const ENCRYPTION_KEY = "XkhZG4fW2t2W";
export default function WriteReview() {
    const userInfo =  localStorage.getItem('user');
    let userRawData =   AuthService.parseJwt(JSON.parse(userInfo)?.accessToken);
    const dispatch  = useDispatch();
    let para = useParams();
    let id= Object.values(para)[0];
    let productId = CryptoJS.AES.decrypt(id, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
    const [selectedRating, setSelectedRating] = useState(0);
    const [selectedRatingText, setSelectedRatingText] = useState('');
    const [selectedTitleText, setSelectedTitleText] = useState('');
    const [selectedImages, setSelectedImages] = useState([]);
    const selectedProductReviewData = useSelector((state) => state.productReducer.selectedProductReviewData);

    useEffectOnce(()=>{
        if(localStorage.getItem('user')){
            let payload = {
                'productId': productId,
                'userId': userRawData?.user?.id,
            }
            dispatch(actionGetProductReviewDetails(payload));
        }
    });

    useEffect(()=>{
        if (selectedProductReviewData?.data?.id){
            setSelectedRating(selectedProductReviewData?.data?.rating);
            setSelectedRatingText(selectedProductReviewData?.data?.review_description);
            setSelectedTitleText(selectedProductReviewData?.data?.review_title);
        }
    },[selectedProductReviewData]);

    const selectRating = (rating)=>{
        setSelectedRating(rating);
    }

    const handleRatingTextChange = e => {
        e.preventDefault();
        setSelectedRatingText(e.target.value);
    }

    const handleTitleTextChange = e => {
        e.preventDefault();
        setSelectedTitleText(e.target.value);
    }

    const onFileChange = event => {
        let newImages = cloneDeep(selectedImages);
        newImages.push(event.target.files[0]);
        setSelectedImages(newImages);
    };

    const saveProductRating = () =>{
        if (selectedRating > 0){
            let payload = {
                'productId': productId,
                'userId': userRawData?.user?.id,
                'selectedRating': selectedRating,
                'selectedRatingText': selectedRatingText,
                'selectedTitleText': selectedTitleText,
                'selectedImages': selectedImages,
                'createdAt': moment().format("YYYY-MM-DD HH:mm:ss"),
            }

            dispatch(actionSaveProductReview(payload)).then(async (responseAddToCart)=>{
                if(responseAddToCart){
                    NotificationManager.success("Product Rating Added.");
                }else{
                    NotificationManager.success("Product rating can`t added, please try again later.");

                }
            })
        }
    }

    return (
        <div className="body_wrapper">
            <CustomNavbar mClass="menu_four hosting_menu" nClass="w_menu m-auto" slogo="sticky_logo" hbtnClass="event_btn"/>
            <WriteProductReviewAndRatting/>
            <Footer/>
        </div>)

}