import React from 'react';
import {useNavigate} from 'react-router-dom';
import CryptoJS from "crypto-js";
import moment from "moment";
import ProductReviewsImagesComponent from "./ProductReviewsImagesComponent";

function ProductRatingAndReviews({productDetailsData}) {
    let navigate = useNavigate();
    let productReviews =productDetailsData?.reviews;
    const ENCRYPTION_KEY = "XkhZG4fW2t2W";
    const openLinkPage = (url) => {
        navigate(url);
        window.location.reload();
    }
    return (
        (productReviews && productReviews?.length>0) ?
            <>
        <div>
            <div className="gray-box-light mt-5">
                <section>
                        <h5 className='mb-5 border-bottom-1 pb-3 border-bottom-1'>Ratings & Reviews:</h5>
                            <div className="card-group col-md-12">
                                <>
                                    {productReviews?.map((row,key) => (
                                        <div className="d-block" key={key+row?.id}>
                                            <div className="card-body">
                                                {(row?.review_rating) ? <div className="mt-2">{row?.review_rating} *</div>:<div className="mt-2"></div>}
                                                <div className="mt-2">{row?.review_title}</div>
                                                <div className="price" dangerouslySetInnerHTML={{__html: row?.review_description}}></div>
                                                <ProductReviewsImagesComponent reviewPhotos={row?.review_photos}/>
                                                <div className="price">{row?.review_by} Certified Buyer {row?.review_location} {moment(row?.review_created_at).format("LLL")}</div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            </div>
                </section>
            </div>
        </div>
                </>:""
    )
}

export default ProductRatingAndReviews
