import React,{useState,useEffect} from 'react';
import {useEffectOnce} from "../../hooks/useEffectOnce";
import {useDispatch, useSelector} from "react-redux";
import AuthService from "../services/auth.service";
import PreLoader from "./PreLoader";
import OrderTracking from "./OrderTracking";
import {
    actionToCancelOrderAndRefundAmount,
    actionToDownloadInvoicePDFByOrderId,
    actionToGetAllUserOrdersByUserId, actionToOpenPayAndUploadInvoiceModal, actionToUploadPaymentReceipt
} from "../../store/actions/shopAction";
import moment from "moment";
import {toast} from "react-toastify";
import PayAndUploadInvoiceComponent from "../payment/PayAndUploadInvoiceComponent";
import {cloneDeep} from "lodash";
import {NotificationManager} from "react-notifications";
import CryptoJS from "crypto-js";

function ListOfOrders() {
    const [orderData,setOrderData]= useState([]);
    const [loadingData,setLoadingData]= useState(true);
    const userInfo = localStorage.getItem('user');
    const dispatch = useDispatch();
    const ENCRYPTION_KEY = "XkhZG4fW2t2W";
    let userRawData =   AuthService.parseJwt(JSON.parse(userInfo)?.accessToken);
    useEffectOnce(()=>{
        if(userRawData?.user?.id){
            dispatch(actionToGetAllUserOrdersByUserId({'userId':userRawData?.user?.id}))
             //dispatch(actionToDownloadInvoicePDFByOrderId());
        }

    });
    const userOrdersData = useSelector((state) => state.cartReducer.userOrdersData);


    const payAndUploadReceipt=(orderData)=>{
        dispatch(actionToOpenPayAndUploadInvoiceModal(orderData));
    }
    const downloadInvoicePdfByOrderId = (id) =>{
        dispatch(actionToDownloadInvoicePDFByOrderId({id,isFileFormat:false}));
    }
    const cancelOrderByOrderId = async (id)=>{
        await dispatch(actionToCancelOrderAndRefundAmount(id)).then((response)=> {
            if(response?.success){
                let clonedOrderData =cloneDeep(orderData);
                clonedOrderData?.map((order,index)=>{
                    if(parseInt(order?.orderData?.id)===parseInt(id)){
                        clonedOrderData[index]['orderData']['status']='cancel';
                        clonedOrderData[index]['orderData']['payment_status']='2';
                        NotificationManager.success("Your Order is cancelled successfully.");
                    }
                });
                setOrderData(clonedOrderData);
            }
        });
    }
    useEffect(() => {
        setLoadingData(userOrdersData?.loading);
        if(!userOrdersData?.loading && userOrdersData?.data){
            setOrderData(userOrdersData?.data);
            setLoadingData(false);
        }

    },[userOrdersData]);
    return (
        <>
            <section>
                {(loadingData) ?
                <>

                    <PreLoader />

                </>:
                    <div className={"container custom_container p0"}>
                        {(orderData?.length>0) ?
                            <>
                            {orderData?.map((order,key)=> (
                                <>
                                    <div className={"row"} key={"order_details_" + key}>
                                        <div className={"col-md-12"}>
                                            <div className={"order-status"}></div>
                                                <table className="table mb-5 mt-1" border={"1"}>
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={4}>
                                                                <div className={"order-heading-section"}>
                                                                <div className='order-id-title'>Order ID: {order?.orderData?.order_number}</div>
                                                                <div className='order-date-tiem'>Order date:{moment(order?.orderData?.createdAt).format('Do MMM YYYY  h:mm a')} </div>
                                                                    <button className='common-btn1' onClick={()=>downloadInvoicePdfByOrderId(order?.orderData?.id)}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width={"20"} viewBox="0 0 640 512" fill={"#fff"}><path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-167l80 80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39 39V184c0-13.3-10.7-24-24-24s-24 10.7-24 24V318.1l-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9z"/></svg>
                                                                        Download Invoice</button>
                                                                    {(order?.orderData?.status!=='cancel' && order?.orderData?.status!=='return' && order?.orderData?.status!=='delieverd') ?
                                                                        <button className='common-btn1' onClick={()=>cancelOrderByOrderId(order?.orderData?.id)}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="20px" height="20px"><path fill="#f44336" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"/><path fill="#fff" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z"/><path fill="#fff" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z"/></svg>
                                                                            Cancel</button>:""
                                                                    }

                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td colSpan={"4"}>
                                                            <OrderTracking status={order?.orderData?.status}/>
                                                        </td>
                                                    </tr>
                                                     <tr>
                                                        <td scope="row" colSpan={4}>
                                                            <>
                                                                {order?.orderData?.products?.map((product,indexKey)=>(
                                                                    <>
                                                                        <div className={"d-inline-flex flex-row space"}>
                                                                            <div key={"product_details_" + order?.orderData?.order_number+indexKey} className={"product-slider-panel1"}>
                                                                                <div className="d-inline-flex flex-column">
                                                                                    <img src={product?.photo} height={150} width={150} alt="..."/>
                                                                                    <h6 className='mt-2'>{product?.name}</h6>
                                                                                    <div className={"price"}>₹ {parseFloat(product?.qty*product?.unite_price).toFixed(2)}</div>
                                                                                    <div className={"price"}><a href={`/write-review/${CryptoJS.AES.encrypt(
                                                                                        JSON.stringify(product?.id),
                                                                                        ENCRYPTION_KEY
                                                                                    ).toString()}`}>Rate and Review this product</a></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ))}
                                                            </>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Total Amount</th>
                                                        <th>Discount</th>
                                                        <th>Net Amount</th>
                                                        <th>Payment Status</th>
                                                    </tr>
                                                    <tr>
                                                        <td>₹{parseFloat(parseFloat(order?.orderData?.netAmount)+parseFloat(order?.orderData?.discountAmount)).toFixed(2)}</td>
                                                        <td>{(order?.orderData?.discountAmount>0) ? "-₹"+order?.orderData?.discountAmount+" ("+order?.orderData?.discountCoupon+")":"₹0.00" }</td>
                                                        <td>₹{order?.orderData?.netAmount}</td>
                                                        <td>{(order?.orderData?.payment_status ==='1') ?
                                                            <span className="paid"><svg
                                                                xmlns="http://www.w3.org/2000/svg" width="16"
                                                                height="16" fill="currentColor"
                                                                className="bi bi-check-lg" viewBox="0 0 16 16"><path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022"/></svg> Paid </span>
                                                            : (order?.orderData?.payment_status ==='2') ?
                                                            <span className="refunded"><svg
                                                                xmlns="http://www.w3.org/2000/svg" width="20"
                                                                height="20" fill="currentColor" className="bi bi-x"
                                                                viewBox="0 0 16 16"> <path  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/></svg> Refunded </span>
                                                        :<span className="unpaid"><svg
                                                                    xmlns="http://www.w3.org/2000/svg" width="20"
                                                                    height="20" fill="currentColor" className="bi bi-x"
                                                                    viewBox="0 0 16 16"> <path  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/></svg> Unpaid </span>} </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={"4"} className={"text-end"}>
                                                            {(order?.orderData?.status!=='cancel') ?
                                                                <>

                                                                    {(order?.orderData?.payment_status ==='0' && order?.orderData?.direct_payment_transaction_id && !order?.orderData?.direct_payment_attachment_path) ?
                                                                        <>
                                                                            <div className="text-right">
                                                                                <button className='common-btn1 m-0' onClick={()=>payAndUploadReceipt(order?.orderData)}>
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width="16" height="16"
                                                                                        fill="currentColor"
                                                                                        className="bi bi-receipt"
                                                                                        viewBox="0 0 16 16">
                                                                                        <path
                                                                                            d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27m.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0z"/>
                                                                                        <path
                                                                                            d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5"/>
                                                                                    </svg> Pay & Upload Invoice</button>
                                                                            </div>
                                                                        </>
                                                                        :<>
                                                                            {(order?.orderData?.payment_status ==='0' && order?.orderData?.direct_payment_transaction_id && order?.orderData?.direct_payment_attachment_path) ?
                                                                                <p>Payment Receipt is uploaded.</p>:""}
                                                                        </>
                                                                    }


                                                                    {/*<button className='common-btn1' onClick={()=>cancelOrder(order?.orderData?.id)}> Cancel Order</button>*/}</>
                                                                :""}
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                        </div>


                                    </div>
                                </>
                            ))}
                            </>

                            :<><h3>No Orders found</h3> </>}

                    </div>

                }
                </section>
            <PayAndUploadInvoiceComponent/>
        </>
    )
}

export default ListOfOrders
