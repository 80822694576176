import React, {Component} from "react";
import withRouter from "../../hooks/withRouter";
import LearningEnrichment from "../../image/pmshri/learning-enrichment.png";
import Vocational from "../../image/pmshri/vocational.png";
import RawMaterial from "../../image/pmshri/raw-materials.webp";
import Hygiene from "../../image/pmshri/hygiene.png";
import MathsCircle from "../../image/pmshri/mathscircle.png";
import VigyanJoti from "../../image/pmshri/vigyan-jyoti.png";
import BandSet from "../../image/pmshri/band set.webp";
import MusicalInstruments from "../../image/pmshri/musical-instruments.webp";
import CenturySkills from "../../image/pmshri/21st century.png";
import HealthDevices from "../../image/pmshri/health-equipments.png";
import ScienceCircle from "../../image/pmshri/science-circle.png";

class PMShriPhaseSecond extends Component {
    render(){
        return(
            <section className="tracking_product_area sec_pad">
                <div className="container">
                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>Learning Enrichment Program</h3>
                                    <p>Shikshak Solutions offers a unique Learning Enrichment Program under the PM SHRI Phase 2 Scheme, focusing on hands-on, interactive learning. Our program includes:

                                        Geography Concepts: Hands-on exploration of geographical principles.
                                        Speed and Motion: Practical projects to understand physics concepts.
                                        History through Robotics: Teaching historical events with robotics.
                                        Projectile Motion: Interactive experiments for better understanding.
                                        With Game Mats, Robotics Kits, and Physical Training, this program prepares students for a future driven by technology and innovation, in line with national education goals.

                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <img className='pm-shri-image' src={LearningEnrichment} alt="bal-vatika"/>
                            </div>
                        </div>
                    </div>

                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <img className='pm-shri-image' src={MathsCircle} alt="maths-circle"/>
                            </div>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>Math's Circle</h3>
                                    <p>The Maths Circle in the PM Shri Scheme is a special program to help students get better at math and enjoy it more. It's like a friendly group where students can do math activities, solve puzzles, and talk about math together. The Maths Circle helps students think critically, solve problems, and be creative while learning different math ideas. By joining the Maths Circle, students become more confident in their math skills and understand math better. The goal of the Maths Circle is to make students good at math and ready for success in school and beyond. Our Maths Circle initiative provides students with opportunities to develop confidence in their mathematical abilities and gain a deeper understanding of mathematical principles. By participating in the Maths Circle, students not only improve their mathematical literacy and proficiency but also prepare themselves for success in academics and beyond.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>Science Circle</h3>
                                    <p>
                                        The Science Circle in the PM Shri Scheme is like a club where students can learn and enjoy science. It's a fun and interactive environment where they can explore different scientific ideas, do experiments, and talk about science with others. The Science Circle helps students think critically, solve problems, and learn by asking questions. By joining the Science Circle, students understand science better, improve their thinking skills, and develop a strong interest in science. The goal of the Science Circle is to make students good at science and ready for success in school and careers in STEM fields.By participating in our Science Circle initiative, students have the opportunity to enhance their critical thinking, problem-solving, and inquiry-based learning skills. Our program covers various scientific disciplines and topics, fostering a deeper understanding of scientific principles and promoting scientific literacy among students.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <img className='pm-shri-image' src={ScienceCircle} alt="science-circle"/>
                            </div>

                        </div>
                    </div>

                    {/*<div className={`tracking_pr_item`}>*/}
                    {/*    <div className={`row align-items-center`}>*/}
                    {/*        <div className="col-lg-6">*/}
                    {/*            <div className="tracking_content wow fadeInDown">*/}
                    {/*                <h3>Linkage Sector</h3>*/}
                    {/*                <p>The Linkage Sector in the PM Shri Scheme acts like a bridge, connecting different groups like government, businesses, schools, and communities. It helps them communicate and work together smoothly. By joining forces and sharing resources, the Linkage Sector helps promote overall development, new ideas, and eco-friendly growth in various areas. Its goal is to create partnerships that solve problems and make life better for everyone under the PM Shri Scheme. Through our strategic networking and coordination efforts, we empower schools to leverage the collective strengths of various sectors and stakeholders to enhance educational outcomes and promote overall development under the PM Shri Scheme.</p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="col-lg-6">*/}
                    {/*            <img className='pm-shri-image' src={LinkageSector} alt="linkage-sector"/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <img className='pm-shri-image' src={Vocational} alt="skill-sector"/>
                            </div>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>Vocational Courses</h3>
                                    <p>Shikshak Solutions, as part of the PM SHRI Phase 2 Scheme, offers a variety of vocational courses aimed at empowering students with cutting-edge skills in emerging fields.</p>
                                    <p>Under the PM SHRI (Pradhan Mantri Schools for Rising India) Schemes, vocational courses are designed to equip students with practical skills and industry-relevant knowledge. These courses aim to enhance employability by offering specialized training in various trades such as Information Technology, Electronics, Healthcare, Agriculture, and more. Aligned with the vision of a skilled India, vocational education under PM SHRI emphasizes hands-on learning, real-world applications, and partnerships with industry leaders. This initiative empowers students with the tools needed for career advancement, entrepreneurship, and contributing to India's growing economy, ensuring a future-ready workforce.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>Vocational Courses-Raw Materials</h3>
                                    <p>
                                        Vocational courses in Raw Materials under the PM SHRI (Pradhan Mantri Schools for Rising India) Schemes are tailored to provide students with essential skills and knowledge related to the sourcing, processing, and utilization of various raw materials. These courses focus on critical industries such as mining, forestry, agriculture, and manufacturing, equipping learners with practical insights into raw material management, quality control, and sustainable practices. By combining theoretical understanding with hands-on training, students gain expertise that enhances their employability in sectors that rely heavily on raw materials. This initiative not only prepares individuals for rewarding careers but also promotes sustainable development and resource management in India, fostering a skilled workforce capable of meeting the demands of a rapidly evolving economy.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <img className='pm-shri-image' src={RawMaterial} alt="internship"/>
                            </div>
                        </div>
                    </div>

                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <img className='pm-shri-image' src={CenturySkills} alt="raw-materials"/>
                            </div>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>21st Century Learning and
                                        Information Skills</h3>
                                    <p>21st Century Learning and Information Skills are essential competencies designed to prepare students for success in a rapidly changing, information-rich world. This educational approach emphasizes critical thinking, creativity, collaboration, and communication—skills necessary for navigating complex problems and fostering innovation.

                                        Central to this framework is the ability to access, evaluate, and utilize information effectively. Students are taught how to discern credible sources, synthesize data, and apply knowledge across various contexts. Additionally, digital literacy is a crucial component, enabling learners to leverage technology responsibly and proficiently for research, communication, and problem-solving.

                                        By integrating these skills into the curriculum, educational programs promote lifelong learning and adaptability, equipping students to thrive in diverse environments, contribute to their communities, and participate fully in a global society. This holistic approach not only enhances academic achievement but also empowers students to become informed, responsible citizens in the 21st century.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>Health Devices</h3>
                                    <p>Under the PM SHRI (Pradhan Mantri Schools for Rising India) Schemes, the incorporation of health devices aims to enhance the quality of education and training in the healthcare sector. This initiative focuses on equipping students with practical skills and knowledge related to the use and maintenance of modern health devices essential for effective patient care and diagnostics.

                                        The program includes training on various health devices, such as diagnostic tools (like ultrasound machines and ECG monitors), wearable health technologies (such as fitness trackers and remote monitoring devices), and telemedicine equipment. By familiarizing students with these technologies, the PM SHRI Schemes prepare them for careers in healthcare, ensuring they are proficient in utilizing advanced medical devices.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <img className='pm-shri-image' src={HealthDevices} alt="toys-library"/>
                            </div>
                        </div>
                    </div>

                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <img className='pm-shri-image' src={Hygiene} alt="toy-accesories"/>
                            </div>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>Hygienic and Sanitation</h3>
                                    <p>Under the PM SHRI (Pradhan Mantri Schools for Rising India) Schemes, the focus on hygiene and sanitation is essential for fostering a safe and healthy learning environment. This initiative aims to educate students about the importance of personal hygiene, sanitation practices, and the role they play in preventing illness and promoting overall well-being.

                                        The program includes training on various aspects of hygiene and sanitation, such as proper handwashing techniques, waste management, clean drinking water practices, and the importance of maintaining cleanliness in school and community settings. By instilling these practices, students develop a strong foundation in health awareness and the responsibilities associated with hygiene.

                                        Furthermore, the PM SHRI Schemes emphasize the importance of sanitation infrastructure, encouraging schools to implement adequate facilities like clean restrooms, waste disposal systems, and safe food handling practices. This holistic approach not only enhances students' understanding of hygiene and sanitation but also empowers them to advocate for healthier practices in their communities.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>Musical Instruments</h3>
                                    <p>Under the PM SHRI (Pradhan Mantri Schools for Rising India) Schemes, the inclusion of musical instruments in the curriculum aims to enrich students' educational experiences and foster their artistic expression. This initiative emphasizes the importance of music education as a means of enhancing creativity, cognitive development, and emotional well-being.

                                        The program provides students with opportunities to learn about various musical instruments, including traditional, classical, and contemporary options. Through hands-on training, students can explore playing techniques, music theory, and the cultural significance of different instruments. This exposure not only nurtures their musical talents but also encourages teamwork and collaboration through group performances and ensembles.

                                        Additionally, the PM SHRI Schemes promote the integration of music into broader educational contexts, allowing students to experience the therapeutic benefits of music and its role in fostering social connections. By cultivating an appreciation for music and developing skills in playing instruments, students gain valuable life skills such as discipline, patience, and self-expression.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <img className='pm-shri-image' src={MusicalInstruments} alt="stem-kit"/>
                            </div>
                        </div>
                    </div>

                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <img className='pm-shri-image' src={BandSet} alt="agriculture"/>
                            </div>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>Band Set</h3>
                                    <p>Under the PM SHRI (Pradhan Mantri Schools for Rising India) Schemes, the inclusion of band sets in school programs aims to provide students with opportunities to explore music through group performances and teamwork. This initiative focuses on enhancing students' musical skills and fostering a sense of discipline, coordination, and collaboration by engaging them in the experience of playing in a band.

                                        The program equips students with a variety of instruments such as drums, brass, woodwinds, and percussion, allowing them to learn different roles within a band set. Through guided instruction, students develop proficiency in playing instruments, understanding music notation, and performing harmoniously as a group. This experience builds their confidence, enhances their listening and coordination skills, and encourages creative expression.

                                        Band sets also provide opportunities for students to participate in school events, parades, and community functions, helping them develop stage presence and teamwork while promoting school spirit. The PM SHRI Schemes also emphasize the cultural and historical significance of band music, allowing students to connect with diverse musical traditions.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>Vigyan Jyoti</h3>
                                    <p><strong>Tinkering Activity: Vigyan Jyoti</strong><br/>
                                        Vigyan Jyoti encourages young minds to explore the world of science and technology through hands-on tinkering activities. Designed to foster curiosity and innovation, participants engage with cutting-edge tools and resources to experiment, build prototypes, and solve real-world problems. The initiative nurtures creativity and critical thinking, empowering students to become future innovators in the fields of science, technology, engineering, and mathematics (STEM).</p>
                                    <p><strong>Science Activity: Vigyan Jyoti</strong><br/>
                                        Vigyan Jyoti aims to ignite a passion for science through engaging and interactive activities that bring theoretical concepts to life. From conducting exciting experiments to exploring scientific phenomena, students are guided through a journey of discovery that deepens their understanding of the natural world. These activities not only enhance scientific knowledge but also inspire students to pursue careers in science, empowering the next generation of scientists and researchers.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <img className='pm-shri-image' src={VigyanJoti} alt="digital-library-vatika"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default withRouter(PMShriPhaseSecond);
