import React from 'react'
import {useNavigate} from 'react-router-dom';

function Cartorder() {
    const navigate = useNavigate();
    const processCheckout=()=>{
        if(localStorage.getItem('user')) {
            navigate("/Checkout");
        }else{
            navigate("/login");
        }
    }
    
  return (
    <div>

        <div className="col-12 mt-0">
            <button type="button" onClick={processCheckout} className="common-btn1">Check Out</button>
        </div>  

    </div>
  )
}

export default Cartorder
