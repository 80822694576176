import React, {Component} from "react";
import RubicCube from "../../video/RubicCubeSolver.mp4";
import withRouter from "../../hooks/withRouter";


class CreatedSolutionsContent extends Component {
    render(){
        return(
            <section className="tracking_product_area sec_pad">
                <div className="container">
                    <div className={`tracking_pr_item`}>
                        <div className={`row align-items-center`}>
                            <div className="col-lg-6">
                                <div className="tracking_content wow fadeInDown">
                                    <h3>"Innovative Kit in Innovative Lab creates Innovative Students" Amaze your eyes and brain with the smartness of one of our kits while it solves the Rubik's cube so smoothly </h3>
                                    <p></p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <video controls className="curriculum-video">
                                    <source src={RubicCube} type="video/mp4" />
                                    Your browser does not support the video.
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default withRouter(CreatedSolutionsContent);
