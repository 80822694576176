import React, {Suspense} from "react";
import './App.css';
import './scss/style.scss';
import 'react-toastify/dist/ReactToastify.css';

import { BrowserRouter, Route,Routes } from "react-router-dom";

/*------ Pages-----*/
import  {Home} from "./Pages/Home";
import Contacts from "./Pages/Contacts";
import CommingSoon from "./Pages/CommingSoon";
import ErrorPage from "./Pages/ErrorPage";
import About from "./Pages/About";
import Curriculum from "./Pages/Curriculum";
import Offering from "./Pages/Offering";
import PMSHRI from "./Pages/Pmshrimain";
import Portfolio from "./Pages/Portfolio";
import CreatedSolutions from "./components/portfolio/CreatedSolutions";
import TermAndCond from "./Pages/TermAndCond";
import PrivacyPolicies from "./Pages/PrivacyPolicies";
import SocialSites from "./Pages/SSSocialSites";
import Products from "./Pages/Products";
import Cards from "./Pages/Cards";
import Blogs from "./Pages/Blogs";
//*import ProductDetails from "./components/product/ProductDetails";
import Cart from "./Pages/Cart";
import Checkout from "./Pages/Checkout";
import CardsDetails from "./Pages/CardsDetails";
import Forgotpassword from "./Pages/Forgotpassword";
import MyOrder from "./components/my order/MyOrder";
import MyAccount from "./components/MyAccount/MyAccount";
import RequireAuth from "./components/auth/RequireAuth";
import SignUp from "./Pages/SignUp";
import Login from "./Pages/Login";
import OrderHistory from "./components/OrderHistory/OrderHistory";
import Billing from "./components/Billing page/Billing";
import Invoice from "./components/Invoice/Invoice";
import EmailTemplet from "./components/EmailTemplet/EmailTemplet";
import useAuth from "./hooks/useAuth";
import AuthService from "./store/services/auth.service";
import {useEffectOnce} from "./hooks/useEffectOnce";
import ChangePassword from "./Pages/ChangePassword";
import {NotificationContainer} from "react-notifications";
import OrderPlaced from "./components/my order/OrderPlaced";
import RefundAndCanc from "./Pages/RefundAndCanc";
import ShippingPolicy from "./Pages/ShippingPolicy";
import Feedback from "./Pages/Feedback";
import PaymentFailure from "./components/my order/PaymentFailure";
import 'react-notifications/lib/notifications.css';
import {useDispatch, useSelector} from "react-redux";
import {actionToGetSEOMetaDataApiCall} from "./store/actions/productAction";
import WriteReview from "./Pages/WriteReview";
import {HelmetComponent} from "./components/layout/HelmetComponent";
import PMShriPhaseTwo from "./Pages/PMShriPhaseTwo";



// import cart from "./Pages/Frontend/cart";
// if you want to show the loader when React loads data again try to change
const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)
/*------ Pages-----*/
const ROLES = {
    'User': 2001,
    'Editor': 1984,
    'Admin': 5150
}
const App = (props)=> {
    const { setAuth } = useAuth();
    const dispatch = useDispatch();
        const authorized = async()=>{
            if(localStorage.getItem('user')){
                const data = JSON.parse(localStorage.getItem('user'));
                if (data.accessToken ) {
                    let user =  await AuthService.parseJwt(data.accessToken);
                    let value  = user.user
                    setAuth({...value});
                }
            }
        }

        useEffectOnce(()=>{
            props.hideLoader();
            authorized();
            dispatch(actionToGetSEOMetaDataApiCall());
        })

        return (
            <BrowserRouter>
                <HelmetComponent />
                <Suspense fallback={loading}>
                    {(localStorage.getItem('user')) ?
                <Routes>
                    <Route exact path="/" element={<Home />}/>
                    <Route exact path="/order-placed/*" element={<OrderPlaced />}/>
                    <Route exact path="/MyOrder" element={<MyOrder />}/>
                    <Route path="/home" element={<Home />}/>
                    <Route path="/contact" element={<Contacts />}/>
                    <Route path="/about" element={<About />}/>
                    <Route path="/curriculum" element={<Curriculum />} />
                    <Route path="/pm-shri-phase-one" element={<PMSHRI />} />
                    <Route path="/pm-shri-phase-two" element={<PMShriPhaseTwo />} />
                    <Route path="/our-work" element={<Portfolio />}/>
                    <Route path="/created-solutions" element={<CreatedSolutions />}/>
                    <Route path="/our-blogs" element={<Blogs />}/>
                    <Route path="/term-and-condition" element={<TermAndCond />}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicies />}/>
                    <Route path="/refund-and-cancellation" element={<RefundAndCanc />}/>
                    <Route path="/shipping-policy" element={<ShippingPolicy />}/>
                    <Route path="/ss-world-page-scan" element={<SocialSites />}/>
                    <Route path="/feedback" element={<Feedback />}/>
                    <Route path="/products" element={<Products />}/>
                    <Route exact path="/Billing" element={<Billing />}/>
                    <Route exact path="/EmailTemplet" element={<EmailTemplet />}/>
                    <Route exact path="/OrderHistory" element={<OrderHistory />}/>
                    <Route exact path="/Invoice" element={<Invoice />}/>
                    <Route exact path="/my-account/*" element={<MyAccount />}/>
                    <Route exact path="/Cart" element={<Cart />}/>
                    <Route exact path="/Checkout" element={<Checkout />}/>
                    <Route exact path="/cart/:id" element={<CardsDetails />}/>
                    <Route path="/Cards" element={<Cards />}/>
                    <Route path="/products/:cat" element={<Products />}/>
                    <Route path="/write-review/*" element={<WriteReview />}/>
                    <Route path="/product/*" element={<CommingSoon />}/>
                    <Route path="/our-offering" element={<Offering />}/>
                    <Route path="/our-offering/:cat" element={<Offering />}/>
                    <Route exact path="/forgot-password" element={<Home />}/>
                    <Route exact path="/reset-password/:token" element={<Home />}/>
                    <Route exact path="/order/success/*" element={<OrderPlaced />}/>
                    <Route exact path="/order/failure/*" element={<PaymentFailure />}/>
                    {/* we want to protect these routes */}
                    <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
                        <Route path="/" element={<Home />} />
                    </Route>

                    {/* catch all */}
                    <Route path="/*" element={<ErrorPage />}/>
                </Routes>:<Routes>
                            <Route exact path="/" element={<Home />}/>
                            <Route exact path="/Signup" element={<SignUp />}/>
                            <Route exact path="/login" element={<Login />}/>
                            <Route exact path="/forgot-password" element={<Forgotpassword />}/>
                            <Route exact path="/reset-password/:token" element={<ChangePassword />}/>
                            <Route path="/pm-shri-phase-one" element={<PMSHRI />} />
                            <Route path="/pm-shri-phase-two" element={<PMShriPhaseTwo />} />
                            <Route path="/home" element={<Home />}/>
                            <Route path="/contact" element={<Contacts />}/>
                            <Route path="/about" element={<About />}/>
                            <Route path="/curriculum" element={<Curriculum />} />
                            <Route path="/pm-shri" element={<PMSHRI />} />
                            <Route path="/our-work" element={<Portfolio />}/>
                            <Route path="/our-blogs" element={<Blogs />}/>
                            <Route path="/term-and-condition" element={<TermAndCond />}/>
                            <Route path="/privacy-policy" element={<PrivacyPolicies />}/>
                            <Route path="/refund-and-cancellation" element={<RefundAndCanc />}/>
                            <Route path="/shipping-policy" element={<ShippingPolicy />}/>
                            <Route path="/ss-world-page-scan" element={<SocialSites />}/>
                            <Route path="/feedback" element={<Feedback />}/>
                            <Route path="/products" element={<Products />}/>
                            <Route path="/product/*" element={<CommingSoon />}/>
                            <Route path="/products/:cat" element={<Products />}/>
                            <Route path="/our-offering" element={<Offering />}/>
                            <Route exact path="/Cart" element={<Cart />}/>
                            <Route path="/our-offering/:cat" element={<Offering />}/>
                            <Route path="/*" element={<ErrorPage />}/>
                        </Routes>}
                    <NotificationContainer />
                </Suspense>
            </BrowserRouter>
        );
}

export default App;
