import React from 'react';

const Shipping =()=>{

    return(
        <section >
            <div  className="container custom_container p0">

                <p>Welcome to Shikshak Solutions!</p>
                
                <p>Shikshak Solutions: Shipping Policy</p>

                <p>At Shikshak Solutions, we are dedicated to providing our customers with the best educational resources and services. While most of our offerings are digital and do not require physical shipping, we want to ensure clarity regarding any physical materials that may be associated with your order.</p>

                <p>Please read our Shipping Policy carefully:</p>

                <h3><strong>1. Digital Products:</strong></h3>

                <p>The majority of our products and services are delivered electronically, which means there is no physical shipping involved.</p>

                <p>Upon successful payment and verification, you will receive access to your digital content through your account on our website or via email.</p>

                <h3><strong>2. Physical Materials:</strong></h3>

                <p>In some cases, we may offer physical materials such as textbooks, workbooks, or other educational resources as part of our educational packages.</p>

                <p>If your order includes physical materials, please note the following:</p>
                
                <h5><strong>2.1 Shipping Times:</strong></h5>

                <p>Shipping times may vary depending on your location and the availability of the physical materials. We strive to process and ship physical orders promptly.</p>

                <p>You will be provided with an estimated delivery time during the checkout process, which may vary based on your chosen shipping method.</p>

                <p>Estimated Delivery time will be 7 to 14 working days based on your location. This estimated time may vary depending on your location and the availability of the physical materials. We'll try to deliver ASAP.  </p>

                <h5><strong>2.2 Shipping Charges:</strong></h5>

                <p>Shipping charges, if applicable, will be displayed during the checkout process. These charges are determined based on your location, the weight of the materials, and the selected shipping method.</p>

                <p>Some orders may qualify for free shipping based on specific promotions or order totals.</p>

                <h5><strong>2.3 Shipping Partners:</strong></h5>

                <p>We partner with reputable courier services and postal carriers to ensure the safe and timely delivery of your physical materials.</p>

                <p>You will receive a tracking number once your order has been shipped, allowing you to monitor the progress of your delivery.</p>

                <h5><strong>2.4 Shipping Address:</strong></h5>

                <p>It is your responsibility to provide accurate and complete shipping information during the checkout process. Please double-check the shipping address to avoid delivery issues.</p>

                <p>We are not responsible for shipping delays or delivery failures due to incorrect or incomplete address information provided by the customer.</p>

                <h5><strong>2.5 International Shipping:</strong></h5>

                <p>Shikshak Solutions may offer international shipping for select products. International shipping rates and delivery times may vary depending on the destination country and local customs regulations.</p>

                <p>Import duties, taxes, and customs fees (if applicable) are the responsibility of the customer and are not included in the product or shipping costs.</p>

                <h3><strong>3. Order Tracking:</strong></h3>

                <p>You can track the status of your order, including estimated delivery dates, by logging into your account on our website and accessing the order history section.</p> 
                
                <p>Alternatively, you can use the provided tracking number to track your shipment on the carrier's website.</p>

                <h3><strong>4. Contact Us:</strong></h3>

                <p>If you have any questions or concerns regarding the shipping of your order, please do not hesitate to contact our customer support team.</p>
                
                <p>We are here to assist you with any shipping-related inquiries.</p>

                <h5>This Shipping Policy is subject to change without prior notice. Please review our policy regularly for any updates.</h5> 
                
                <p>Last updated on : <strong>01-Oct-2023</strong></p>
            </div>
        </section>
    )
}
export default Shipping;
