import {api} from "../../hooks/api/ApiConfig";
import {
    BUDGET_TYPE_DATA_CP,
    CATEGORY_DATA_CP, CLASS_TYPE_DATA_CP,
    PRODUCT_DATA_CP,
    PRODUCT_DETAILS_DATA_CP, SEO_META_DATA, URL_SLUG_DATA, PRODUCT_REVIEW_DATA
} from "../constant/productConstant";
import CryptoJS from "crypto-js";
import AWS from 'aws-sdk';
const AWS_ACCESS_KEY_ID='AKIA5BAMSD7MAS6P43HN';
const AWS_SECRET_ACCESS_KEY='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';
const AWS_DEFAULT_REGION='eu-north-1';
const AWS_BUCKET = 'shikshak-solutions';
const ENCRYPTION_KEY = "XkhZG4fW2t2W";
const S3Bucket = new AWS.S3({
    params: {Bucket: AWS_BUCKET},
    region: AWS_DEFAULT_REGION,
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_SECRET_ACCESS_KEY,
    encryptionKey : ENCRYPTION_KEY,
})

export const actionToUploadDataToS3BucketStore = (payload) => async () => {
    const params = {
        ACL: 'public-read',
        Body: payload.blobData,
        Bucket: AWS_BUCKET,
        Key: payload.key,
        ContentType:payload.type
    };
    S3Bucket.putObject(params)
        .send((err) => {
            console.log(err,'error');
        })
    return `https://shikshak-solutions.s3.eu-north-1.amazonaws.com/${payload.key}`;
}
export const actionToGetProductsApiCall = () => async (dispatch) => {
    let payload = {show_on_website:'shikshak'};
    const {data} = await api.post(`products/actionToGetProductsApiCall`,{payload:CryptoJS.AES.encrypt(JSON.stringify(payload), ENCRYPTION_KEY).toString()});
    let requestDataString = CryptoJS.AES.decrypt(data, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
    let productsData = JSON.parse(requestDataString);
    dispatch({ type: PRODUCT_DATA_CP, payload: {data:productsData,loading:false}});
    return data;
}
export const actionToGetCategoriesApiCall = () => async (dispatch) => {
    const {data} = await api.post(`products/actionToGetCategoriesApiCall`);
    let responseDataString = CryptoJS.AES.decrypt(data, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
    let categoriesData = JSON.parse(responseDataString);
    dispatch({ type: CATEGORY_DATA_CP, payload: categoriesData });
    return data;
}
export const actionToGetBudgetTypeApiCall = () => async (dispatch) => {
    const {data} = await api.post(`products/actionToGetRangeTypeCategoriesApiCall`);
    /* let responseDataString = CryptoJS.AES.decrypt(data, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
     let categoriesData = JSON.parse(responseDataString);*/
    dispatch({ type: BUDGET_TYPE_DATA_CP, payload: data });
    return data;
}
export const actionToGetProductDataApiCall = (payload) => async (dispatch) => {
    const {data} = await api.post(`products/actionToGetProductsApiCall`,payload);
    dispatch({ type: PRODUCT_DETAILS_DATA_CP, payload: data[0] });
    return data;
}
export const actionToGetProductDetailsDataApiCall = (payload) => async (dispatch) => {
    const {data} = await api.post(`products/actionToGetProductsDetailsByIdApiCall`,{payload:CryptoJS.AES.encrypt(JSON.stringify(payload), ENCRYPTION_KEY).toString()});
    let responseDataString = CryptoJS.AES.decrypt(data, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
    let productData = JSON.parse(responseDataString);
    dispatch({ type: PRODUCT_DETAILS_DATA_CP, payload:{data: productData,loading:false}});
    return data;
}
export const actionToGetClassTypeApiCall = () => async (dispatch) => {
    const {data} = await api.post(`products/actionToGetClassTypeCategoriesApiCall`);
    /* let responseDataString = CryptoJS.AES.decrypt(data, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
     let categoriesData = JSON.parse(responseDataString);*/
    dispatch({ type: CLASS_TYPE_DATA_CP, payload: data });
    return data;
}
export const actionToGetSEOMetaDataApiCall = () => async (dispatch) => {
    const {data} = await api.post(`web-setting/actionToGetSEOMetaDataApiCall`);
    let responseDataString = CryptoJS.AES.decrypt(data, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
    let seoMetaData = JSON.parse(responseDataString);
    console.log(seoMetaData,'seoMetaData')
    dispatch({ type: SEO_META_DATA, payload: seoMetaData });
    const response = await api.post(`web-setting/actionToGetURLSlugDataApiCall`);
    let responseData = CryptoJS.AES.decrypt(response?.data, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
    let urlSlugData = JSON.parse(responseData);
    console.log(urlSlugData,'seoMetaData')
    dispatch({ type: URL_SLUG_DATA, payload: urlSlugData });
    return data;
}
export const uploadReviewImages  = (payload) => async  (dispatch)=>{
    let finalImagesArray = [];
    payload?.selectedImages?.forEach(  (selectedImageData,key)=>{
        let filename = `products/review-${key}-${new Date().getTime()}.png`;
        let imagePayload = {
            blobData:selectedImageData,
            key:filename,
            type:'img/png',
        }
        dispatch(actionToUploadDataToS3BucketStore(imagePayload)).then((image)=>{
            finalImagesArray.push(image);
        })

        // finalImages = (finalImages === '') ? image : ', '+image;

    })
    return finalImagesArray;
}

export const actionSaveProductReview = (payload) => async (dispatch) => {
    if(payload.userId !==undefined) {
        await dispatch(uploadReviewImages(payload)).then( async (responseUploadImage)=>{
            payload.finalImagesJson = JSON.stringify(Object.assign({}, responseUploadImage));
            await dispatch(callProductReviewInsertDataFunction(payload));
            return true;
        });
    }
}

export const callProductReviewInsertDataFunction = (payload) => async (dispatch) => {
    console.log(payload);
    const {data} = await api.post(`products/actionAddUpdateProductReviewByUserId`, {payload:CryptoJS.AES.encrypt(JSON.stringify(payload), ENCRYPTION_KEY).toString()});
    if (data?.response?.success) {
        return true;
    } else {
        return false;
    }
}

export const actionGetProductReviewDetails = (payload) => async (dispatch) => {
    if(payload.userId !==undefined) {
        const {data} = await api.post(`products/actionGetProductReviewByUserId`, {payload:CryptoJS.AES.encrypt(JSON.stringify(payload), ENCRYPTION_KEY).toString()});
        if (data?.response.length > 0) {
            dispatch({ type: PRODUCT_REVIEW_DATA, payload: {data:data?.response[0]}});
            return true;
        } else {
            return false;
        }
    }
}