import {useParams} from "react-router-dom";
import CryptoJS from "crypto-js";
import React, {useEffect, useState} from "react";
import moment from "moment";
import {actionGetProductReviewDetails, actionSaveProductReview} from "../../store/actions/productAction";

import {NotificationManager} from "react-notifications";
import {useDispatch, useSelector} from "react-redux";
import AuthService from "../../components/services/auth.service";
import {useEffectOnce} from "../../hooks/useEffectOnce";
import {cloneDeep} from "lodash";
import CustomNavbar from "../../components/layout/CustomNavbar";
const ENCRYPTION_KEY = "XkhZG4fW2t2W";
export default function WriteProductReviewAndRatting() {
    const userInfo =  localStorage.getItem('user');
    let userRawData =   AuthService.parseJwt(JSON.parse(userInfo)?.accessToken);
    const dispatch  = useDispatch();
    let para = useParams();
    let id= Object.values(para)[0];
    let productId = CryptoJS.AES.decrypt(id, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
    const [selectedRating, setSelectedRating] = useState(0);
    const [selectedRatingText, setSelectedRatingText] = useState('');
    const [selectedTitleText, setSelectedTitleText] = useState('');
    const [selectedImages, setSelectedImages] = useState([]);
    const selectedProductReviewData = useSelector((state) => state.productReducer.selectedProductReviewData);

    useEffectOnce(()=>{
        if(localStorage.getItem('user')){
            let payload = {
                'productId': productId,
                'userId': userRawData?.user?.id,
            }
            dispatch(actionGetProductReviewDetails(payload));
        }
    });

    useEffect(()=>{
        if (selectedProductReviewData?.data?.id){
            setSelectedRating(selectedProductReviewData?.data?.rating);
            setSelectedRatingText(selectedProductReviewData?.data?.review_description);
            setSelectedTitleText(selectedProductReviewData?.data?.review_title);
        }
    },[selectedProductReviewData]);

    const selectRating = (rating)=>{
        setSelectedRating(rating);
    }

    const handleRatingTextChange = e => {
        e.preventDefault();
        setSelectedRatingText(e.target.value);
    }

    const handleTitleTextChange = e => {
        e.preventDefault();
        setSelectedTitleText(e.target.value);
    }

    const onFileChange = event => {
        let newImages = cloneDeep(selectedImages);
        newImages.push(event.target.files[0]);
        setSelectedImages(newImages);
    };

    const saveProductRating = () =>{
        if (selectedRating > 0){
            let payload = {
                'productId': productId,
                'userId': userRawData?.user?.id,
                'selectedRating': selectedRating,
                'selectedRatingText': selectedRatingText,
                'selectedTitleText': selectedTitleText,
                'selectedImages': selectedImages,
                'createdAt': moment().format("YYYY-MM-DD HH:mm:ss"),
            }

            dispatch(actionSaveProductReview(payload)).then(async (responseAddToCart)=>{
                if(responseAddToCart){
                    NotificationManager.success("Product Rating Added.");
                }else{
                    NotificationManager.success("Product rating can`t added, please try again later.");

                }
            })
        }
    }

    return (
            <div className="body_wrapper rating-container">
                <div className={"rating-section border-bottom"}>
                    <h5>Rate this product</h5>
                    <div>
                        <ul className={"rating-product"}>
                            <li className={(selectedRating > 0 ? 'active' : '')} onClick={()=>{selectRating(1);}}></li>
                            <li className={(selectedRating > 1 ? 'active' : '')} onClick={()=>{selectRating(2);}}></li>
                            <li className={(selectedRating > 2 ? 'active' : '')} onClick={()=>{selectRating(3);}}></li>
                            <li className={(selectedRating > 3 ? 'active' : '')} onClick={()=>{selectRating(4);}}></li>
                            <li className={(selectedRating > 4 ? 'active' : '')} onClick={()=>{selectRating(5);}}></li>
                        </ul>
                    </div>
                </div>

                <div className={"mt-3"}>
                    <h5>Review this product</h5>
                    <div><textarea className={"form-control"}  placeholder="Description" cols="90" rows="10" value={selectedRatingText} onChange={(e)=>handleRatingTextChange(e)} ></textarea></div>
                </div>

                <div className={"mt-3"}>
                    Title
                    <input className={"form-control"} type="text" value={selectedTitleText} onChange={(e)=>handleTitleTextChange(e)}/>
                </div>

                <div className={"mt-3"}>
                    <input type="file" className={"form-control"} onChange={(e)=>onFileChange(e)} />
                </div>

                <div>
                    {selectedImages.map((selectedImgData, index) => (
                            <img className="playerProfilePic_home_tile"  style={{width:100,heigh:100}} src={URL.createObjectURL(selectedImgData)}></img>
                        )
                    )
                    }

                </div>

                <div>
                    <input type="button" className="theme-btn"  value="Submit" onClick={()=>{saveProductRating();}} />
                </div>
            </div>)

}