import React from 'react';
import intermediate from '../../image/product/intermediate.jpg'

function Checkouttable({userAllCartData,couponDiscountPercentage,couponMaximumDiscountAmount,couponCode,freeShipping,shippingCharge,minimumOrderAmountToFreeShipping,setCalculatedShippingCharge}) {

  //const userAllCartData = useSelector((state) => state.cartReducer.userCartData);

  let totalItem = 0;
  let totalCheckoutAmount = 0.00;
  let totalDiscountAmount = 0.00;
  let totalAmountAfterDiscount =0.00;
  let totalShippingCharge =0.00;

  return (
    <div>
      <table className="table mt-5" border={"0"}>
          <tbody>
          {
            (userAllCartData?.length > 0) ?
            userAllCartData.map((userCartData, index) => {
              totalItem = totalItem + 1;
              totalCheckoutAmount = totalCheckoutAmount + (userCartData?.price)*(userCartData?.qty);
              if(couponDiscountPercentage>0){
                totalDiscountAmount = totalDiscountAmount +(((userCartData?.price)*(userCartData?.qty))*couponDiscountPercentage)/100;
              }
                totalAmountAfterDiscount=parseFloat(totalCheckoutAmount -((couponDiscountPercentage>0) ? (totalDiscountAmount<couponMaximumDiscountAmount)? parseFloat(totalDiscountAmount).toFixed(2):parseFloat(couponMaximumDiscountAmount).toFixed(2):0.00)).toFixed(2)
                totalShippingCharge=(parseInt(freeShipping)===0 && parseFloat(totalAmountAfterDiscount)<parseFloat(minimumOrderAmountToFreeShipping)) ? parseFloat(shippingCharge).toFixed(2):0.00;
                setCalculatedShippingCharge(totalShippingCharge);
              return (
              <tr>
                <td scope="col" colSpan={2}>
                  <div className={"d-inline-flex"}>
                    <img src={userCartData.photo} height={100} width={100} className={"mr-3 p-1"} alt="..."/>
                      <div className="card-body pl-3">
                        <div className={"ml-3"}>{userCartData?.name}</div>
                      </div>
                  </div>
                </td>
                <td scope="col">Quantity {userCartData?.qty}</td>
                <td scope="col">{parseFloat(userCartData?.price).toFixed(2)} * {userCartData?.qty}</td>
                <td scope="col" align={"right"}><span className={"price"}>₹ {parseFloat(userCartData?.price*userCartData?.qty).toFixed(2)}</span></td>
              </tr>
              )
            }) : ''
          }
          </tbody>
        </table>
        <div className={"subtotal-row"}>
            <table border={"0"} width={"50%"} className={"subtotal-table"}>
            <tr>
                <td>Sub total</td>
                <td>
                    <div>{totalItem} items</div>
                    {couponCode}
                </td>
                <td align={"right"}><span className={"subtotal"}>₹ {parseFloat(totalCheckoutAmount).toFixed(2)}</span></td>
            </tr>
            <tr>
                <td>Tax</td>
                <td></td>
                <td align={"right"}><span className={"price"}>₹0.00</span></td>
            </tr>
            <tr className={""}>
                <td>Discount</td>
                <td></td>
                <td align={"right"}><span className={"price"}>₹{(couponDiscountPercentage>0) ? (totalDiscountAmount<couponMaximumDiscountAmount)? parseFloat(totalDiscountAmount).toFixed(2):parseFloat(couponMaximumDiscountAmount).toFixed(2):"0.00"}</span></td>
            </tr>
                <tr className={""}>
                    <td>Shipping Charge</td>
                    <td></td>
                    <td align={"right"}><span className={"price"}>₹{parseFloat(totalShippingCharge).toFixed(2)}</span></td>
                </tr>
            <tr>
                <td>Total</td>
                <td></td>
                <td align={"right"}><span className={"price"}>₹ {parseFloat(parseFloat(totalAmountAfterDiscount)+parseFloat(totalShippingCharge)).toFixed(2)}</span></td>
            </tr>
        </table>
        </div>
    </div>
  )
}

export default Checkouttable
