import React, { useState } from 'react';

function BillingPayments() {
  const [cardNumber, setCardNumber] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [cvv, setCvv] = useState('');

  const handleCardNumberChange = (e) => {
    setCardNumber(e.target.value);
  }

  const handleCardHolderNameChange = (e) => {
    setCardHolderName(e.target.value);
  }

  const handleExpirationDateChange = (e) => {
    setExpirationDate(e.target.value);
  }

  const handleCvvChange = (e) => {
    setCvv(e.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    
    console.log('Card Number:', cardNumber);
    console.log('Card Holder Name:', cardHolderName);
    console.log('Expiration Date:', expirationDate);
    console.log('CVV:', cvv);


    setCardNumber('');
    setCardHolderName('');
    setExpirationDate('');
    setCvv('');
  }

  return (
    <div className="form-group text_box">
      <div className={"d-inline-flex border-bottom pb-3 w-100"}>
        <h5 className='m-0'>Add Payment Method</h5>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={"mt-3 gray-box-light"}>
        <div>
          <input className='form-control'
            type="text"
            placeholder='Card Number'
            id="cardNumber"
            name="cardNumber"
            value={cardNumber}
            onChange={handleCardNumberChange}
            required
          />
        </div>
        <div>
          <label htmlFor="cardHolderName"></label>
          <input className='form-control'
            type="text"
            placeholder='Card Holder Name'
            id="cardHolderName"
            name="cardHolderName"
            value={cardHolderName}
            onChange={handleCardHolderNameChange}
            required
          />
        </div>
        <div>
          <label htmlFor="expirationDate"></label>
          <input className='form-control'
            type="text"
            placeholder='Expiration Date'
            id="expirationDate"
            name="expirationDate"
            value={expirationDate}
            onChange={handleExpirationDateChange}
            required
          />
        </div>
        <div>
          <label htmlFor="cvv"></label>
          <input className='form-control'
            type="text"
            placeholder='CVV'
            id="cvv"
            name="cvv"
            value={cvv}
            onChange={handleCvvChange}
            required
          />
        </div>
        <div>
        </div>
          <button className='common-btn m-0 mt-3' type="submit">Add Payment Method</button>
        </div>
      </form>
    </div>
  );
}

export default BillingPayments;