import React, {useEffect, useState} from 'react';
import PreLoader from "./PreLoader";
import ProductList from "./ProductList";
import ProductFilter from "./ProductFilter";
import {useDispatch, useSelector} from "react-redux";
import { ToastContainer } from 'react-toastify';
import {
    actionToGetBudgetTypeApiCall,
    actionToGetCategoriesApiCall, actionToGetClassTypeApiCall,
    actionToGetProductsApiCall
} from "../../store/actions/productAction";
import {useEffectOnce} from "../../hooks/useEffectOnce";
export default function Product(){
    const [filterCategoryTypes, setFilterCategoryTypes] = useState([]);
    const [filterBudgetType, setFilterBudgetType] = useState([]);
    const [filterClassType, setFilterClassType] = useState([]);
    const [fromClassFilter, setFromClassFilter] = useState('');
    const [toClassFilter, setToClassFilter] = useState('');
    const [searchProductName, setSearchProduct] = useState('');
    const [filterPriceRange, setFilterPriceRange] = useState([]);
    const [listOfProducts, setListOfProducts] = useState([]);
    const [loadingData, setLoadingData] = useState(true);

    const dispatch  = useDispatch();
    let typingTimer;                //timer identifier
    let doneTypingInterval = 500;
    useEffectOnce(()=>{
        dispatch(actionToGetProductsApiCall());
        dispatch(actionToGetCategoriesApiCall());
        dispatch(actionToGetBudgetTypeApiCall());
    })
    const productData = useSelector((state) => state.productReducer.productData)
    const listOfCategory = useSelector((state) => state.productReducer.categoryData)
    const listOfBudgetType = useSelector((state) => state.productReducer.listOfBudgetType)
    const checkPriceRangeFilter= (productData)=>{
         let isProductWithinFilterPriceRange =false;
             filterPriceRange.map((priceRange) => {
              let priceRangeArray = priceRange.split("-")
                if(productData?.price>=parseInt(priceRangeArray[0]) && productData?.price <=parseInt(priceRangeArray[1])){
                     isProductWithinFilterPriceRange =true;
                        }
                    });
         return isProductWithinFilterPriceRange;
     };
    const searchKeyDown = ()=>{
        clearTimeout(typingTimer);
    }
    const searchKeyUp = ()=>{
        clearTimeout(typingTimer);
        typingTimer = setTimeout(searchProductByName, doneTypingInterval);
    }
    const searchProductByName = ()=>{
        // HERE WE ARE INCREASING OFFSET OF LOAD MORE CONTENT
        let searchText = searchProductName.toLowerCase();
        // HERE WE ARE INCREASING OFFSET OF LOAD MORE CONTENT
        if(searchText !== '' ){
            let searchDataList = [];
            productData?.data?.length && productData?.data.map((product) => {
                if(product.name.toLowerCase().indexOf(searchText) !== -1){
                    searchDataList.push(product);
                }
            });
            setListOfProducts([...searchDataList]);
        }
        else{
            setListOfProducts([...productData?.data])
        }
    }

    const checkClassRangeFilter= (productData)=>{
        let isProductWithinFilterClassRange =false;
            if(productData?.min_class>=parseInt(fromClassFilter) && productData?.max_class <=parseInt(toClassFilter)){
                isProductWithinFilterClassRange =true;
            }
        return isProductWithinFilterClassRange;
    };
    const emptySearchInput = ()=>{
        setSearchProduct('');
        setListOfProducts([...productData?.data])
    }
    useEffect(()=>{
        setListOfProducts(productData?.data);
        setLoadingData(productData?.loading);

    },[productData])
        return(
            <section className={"sec_without_breadcrumb "}>
                <div className="container_account container-fluid">
                    <div className={"container"}>
                        <div className={"row"}>
                            <div className={"col-md-12 account-heading-title"}>
                                <h1 className={"mb-0"}>Our Products</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-3">
                    <div className="row">
                        <div className="col-md-2">
                            <ProductFilter listOfCategory={listOfCategory}  listOfBudgetType={listOfBudgetType} filterCategoryTypes={filterCategoryTypes} setFilterCategoryTypes={setFilterCategoryTypes} setFilterBudgetType={setFilterBudgetType} filterBudgetType={filterBudgetType} setFromClassFilter={setFromClassFilter} fromClassFilter={fromClassFilter} setToClassFilter={setToClassFilter} toClassFilter={toClassFilter} filterPriceRange={filterPriceRange} setFilterPriceRange={setFilterPriceRange}/>
                        </div>
                        <div className="col-md-10">
                            <div className={"container-fluid mb-3 pt-3 pb-3 light-background product-search"}>
                                <div className={"row"}>
                                    <div className={"col-md-12"}>
                                        <div className={"d-inline-flex w-100"}><input type={"text"} className={"form-control"} placeholder={"Search Product"}  onKeyUp={()=>searchKeyUp()} onKeyDown={()=>searchKeyDown()} value={searchProductName} onChange={(e)=>setSearchProduct(e.target.value)}/>
                                        <div className={"close-search-btn"}>{(searchProductName.trim().length > 0) ? <i className={"fas fa-times-circle clear-text-search-left clear_text_search_in_list"}
                                                                                                                        onClick={()=>{emptySearchInput();}}></i>: ""}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {(loadingData) ? <PreLoader />:
                                <>
                            {(listOfProducts?.length>0) ?
                            <div className="row product-list">
                                {
                                    listOfProducts.map((productContent, index) => {
                                        if(filterCategoryTypes?.includes(productContent?.categoryId) || filterCategoryTypes?.length ===0) {
                                            if(checkClassRangeFilter(productContent) || fromClassFilter == '' || toClassFilter == '') {
                                                if (checkPriceRangeFilter(productContent) || filterPriceRange?.length === 0) {
                                                    if (filterBudgetType?.includes(productContent?.rangeTypeCategory) || filterBudgetType?.length === 0) {
                                                        return (
                                                            <div className="col-md-3">
                                                                <ProductList key={index} item={productContent}/>
                                                            </div>
                                                        )
                                                    }
                                                }
                                            }
                                        }
                                    })
                                }
                            </div>: <div className={"no-product-found text-center w-100"}><h5> No such as Products found. </h5> </div>}
                                </>
                            }
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </section>
        )
};

