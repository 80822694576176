import React, { useState, useEffect, useRef } from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import AuthService from "../services/auth.service";
import useAuth from '../../hooks/useAuth';
import {actionAddUnAuthCartDataToCartAfterLogin, actionToGetTotalCartCount} from "../../store/actions/shopAction";
import {useDispatch} from "react-redux";
import {useEffectOnce} from "../../hooks/useEffectOnce";
import CryptoJS from "crypto-js";
import {GoogleLogin} from 'react-google-login';
import moment from 'moment';
import axios from "axios";
const ENCRYPTION_KEY = "XkhZG4fW2t2W";
const LoginForm = () => {
    const inputCode1Ref = useRef(null);
    const inputCode2Ref = useRef(null);
    const inputCode3Ref = useRef(null);
    const inputCode4Ref = useRef(null);
    const inputCode5Ref = useRef(null);
    const inputCode6Ref = useRef(null);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [signInError, setSignInError] = useState("");
    const [disableActionButton, setDisableActionButton] = useState(false);
    const [loginWithOtp, setLoginWithOtp] = useState(false);
    const [showOtpFillForm, setShowOtpFillForm] = useState(false);
    const [timer, setTimer] = useState(300); // 5 minutes in seconds
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const navigate = useNavigate();
    const { setAuth } = useAuth();
    const dispatch = useDispatch();
    const [allCodes, setAllCodes] = useState({
        code1: '',
        code2: '',
        code3: '',
        code4: '',
        code5: '',
        code6: ''
    });
    useEffect(() => {
        setOtp(allCodes.code1+allCodes.code2+allCodes.code3+allCodes.code4+allCodes.code5+allCodes.code6);
    }, [allCodes]);
    const otpCodeChangeHandler = e => {
        const inputValue = e.target.value;
        if ((/^\d$/.test(inputValue) || inputValue === '')) {
            setAllCodes({...allCodes, [e.target.name]: e.target.value});
            if (inputValue !== ''){
                if (e.target.name == 'code1'){
                    inputCode2Ref.current.focus();
                }else if (e.target.name == 'code2'){
                    inputCode3Ref.current.focus();
                }else if (e.target.name == 'code3'){
                    inputCode4Ref.current.focus();
                }else if (e.target.name == 'code4'){
                    inputCode5Ref.current.focus();
                }else if (e.target.name == 'code5'){
                    inputCode6Ref.current.focus();
                }
            }
        }
    }


    const otpCodeKeyHandler = e => {
        if (e.key === 'Backspace') {
            if (e.target.name == 'code2' && allCodes.code2 == ''){
                setTimeout(function (){
                    inputCode1Ref.current.focus();
                }, 100);
            }else if (e.target.name == 'code3' && allCodes.code3 == ''){
                setTimeout(function (){
                    inputCode2Ref.current.focus();
                }, 100);
            }else if (e.target.name == 'code4' && allCodes.code4 == ''){
                setTimeout(function (){
                    inputCode3Ref.current.focus();
                }, 100);
            }else if (e.target.name == 'code5' && allCodes.code5 == ''){
                setTimeout(function (){
                    inputCode4Ref.current.focus();
                }, 100);
            }else if (e.target.name == 'code6' && allCodes.code6 == ''){
                setTimeout(function (){
                    inputCode5Ref.current.focus();
                }, 100);
            }
        }
    }

    useEffect(() => {
        let interval;

        if (timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [timer]);

    useEffect(() => {
        if (showOtpFillForm){
            setTimer(300);
        }
    }, [showOtpFillForm]);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;

        const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

        return `${formattedMinutes}:${formattedSeconds}`;
    };

    useEffectOnce(()=>{
        if(localStorage.getItem('user')){
            navigate("/");
        }

    });
    const [formErrors,setFormErrors] = useState({
        email: "",
        password: ""
    });
    const numberRegex = /^\d+$/;
    let googleClientId = `389641395769-j84mn9s1ikhvafjaguc3eln6aiq9o7rj.apps.googleusercontent.com`
  /*  const emailRegex = RegExp(
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );*/
    const handleChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        let formError = { ...formErrors };

        switch (name) {
            case "email":
                setEmail(value);
                formError.email = value.length <1 ? "Please Fill Email or mobile number": "";
                break;
            case "password":
                setPassword(value);
                formError.password = value.length <1 ? "Please Fill Password" :"";
                break;
            default:
                break;
        }
        setFormErrors(formError);
    };
    const formValid = () => {
        let valid = true;
        // validate form errors being empty
        Object.values(formErrors).forEach(val => {
            val.length > 0 && (valid = false);
        });
        let inputArray = {
            email: email,
            password: password
        }
        let errorArray = {
            email: "Please Fill Email",
            password: "Please Fill Password"
        }
        let formError ={...formErrors};
        // validate the form was filled out
        Object.keys(inputArray).map((key) => {
            let val = inputArray[key];
           if(val.trim().length === 0){
               formError[key] = errorArray[key];
           }
        });
        setFormErrors(formError);
        return valid;
    };
    
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    /**
     * This method is used to handel the login with otp changes
     */
    const handleLoginWithOtpOnChange = () => {
        setLoginWithOtp(!loginWithOtp);
        setOtpError("");
    };
    const handelLoginWithOtp = async (e)=>{
        e.preventDefault();
        setOtpError("")
        console.log(otp,"otp")
        if(numberRegex.test(otp) && otp.trim().length===6){
            await AuthService.loginWithOtp(otp,email).then(
              async (data) => {
                  if(data?.status===2 || data?.status===3 || data?.status===4){
                      setOtpError(data?.message);
                  }
                  if(data?.accessToken){
                      let unAuthUserCartData = localStorage.getItem('SS_UN_AUTH_CART_DATA') ? JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('SS_UN_AUTH_CART_DATA'), ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8)) : {};
                      let user =  await AuthService.parseJwt(data.accessToken);
                      if(Object.keys(unAuthUserCartData).length>0){
                          dispatch(actionAddUnAuthCartDataToCartAfterLogin({userId:user?.user?.id}));
                      }else{
                          dispatch(actionToGetTotalCartCount(user?.user?.id));

                      }
                      let value  = user.user
                      setAuth({...value});
                      navigate(from, { replace: true });
                      setDisableActionButton(false);
                  }
                  console.log(data,"data")

              })
        }else{
            setOtpError("Please enter valid OTP for login.")
        }
    }
    const handelResentOtp =async (e) => {
        console.log("Calling")
        e.preventDefault();
        if(email.trim().length>0){
            try {
                await AuthService.sendOtpForLogin(email).then(
                    async (data) => {
                        if(data?.data?.response?.success ===1){
                            setShowOtpFillForm(true);
                            setTimer(300);
                        }else{
                            let formError = { ...formErrors };
                            formError.email =  "Something wnr wrong please tray again.";
                            setFormErrors(formError);
                        }
                    });
            }catch (err) {
                console.log(err);
            }
        }else{
            let formError = { ...formErrors };
            formError.email =  "Please Fill Email or mobile number.";
            setFormErrors(formError);
        }
    }
    const handleLogin = async (e) => {
        e.preventDefault();
        if(!loginWithOtp && formValid()){
            try {
                setDisableActionButton(true);
                await AuthService.login(email, password).then(
                    async (data) => {
                        if (data.accessToken) {
                            let unAuthUserCartData = localStorage.getItem('SS_UN_AUTH_CART_DATA') ? JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('SS_UN_AUTH_CART_DATA'), ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8)) : {};
                            let user =  await AuthService.parseJwt(data.accessToken);
                            if(Object.keys(unAuthUserCartData).length>0){
                                dispatch(actionAddUnAuthCartDataToCartAfterLogin({userId:user?.user?.id}));
                            }else{
                                dispatch(actionToGetTotalCartCount(user?.user?.id));

                            }
                            let value  = user.user
                            setAuth({...value});
                            navigate(from, { replace: true });
                            setDisableActionButton(false);
                        }
                    },
                    (error) => {
                        console.log(error);
                        setSignInError(error?.response?.data?.errors[0]?.msg)
                        setDisableActionButton(false);
                    }
                );
            } catch (err) {
                console.log(err);
                setDisableActionButton(false);
            }
        }else{
            if(email.trim().length>0){
                try {
                    await AuthService.sendOtpForLogin(email).then(
                        async (data) => {
                            if(data?.data?.response?.success ===1){
                                setShowOtpFillForm(true);
                            }else{
                                let formError = { ...formErrors };
                                formError.email =  "Something wnr wrong please tray again.";
                                setFormErrors(formError);
                            }
                        });
                }catch (err) {
                    console.log(err);
                }
            }else{
                let formError = { ...formErrors };
                formError.email =  "Please Fill Email or mobile number.";
                setFormErrors(formError);
            }
        }

    };
    const googleLoginSuccessHandel = async (response) =>{
        console.log(response,"response Success")
    }
    const googleLoginFailureHandel = async (response) =>{
        console.log(response,"response Failure")
    }
    /////////// GETTING HASH FROM GOOGLE SIGNIN AND RESETTING URL TO LOGIN ///////////
    useEffectOnce(()=>{
        const urlHash = location?.hash;
        if(urlHash) {
            const access_token = urlHash.match(/#(?:access_token)=([\S\s]*?)&/)[1];
             if (access_token) {
                 dispatch(actionToSignInWithGoogleAuth({access_token}));
                 window.history.pushState({}, null, window.location.origin + location.pathname);
             }
        }
    },[])
    /////////// GETTING HASH FROM GOOGLE SIGNIN AND RESETING URL TO LOGIN ///////////

    const actionToSignInWithGoogleAuth = (userEncryptedData) => async (dispatch) => {
        if(userEncryptedData?.access_token) {
            await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${userEncryptedData?.access_token}`).then((res) => {
                const payload = res?.data;
                dispatch(actionToSignInWithEmailAddress(payload?.email));
            }).catch((err) => console.log(err));
        }
    }

    const actionToSignInWithEmailAddress = (email) => async () => {
        try {
            await AuthService.googleLogin(email).then(
                async (data) => {
                    if (data.accessToken) {
                        let unAuthUserCartData = localStorage.getItem('SS_UN_AUTH_CART_DATA') ? JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('SS_UN_AUTH_CART_DATA'), ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8)) : {};
                        let user =  await AuthService.parseJwt(data.accessToken);
                        if(Object.keys(unAuthUserCartData).length>0){
                            dispatch(actionAddUnAuthCartDataToCartAfterLogin({userId:user?.user?.id}));
                        }else{
                            dispatch(actionToGetTotalCartCount(user?.user?.id));

                        }
                        let value  = user.user
                        setAuth({...value});
                        navigate(from, { replace: true });
                        setDisableActionButton(false);
                    }
                },
                (error) => {
                    console.log(error);
                    setSignInError(error?.response?.data?.errors[0]?.msg)
                    setDisableActionButton(false);
                }
            );
        } catch (error) {
            console.log('error');
        }
    };


    const signInGoogle = ()=>{
        const redirectUri = `http%3A%2F%2Flocalhost:3000%2Flogin`;
        window.location.href = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?gsiwebsdk=3&client_id=389641395769-j84mn9s1ikhvafjaguc3eln6aiq9o7rj.apps.googleusercontent.com&scope=openid%20profile%20email&redirect_uri=${redirectUri}&prompt=select_account&response_type=token&include_granted_scopes=true&enable_serial_consent=true&service=lso&o2v=2&flowName=GeneralOAuthFlow`;
    }

    return (
 
        <div className="login_info">
            {(showOtpFillForm) ?<>
            <h2 className="heading">Enter OTP</h2>
            <form  className="login-form sign-in-form">
                <div className="form-group text_box otp-code-wrapper">

                    <input className={"form-control opt-code"} onChange={otpCodeChangeHandler} onKeyDown={otpCodeKeyHandler} type="text" ref={inputCode1Ref} maxLength={1} name={"code1"} value={allCodes.code1}/>
                    <input className={"form-control opt-code"} onChange={otpCodeChangeHandler} onKeyDown={otpCodeKeyHandler} type="text" ref={inputCode2Ref} maxLength={1} name={"code2"} value={allCodes.code2}/>
                    <input className={"form-control opt-code"} onChange={otpCodeChangeHandler} onKeyDown={otpCodeKeyHandler} type="text" ref={inputCode3Ref} maxLength={1} name={"code3"} value={allCodes.code3}/>
                    <input className={"form-control opt-code"} onChange={otpCodeChangeHandler} onKeyDown={otpCodeKeyHandler} type="text" ref={inputCode4Ref} maxLength={1} name={"code4"} value={allCodes.code4}/>
                    <input className={"form-control opt-code"} onChange={otpCodeChangeHandler} onKeyDown={otpCodeKeyHandler} type="text" ref={inputCode5Ref} maxLength={1} name={"code5"} value={allCodes.code5}/>
                    <input className={"form-control opt-code"} onChange={otpCodeChangeHandler} onKeyDown={otpCodeKeyHandler} type="text" ref={inputCode6Ref} maxLength={1} name={"code6"} value={allCodes.code6}/>

                    {otpError.length > 0 && (
                        <span className="errorMessage">{otpError}</span>
                    )}
                </div>

                <div>
                    {(timer > 0) ?
                        <p>Resend in: {formatTime(timer)} seconds</p>:
                    <button className={timer > 0 ? 'timer_running' : 'timer_stop'}  onClick={(e)=>handelResentOtp(e)}>
                        Resend OTP
                    </button>}
                </div>


                <div className="d-flex justify-content-start">
                    <button type="button" onClick={handelLoginWithOtp} disabled={disableActionButton} className="signin-btn m-0">Sign in</button>
                </div>
            </form>
            </>
                :
                <>
                <h2 className="heading">Sign In</h2>
                <form onSubmit={handleLogin} className="login-form sign-in-form">
                <div className="form-group text_box">

                <input className={"form-control"} type="text" name={"email"} placeholder="Email address or Mobile number"
                value={email}
                onChange={handleChange}/>
            {formErrors.email.length > 0 && (
                <span className="errorMessage">{formErrors.email}</span>
                )}
                </div>
                <div className="extra mb_20">
                <div className="checkbox remember">
                <label className={"d-inline-flex align-items-center"}>
                <input type="checkbox" className="checkbox" checked={loginWithOtp} onChange={handleLoginWithOtpOnChange} /> <span className={"lead-text"}>Sign in with OTP</span>
                </label>
                </div>
                </div>
            {(!loginWithOtp) ?
                <>
                <div className="form-group text_box password-container">

                <input className={"form-control"} type={showPassword ? "text" : "password"} name={"password"} placeholder="Password"  value={password}
                onChange={handleChange}/>
                <div className="pwrd-container">
            {formErrors.password.length > 0 && (
                <span className="errorMessage">{formErrors.password}</span>
                )}
                <i className={`eye-icon ${showPassword ? "fa fa-eye-slash" : "fa fa-eye"}`}
                onClick={togglePasswordVisibility}></i>
                </div>
                </div>
            {signInError.length > 0 && (
                <span className="errorMessage">{signInError}</span>
                )}
                <div className="extra mb_20">
                <div className="checkbox remember">
                <label className={"d-inline-flex align-items-center"}>
                <input type="checkbox" className="checkbox"/> <span className={"lead-text"}>Keep me Signed in</span>
                </label>
                </div>

                <div className="forgotten-password">
                <Link to={"/forgot-password"}>Forgot Password?</Link>
                </div>
                </div>
                <div className="d-flex justify-content-start">
                <button type="submit" disabled={disableActionButton} className="signin-btn m-0">Sign in</button>
                </div>
                    {/*<div className="d-flex justify-content-start">
                        {<GoogleLogin clientId={googleClientId}
                                     buttonText={"Login With Google"}
                                     cookiePolicy={'single_host_origin'}
                                     isSignedIn={true}
                                     onSuccess={googleLoginSuccessHandel}
                                     onFailure={googleLoginFailureHandel}
                                     />}
                    </div>*/}
                <div className="d-flex justify-content-start">
                    <button onClick={() => signInGoogle()} type="button" className="google-button" tabIndex="5">
                        <span className="google-button_icon" tabIndex="-1"><svg height="20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><defs><path id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"></path></defs><clipPath id="b"><use href="#a" overflow="visible"></use></clipPath><path clipPath="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z"></path><path clipPath="url(#b)" fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"></path><path clipPath="url(#b)" fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z"></path><path clipPath="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z"></path></svg></span>
                        <span className="google-button_text" tabIndex="-1">Google</span>
                    </button>
                </div>
                </>:<div className="d-flex justify-content-start">
                <button type="submit" disabled={disableActionButton} className="signin-btn m-0">Send OTP</button>
                </div>}

                <div className="social_text d-inline-flex mt-3">
                Didn't have an account? &nbsp; <Link to={"/signup"}> Sign Up</Link>
                </div>
                </form>
                </>}
                </div>
    ); 
};

export default LoginForm;
