import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useEffectOnce} from "../../hooks/useEffectOnce";
import {useNavigate} from 'react-router-dom';
import AuthService from "../services/auth.service";
import {
  actionToCheckDeliveryAvailability,
  actionToCreateAndSavePaymentGatewayTransaction, actionToDownloadInvoicePDFByOrderId,
  actionToGetCouponCodeDetailsByCouponCode,
  actionToGetTotalCartCount,
  actionToPlaceOrderAndSaveData,
  actionToSavePaymentData, actionToSendOrderPlacedEmailWithInvoice,
  actionToUpdateOrderIdInCart,
  actionToUpdatePaymentGatewayTransactionAfterPayment,
  createCheckOutOrder, createCheckOutPonePayOrder
} from "../../store/actions/shopAction";
import moment from "moment";
import CryptoJS from "crypto-js";
const ENCRYPTION_KEY = "XkhZG4fW2t2W";
function CheckoutForm({userAllCartData,userSavedAddressesData,setCouponDiscountPercentage,setCouponMaximumDiscountAmount,setCouponCode,couponCode,couponDiscountPercentage,couponMaximumDiscountAmount,allPinCodeData,setFreeShipping,setShippingCharge,setMinimumOrderAmountToFreeShipping,calculatedShippingCharge,allStateList}) {
  const dispatch  = useDispatch();
  const navigate = useNavigate();

  const placeNewOder = useSelector((state) => state.cartReducer.placeNewOder);

  const [addNewAddress, setAddNewAddress] = useState(false);
  const [existingDeliveryAddressIndex, setExistingDeliveryAddressIndex] = useState(0);
  const [existingDeliveryAddressId, setExistingDeliveryAddressId] = useState('');
  const [existingShippingAddressId, setExistingShippingAddressId] = useState('');
  const [shippingAvailable, setShippingAvailable] = useState(false);
  const [codShippingAvailable, setCodShippingAvailable] = useState(false);
  // const [minimumOrderAmountToFreeShipping, setMinimumOrderAmountToFreeShipping] = useState(0);
  const [addCoupon, setAddCoupon] = useState(false);
  const [couponValidationMessage, setCouponValidationMessage] =useState("");
  const [couponInfoMessage, setCouponInfoMessage] =useState("");
  const [couponId, setCouponId] =useState(0);

  const [userId, setUserId] = useState([]);
  const [cartIds, setCartIds] = useState([]);
  const [totalAmount, setTotalAmount] = useState("");
  const [totalItems, setTotalItems] = useState("");
  const [shippingFullName, setShippingFullName] = useState("");
  const [shippingMobileNo, setShippingMobileNo] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [shippingCity, setShippingCity] = useState("");
  const [shippingCountry, setShippingCountry] = useState("India");
  const [shippingState, setShippingState] = useState("");
  const [shippingPinCode, setShippingPinCode] = useState("");
  const [shippingCompanyName, setShippingCompanyName] = useState("");
  const [shippingGstNo, setShippingGstNo] = useState("");
  const [shippingCompanyAddress, setShippingCompanyAddress] = useState("");

  const [gstBenefit, setGstBenefit] = useState(false);
  const [billToDifferentAddress, setBillToDifferentAddress] = useState(false);

  const [billingFullName, setBillingFullName] = useState("");
  const [billingMobileNo, setBillingMobileNo] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [billingCountry, setBillingCountry] = useState("India");
  const [billingState, setBillingState] = useState("");
  const [billingPinCode, setBillingPinCode] = useState("");
 /* const [billingCompanyName, setBillingCompanyName] = useState("");
  const [billingGstNo, setBillingGstNo] = useState("");
  const [billingCompanyAddress, setBillingCompanyAddress] = useState("");*/
  const [shippingFromError, setShippingFromError] = useState({
    shippingFullName: "",
    shippingMobileNo: "",
    shippingAddress: "",
    shippingCity: "",
    shippingCountry: "",
    shippingState: "",
    shippingPinCode: ""/*,
    shippingCompanyName: "",
    shippingGstNo: "",
    shippingCompanyAddress: ""*/
  });
  const [billingFromError, setBillingFromError] = useState({
    billingFullName: "",
    billingMobileNo: "",
    billingAddress: "",
    billingCity: "",
    billingCountry: "",
    billingState: "",
    billingPinCode: ""
    /*billingCompanyName: "",
    billingGstNo: "",
    billingCompanyAddress: ""*/
  });


  useEffect(() => {
    if (userAllCartData?.length > 0){
      let cartIdsArray = [];
      let totalItem = 0;
      let totalCheckoutAmount = 0;
      userAllCartData.map((userCartData, index) => {
        cartIdsArray.push(userCartData?.id);
        totalItem = totalItem + 1;
        totalCheckoutAmount = totalCheckoutAmount + (userCartData?.price)*(userCartData?.qty);
      })

      setCartIds(cartIdsArray);
      setTotalAmount(totalCheckoutAmount);
      setTotalItems(totalItem);
    }

  }, [userAllCartData]);
  useEffect(()=>{
    if(placeNewOder?.data?.orderId){
      navigate("/order-placed/"+CryptoJS.AES.encrypt(
          JSON.stringify(placeNewOder?.data?.orderId),
          ENCRYPTION_KEY
      ).toString());
    }
  },[placeNewOder])
  useEffect(()=>{
    console.log(userSavedAddressesData[0],"allPinCodeData");
    if(userSavedAddressesData?.length>0  && !addNewAddress){
      setExistingDeliveryAddressId(userSavedAddressesData[0]?.billing_address_id);
      setExistingShippingAddressId(userSavedAddressesData[0]?.shipping_address_id);
      setShippingMobileNo(userSavedAddressesData[0]?.shipping_address_phone);
      setShippingFullName(userSavedAddressesData[0]?.shipping_address_full_name);
      checkDeliveryAvailability(userSavedAddressesData[0]?.shipping_address_pin_code);
    }
  },[userSavedAddressesData]);

  const mobileNoRegex = RegExp(
      /^((\+){0,1}91(\s){0,1}(\-){0,1}(\s){0,1}){0,1}[6-9][0-9](\s){0,1}(\-){0,1}(\s){0,1}[1-9]{1}[0-9]{7}$/gm
  );
  const gstNoRegex = RegExp(
      /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/
  );
  useEffectOnce(()=>{
    const userInfo =  localStorage.getItem('user');
    let userRawData =   AuthService.parseJwt(JSON.parse(userInfo)?.accessToken);
    //dispatch(getUserCartDetails(userRawData?.user?.id));
    setUserId(userRawData?.user?.id);
  });

  /**
   * This method is used to generate random string according to required length
   * @param length
   * @returns {string}
   */
  const generateRandomString=(length)=> {
    var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charLength = chars.length;
    var result = '';
    for ( var i = 0; i < length; i++ ) {
      result += chars.charAt(Math.floor(Math.random() * charLength));
    }
    return result;
  }
  const checkDeliveryAvailability = async (pinCode)=>{
    dispatch(actionToCheckDeliveryAvailability({pinCode:pinCode},callFunctionToFinishCheckDeliveryAvailabilityProcess));
  }
  /**
   * This method is used to set validation message after server finished the processing of sign up
   * @param serverStatus
   * @param message
   * @param source
   * @param token
   */
  const callFunctionToFinishCheckDeliveryAvailabilityProcess = (serverStatus,message = '',isFreeShipping='0',shippingCharg=0,minAmountToFreeShipping='0')=>{
    if(serverStatus==='0'){
      setShippingAvailable(false);
      setCodShippingAvailable(false);
      if(addNewAddress){
        let formError = { ...shippingFromError };
        formError.shippingPinCode=' Delivery is not available at this pin code.';
        setShippingFromError(formError);
      }
    }
    if(serverStatus==='11'){
      setShippingAvailable(true);
      setCodShippingAvailable(true);
      if(addNewAddress){
        let formError = { ...shippingFromError };
        formError.shippingPinCode='';
        setShippingFromError(formError);
      }
    }
    if(serverStatus==='10'){
      setShippingAvailable(true);
      setCodShippingAvailable(false);
      if(addNewAddress){
        let formError = { ...shippingFromError };
        formError.shippingPinCode='Cod is not available at this pin code.';
        setShippingFromError(formError);
      }
    }
    setFreeShipping(isFreeShipping);
    setShippingCharge(shippingCharg);
    setMinimumOrderAmountToFreeShipping(minAmountToFreeShipping);
  }
  const cancelApplyCoupon = async()=>{
    setAddCoupon(!addCoupon);
    setCouponValidationMessage("")
    setCouponDiscountPercentage(0);
    setCouponMaximumDiscountAmount(0);
    setCouponInfoMessage("");
    setCouponCode("");
    setCouponId(0);
  }
  const applyCoupon =async()=>{
    if(couponCode !==''){
      setCouponValidationMessage("")
      dispatch(actionToGetCouponCodeDetailsByCouponCode(couponCode)).then((response)=> {
        if(response[0]?.is_active ==="1"){
          setCouponDiscountPercentage(response[0]?.discount_percentage);
          setCouponMaximumDiscountAmount(response[0]?.maximum_discount);
          setCouponInfoMessage(response[0]?.coupon_info_message);
          setCouponId(response[0]?.id);
        }else{
          setCouponValidationMessage("Invalid Coupon Code.")
          setCouponDiscountPercentage(0);
          setCouponMaximumDiscountAmount(0);
          setCouponInfoMessage("");
          setCouponId(0);
        }
      });
    }
  }

  const handleShippingFormChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    let formError = { ...shippingFromError };

    switch (name) {
      case "fullName":
        setShippingFullName(value);
        formError.shippingFullName = value.length <1 ? "Please enter full name." :  "";
        break;
      case "mobileNo":
        if (/^[0-9]*$/.test(value) && (value>0 || value ==="")) {
          setShippingMobileNo(value);
          formError.shippingMobileNo = value.length < 1 ? "Please enter mobile no." : mobileNoRegex.test(value)
              ? ""
              : "Please enter valid mobile no.";
        }
        break;
      case "address":
        setShippingAddress(value);
        formError.shippingAddress = value.length <1 ? "Please enter shipping address." :"";
        break;
      case "city":
        setShippingCity(value);
        formError.shippingCity = value.length <1 ? "Please enter shipping city." :"";
        break;
      case "country":
        setShippingCountry(value);
        formError.shippingCountry = value.length <1 ? "Please select shipping country." :"";
        break;
      case "state":
        setShippingState(value);
        formError.shippingState = value.length <1 ? "Please select shipping state." :"";
        break;
      case "pinCode":
        if (/^[0-9]*$/.test(value) && (value>0 || value ==="")) {
          setShippingPinCode(value);
          formError.shippingPinCode = value.length <1 ? "Please enter shipping pin code." :(value.length!==6)?"Please enter valid shipping pin code.":checkDeliveryAvailability(value);
        }
        break;
      case "companyName":
        setShippingCompanyName(value);
        /*formError.shippingCompanyName = value.length <1 ? "Please enter shipping company name." :"";*/
        break;
      case "gstNo":
        setShippingGstNo(value);
        /*formError.shippingGstNo = value.length <1 ? "Please enter shipping GST no." :gstNoRegex.test(value)
            ? ""
            : "Please enter valid GST no.";*/
        break;
      case "companyAddress":
        setShippingCompanyAddress(value);
        /*formError.shippingCompanyAddress = value.length <1 ? "Please enter shipping company address." :"";*/
        break;
      default:
        break;
    }
    setShippingFromError(formError);
  };
  const shippingFormValid = (e) => {
    e.preventDefault();
    let valid = true;
    // validate form errors being empty
    Object.values(shippingFromError).forEach(val => {
      val.length > 0 && (valid = false);
    });
    let inputArray = {
      shippingFullName: shippingFullName,
      shippingMobileNo: shippingMobileNo,
      shippingAddress: shippingAddress,
      shippingCity: shippingCity,
      shippingCountry: shippingCountry,
      shippingState: shippingState,
      shippingPinCode: shippingPinCode,
      shippingCompanyName: shippingCompanyName,
      shippingGstNo: shippingGstNo,
      shippingCompanyAddress:shippingCompanyAddress
    }
    let errorArray = {
      shippingFullName: "Please enter full name.",
      shippingMobileNo: "Please enter mobile no.",
      shippingAddress: "Please enter shipping address.",
      shippingCity: "Please enter shipping city.",
      shippingCountry: "Please select shipping country.",
      shippingState: "Please select shipping state.",
      shippingPinCode: "Please enter shipping pin code."/*,
      shippingCompanyName: "Please enter shipping company name.",
      shippingGstNo: "Please enter shipping GST no.",
      shippingCompanyAddress: "Please enter shipping company address."*/
    }
    let formError ={...shippingFromError};
    // validate the form was filled out
    Object.keys(inputArray).map((key) => {
      let val = inputArray[key];
      if(val.trim().length === 0 && errorArray[key]){
        formError[key] = errorArray[key];
        valid = false;
      }else{
        formError[key] = "";
      }
    });
    setShippingFromError(formError);
    return valid;
  };

  const handleBillingFormChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    let formError = { ...billingFromError };

    switch (name) {
      case "fullName":
        setBillingFullName(value);
        formError.billingFullName = value.length <1 ? "Please enter full name." :  "";
        break;
      case "mobileNo":
        if (/^[0-9]*$/.test(value) && (value>0 || value ==="")) {
          setBillingMobileNo(value);
          formError.billingMobileNo = value.length < 1 ? "Please enter mobile no." : mobileNoRegex.test(value)
              ? ""
              : "Please enter valid mobile no.";
        }
        break;
      case "address":
        setBillingAddress(value);
        formError.billingAddress = value.length <1 ? "Please enter billing address." :"";
        break;
      case "city":
        setBillingCity(value);
        formError.billingCity = value.length <1 ? "Please enter billing city." :"";
        break;
      case "country":
        setBillingCountry(value);
        formError.billingCountry = value.length <1 ? "Please select billing country." :"";
        break;
      case "state":
        setBillingState(value);
        formError.billingState = value.length <1 ? "Please select billing state." :"";
        break;
      case "pinCode":
        if (/^[0-9]*$/.test(value) && (value>0 || value ==="")) {
          setBillingPinCode(value);
          formError.billingPinCode = value.length <1 ? "Please enter billing pin code." :(value.length!==6)?"Please enter valid billing pin code":"";
        }
        break;
      /*case "companyName":
        setBillingCompanyName(value);
        formError.billingCompanyName = value.length <1 ? "Please enter billing company name." :"";
        break;
      case "gstNo":
        setBillingGstNo(value);
        formError.billingGstNo = value.length <1 ? "Please enter billing GST no." :gstNoRegex.test(value)
            ? ""
            : "Please enter valid GST no.";
        break;
      case "companyAddress":
        setBillingCompanyAddress(value);
        formError.billingCompanyAddress = value.length <1 ? "Please enter billing company address." :"";
        break;*/
      default:
        break;
    }
    setBillingFromError(formError);
  };
  const billingFormValid = () => {
    let valid = true;
    // validate form errors being empty
    Object.values(billingFromError).forEach(val => {
      val.length > 0 && (valid = false);
    });
    let inputArray = {
      billingFullName: billingFullName,
      billingMobileNo: billingMobileNo,
      billingAddress: billingAddress,
      billingCity: billingCity,
      billingCountry: billingCountry,
      billingState: billingState,
      billingPinCode: billingPinCode
      /*billingCompanyName: billingCompanyName,
      billingGstNo: billingGstNo,
      billingCompanyAddress:billingCompanyAddress*/
    }
    let errorArray = {
      billingFullName: "Please enter full name.",
      billingMobileNo: "Please enter mobile no.",
      billingAddress: "Please enter billing address.",
      billingCity: "Please enter billing city.",
      billingCountry: "Please select billing country.",
      billingState: "Please select billing state.",
      billingPinCode: "Please enter billing pin code."
      /*billingCompanyName: "Please enter billing company name.",
      billingGstNo: "Please enter billing GST no.",
      billingCompanyAddress: "Please enter billing company address."*/
    }
    let formError ={...billingFromError};
    // validate the form was filled out
    Object.keys(inputArray).map((key) => {
      let val = inputArray[key];
      if(val.trim().length === 0){
        formError[key] = errorArray[key];
        valid = false;
      }else{
        formError[key] = "";
      }
    });
    setBillingFromError(formError);
    return valid;
  };

  const proceedPayment = async(e,paymentMethod)=>{
    let bothFormValid =false;
    if(addNewAddress===true){
      if(shippingFormValid(e)){
        if(billToDifferentAddress){
          if(billingFormValid()){
            bothFormValid=true;
          }
        }else{
          bothFormValid=true;
        }
      }
    }else{
      bothFormValid=true;
    }
  if(bothFormValid){
   let discountAmount =0.00;
   let finalAmount = totalAmount;
    if(couponDiscountPercentage>0){
      if((totalAmount*couponDiscountPercentage)/100>couponMaximumDiscountAmount){
        discountAmount = couponMaximumDiscountAmount;
      }else {
        discountAmount = parseFloat((totalAmount*couponDiscountPercentage)/100).toFixed(2);
      }
      finalAmount = finalAmount-discountAmount;
    }
    if(parseFloat(calculatedShippingCharge)>0){
      finalAmount = parseFloat(parseFloat(finalAmount)+parseFloat(calculatedShippingCharge)).toFixed(2);
    }
    let nowDateTime = moment().format("YYYY-MM-DD HH:mm:ss");
    let payload = {userId:userId,shippingFullName:shippingFullName,shippingMobileNo: shippingMobileNo, shippingAddress: shippingAddress, shippingCity: shippingCity,shippingCountry:shippingCountry, shippingState: shippingState,
      shippingPinCode:shippingPinCode,gstBenefit:(gstBenefit===true)? '1':'0', shippingCompanyName:shippingCompanyName, shippingGstNo:shippingGstNo, shippingCompanyAddress:shippingCompanyAddress, billToDifferentAddress:billToDifferentAddress, billingFullName:billingFullName,
      billingMobileNo:billingMobileNo, billingAddress:billingAddress, billingCity:billingCity, billingCountry:billingCountry, billingState: billingState, billingPinCode: billingPinCode,
      cartIds:cartIds, totalAmount:finalAmount,'dateTime': nowDateTime,addNewAddress:addNewAddress,existingDeliveryAddressId:existingDeliveryAddressId,existingShippingAddressId:existingShippingAddressId,discountAmount:discountAmount,couponId:couponId,paymentMethod:paymentMethod,calculatedShippingCharge:calculatedShippingCharge};

    if(paymentMethod==='offline'){
        await dispatch(actionToPlaceOrderAndSaveData(payload)).then(async (responseData) => {
          payload.db_order_id= responseData.id;
          // Saving payment gateway data and save payment gateway transaction log
          await dispatch(actionToCreateAndSavePaymentGatewayTransaction(payload)).then(async (responsePaymentGatewayTransactionSave) => {
            const aliasArray = ['?', '?', '?', '?', '?', '?', '?'];
            const columnArray = ['order_id', 'direct_payment_transaction_id','shipping_charge', 'net_amount', 'discount_amount', 'discount_coupon_id', 'createdAt'];
            const valuesArray = [payload.db_order_id, responsePaymentGatewayTransactionSave?.id,calculatedShippingCharge, finalAmount, discountAmount, couponId, nowDateTime];
            const insertData = {
              alias: aliasArray,
              column: columnArray,
              values: valuesArray,
              tableName: 'payments'
            };
            await dispatch(actionToSavePaymentData(insertData)).then(async () => {
              dispatch(actionToUpdateOrderIdInCart({
                cartIds: cartIds,
                orderId: responseData.id
              })).then(async () => {
                dispatch(actionToGetTotalCartCount(userId));
              });
            });
          });
        });
    }else if(paymentMethod==='phonepay'){
      payload.transactionId = 'sspay'+generateRandomString(12);
      await dispatch(createCheckOutPonePayOrder(payload)).then(async (responseCreateOrder) => {
        if(responseCreateOrder?.success){
          payload.payment_getway_transaction_id = responseCreateOrder?.data?.data?.merchantTransactionId;
          // Saving Address data and order data in order table
          await dispatch(actionToPlaceOrderAndSaveData(payload)).then(async (responseData) => {
            responseCreateOrder.db_order_id = responseData?.id;
            responseCreateOrder.paymentMethod = paymentMethod;
            responseCreateOrder.payment_gateway_id = 2;
            responseCreateOrder.id = payload.payment_getway_transaction_id;
            // Saving payment gateway data and save payment gateway transaction log
            await dispatch(actionToCreateAndSavePaymentGatewayTransaction(responseCreateOrder)).then(async (responsePaymentGatewayTransactionSave) => {
              if (responsePaymentGatewayTransactionSave?.id) {
                const aliasArray = ['?', '?', '?', '?', '?', '?','?'];
                const columnArray = ['order_id', 'payment_gateway_transaction_id','shipping_charge', 'net_amount', 'discount_amount', 'discount_coupon_id', 'createdAt'];
                const valuesArray = [responseCreateOrder.db_order_id, responsePaymentGatewayTransactionSave?.id,calculatedShippingCharge, finalAmount, discountAmount, couponId, nowDateTime];
                const insertData = {
                  alias: aliasArray,
                  column: columnArray,
                  values: valuesArray,
                  tableName: 'payments'
                };
                await dispatch(actionToSavePaymentData(insertData)).then(async () => {
                  window.location.href=responseCreateOrder.url;
                });
              }
            });
          });

        }else{
        }
      });
    }
    else {
      // return false;
      // Creating order at payment gateway
      await dispatch(createCheckOutOrder(payload)).then(async (responseCreateOrder) => {
        if (responseCreateOrder?.success) {
          let order = responseCreateOrder?.order;
          payload.payment_getway_transaction_id = order?.id;
          // Saving Address data and order data in order table
          await dispatch(actionToPlaceOrderAndSaveData(payload)).then(async (responseData) => {
            order.db_order_id = responseData?.id;
            order.paymentMethod = paymentMethod;
            responseCreateOrder.payment_gateway_id = 1;
            order.payment_gateway_id = 1;
            // Saving payment gateway data and save payment gateway transaction log
            await dispatch(actionToCreateAndSavePaymentGatewayTransaction(order)).then(async (responsePaymentGatewayTransactionSave) => {
              if (responsePaymentGatewayTransactionSave?.id) {
                const options = {
                  key: process.env.RAZORPAY_APT_SECRET,
                  amount: order?.amount,
                  currency: "INR",
                  name: "Shikshak Solutions",
                  description: "Tutorial of RazorPay",
                  image: "https://shikshaksolutions.com/static/media/logo2.2d5fd5effc6e228891809bb238e64e17.svg",
                  order_id: order?.id,
                  handler: async function (response) {
                    response.payment_gateway_transaction_log_id = responsePaymentGatewayTransactionSave?.id;
                    const aliasArray = ['?', '?', '?', '?', '?', '?','?','?'];
                    const columnArray = ['order_id', 'payment_gateway_transaction_id','shipping_charge', 'net_amount', 'discount_amount', 'discount_coupon_id','payment_status', 'createdAt'];
                    const valuesArray = [order.db_order_id, responsePaymentGatewayTransactionSave?.id, calculatedShippingCharge,finalAmount, discountAmount, couponId,'1', nowDateTime];
                    const insertData = {
                      alias: aliasArray,
                      column: columnArray,
                      values: valuesArray,
                      tableName: 'payments'
                    };
                    await dispatch(actionToSavePaymentData(insertData)).then(async () => {

                      // Update payment gateway transaction log
                      dispatch(actionToUpdatePaymentGatewayTransactionAfterPayment(response));
                      // Update order id in cart
                      dispatch(actionToUpdateOrderIdInCart({
                        cartIds: cartIds,
                        orderId: order.db_order_id
                      })).then(async () => {

                        // Updating cart count
                        dispatch(actionToGetTotalCartCount(userId));
                      });
                    });
                  },
                  prefill: {},
                  notes: {},
                  theme: {
                    "color": "#1a1579"
                  }
                };
                const razor = new window.Razorpay(options);
                razor.open();
              }
            });
          });

        }
      });

    }

  }




  }
  const handelAddressOptionChange = (e) => {
    setExistingDeliveryAddressIndex(parseInt(e.target.value));
    setExistingDeliveryAddressId(userSavedAddressesData[parseInt(e.target.value)]?.billing_address_id);
    setExistingShippingAddressId(userSavedAddressesData[parseInt(e.target.value)]?.shipping_address_id);
    setAddNewAddress(false);
    checkDeliveryAvailability(userSavedAddressesData[parseInt(e.target.value)]?.shipping_address_pin_code);
  };
  const handelAddNewAddressOptionChange = () => {
    setAddNewAddress(!addNewAddress);
    setExistingDeliveryAddressIndex("");
    setExistingDeliveryAddressId('');
    setExistingShippingAddressId('');
    setShippingAvailable(false);
    setCodShippingAvailable(false);
  };
  /**
   * This method is used to handel the different billing address checkkbox changes
   */
  const handleOnChange = () => {
    setBillToDifferentAddress(!billToDifferentAddress);
  };
  /**
   * This method is used to handel the different billing address checkkbox changes
   */
  const handleGstBenefitOnChange = () => {
    setGstBenefit(!gstBenefit);
  };

  return (
    <div>

      <section className='mt-5'>
        <div className={"myprofile p-3"}>
        <h5 className='mb-3 border-bottom pb-2'>Delivery Address </h5>
        {(userSavedAddressesData?.length)?
            <>
        {userSavedAddressesData?.map((address,index)=>(
        <div key={index}>
          <div className="form-label d-flex white-bg p-2">
            <input type="radio" className="form-radio-input" name="address" value={index} onChange={(e)=>handelAddressOptionChange(e)}  checked={ existingDeliveryAddressIndex=== index}/>
                <div id="address" className={"address-section"}>
                    <span>{address?.shipping_address_full_name} &nbsp;</span>,
                    <span>{address?.shipping_address_phone}</span><br />
                    <span>
                      {address?.shipping_address_address}, &nbsp;<br />
                      {address?.shipping_address_city}, &nbsp;<br />
                      {address?.shipping_address_state},<br />
                      {address?.shipping_address_pin_code} &nbsp;
                      {address?.shipping_address_country}
                    </span>

                </div>
            </div>
          {(existingDeliveryAddressIndex=== index && !shippingAvailable) ? <span className={"errorMessage has-error"}>Delivery is not available at this pin code.</span>:''}
          {(existingDeliveryAddressIndex=== index && (!codShippingAvailable && shippingAvailable)) ? <span className={"errorMessage has-error"}>Cod is not available at this pin code.</span>:''}
        </div>
        ))}
        </>:""}
        </div>

        <div className={"d-inline-flex w-100 align-item-center delivery-address-section"}>
          <input type="radio" className={"form-check-input mr-2"} name="addNewAddress" onChange={handelAddNewAddressOptionChange}  checked={addNewAddress}/>
          &nbsp; &nbsp; <h5 className={"ml-2 mb-0"}>Add New Address</h5>
        </div>
      </section>

      {(addNewAddress)?
    <>
        <section className='mt-3 delivery-address-section'>
        <h5 className='mb-3 border-bottom pb-2'>Shipping Address</h5>
      <form className="row g-3 mb-4">
        <div className="col-md-6">
          <label for="inputEmail4" className="form-label">Full Name</label>
            <input type="text" className="form-control" name={"fullName"} id="inputEmail4" value={shippingFullName} onChange={handleShippingFormChange} placeholder='Full Name'/>
            {shippingFromError?.shippingFullName?.length > 0 && (
                <span className="errorMessage">{shippingFromError?.shippingFullName}</span>
            )}
        </div>
        <div className="col-md-6">
          <label for="inputPassword4" className="form-label">Mobile Number</label>
          <input type="text" className="form-control" name={"mobileNo"} id="inputPassword4" value={shippingMobileNo} onChange={handleShippingFormChange} placeholder='mobile Number'/>
          {shippingFromError?.shippingMobileNo?.length > 0 && (
              <span className="errorMessage">{shippingFromError?.shippingMobileNo}</span>
          )}
        </div>
        <div className="col-12">
          <label for="inputAddress" className="form-label">Address</label>
          <textarea className="form-control" id="inputAddress" name={"address"} value={shippingAddress} onChange={handleShippingFormChange} placeholder="Address"/>
          {shippingFromError?.shippingAddress?.length > 0 && (
              <span className="errorMessage">{shippingFromError?.shippingAddress}</span>
          )}
        </div>
        <div className="col-md-12">
          <label for="inputCity" className="form-label">City</label>
          <input type="text" className="form-control" id="inputCity" name={"city"} value={shippingCity} onChange={handleShippingFormChange} placeholder='City'/>
          {shippingFromError?.shippingCity?.length > 0 && (
              <span className="errorMessage">{shippingFromError?.shippingCity}</span>
          )}
        </div>
      <div className="col-md-12">
        <label for="inputCity" className="form-label">Country</label>
        <select id="inputCountry" name={"country"}  onChange={handleShippingFormChange} className="form-select">
          <option value={"India"} selected>India</option>
        </select>
        {shippingFromError?.shippingCountry?.length > 0 && (
            <span className="errorMessage">{shippingFromError?.shippingCountry}</span>
        )}
      </div>
        <div className="col-md-6 ">
          <label for="inputState" className="form-label">State</label>
          <select id="inputState" name={"state"} onChange={handleShippingFormChange} className="form-select">
            <option selected value="">Choose...</option>
            {allStateList?.length>0 && allStateList?.map((stateData)=>{
                return (<option key={"key_"+stateData.id} value={stateData.state_name}>
                  {stateData?.state_name}
                </option>);
            })}
          </select>
          {shippingFromError?.shippingState?.length > 0 && (
              <span className="errorMessage">{shippingFromError?.shippingState}</span>
          )}
        </div>
        <div className="col-md-6 mb-3">
          <label for="inputZip" className="form-label">Pincode</label>
          <input type="text" className="form-control" name={"pinCode"} id="inputZip" value={shippingPinCode} onChange={handleShippingFormChange} placeholder='Pincode'/>
          {shippingFromError?.shippingPinCode?.length > 0 && (
              <span className="errorMessage">{shippingFromError?.shippingPinCode}</span>
          )}
        </div>
        <div className="mb-1 form-check">
          <input type="checkbox" checked={gstBenefit} onChange={handleGstBenefitOnChange} className="form-check-input"
                 id="exampleCheck2"/>
          <label className="form-check-label" htmlFor="exampleCheck2">Do you want GST Benefit</label>
        </div>
        {(gstBenefit===true)?
            <>
          <div className="col-md-12">
            <label for="inputCity" className="form-label">Company name</label>
            <input type="text" className="form-control" name={"companyName"} id="inputCity" value={shippingCompanyName} onChange={handleShippingFormChange} placeholder='Company name'/>
            {shippingFromError?.shippingCompanyName?.length > 0 && (
                <span className="errorMessage">{shippingFromError?.shippingCompanyName}</span>
            )}
          </div>
          <div className="col-md-12">
            <label for="inputCity" className="form-label">GST no.</label>
            <input type="text" className="form-control" name={"gstNo"} value={shippingGstNo} onChange={handleShippingFormChange} id="inputCity" placeholder='Gst no.'/>
            {shippingFromError?.shippingGstNo?.length > 0 && (
                <span className="errorMessage">{shippingFromError?.shippingGstNo}</span>
            )}
          </div>
        <div className="col-md-12">
          <label for="inputCity" className="form-label">Company address</label>
          <textarea className="form-control" id="inputCity" name={"companyAddress"} value={shippingCompanyAddress} onChange={handleShippingFormChange} placeholder='Company address'/>
          {shippingFromError?.shippingCompanyAddress?.length > 0 && (
              <span className="errorMessage">{shippingFromError?.shippingCompanyAddress}</span>
          )}
        </div></>:""}
      <div className="mb-1 form-check">
        <input type="checkbox" checked={billToDifferentAddress} onChange={handleOnChange}  className="form-check-input" id="exampleCheck1"/>
        <label className="form-check-label" for="exampleCheck1">Bill to a different address</label>
      </div>

</form>
</section>
<section className={"mt-3 delivery-address-section"}>
  {(billToDifferentAddress) ?
      <>
        <h5 className='mb-3 border-bottom pb-2' >Billing Address</h5>
      <form className="row g-3">
        <div className="col-md-6">
          <label for="inputEmail4" className="form-label">Full Name</label>
          <input type="text" className="form-control"  name={"fullName"} id="billingFullName" value={billingFullName} onChange={handleBillingFormChange} placeholder='Full Name'/>
          {billingFromError?.billingFullName?.length > 0 && (
              <span className="errorMessage">{billingFromError?.billingFullName}</span>
          )}
        </div>
  <div className="col-md-6">
    <label for="inputPassword4" className="form-label">Mobile Number</label>
    <input type="text" className="form-control" name={"mobileNo"} id="billingMobileNo" value={billingMobileNo} onChange={handleBillingFormChange} placeholder='mobile Number'/>
    {billingFromError?.billingMobileNo?.length > 0 && (
        <span className="errorMessage">{billingFromError?.billingMobileNo}</span>
    )}
  </div>
  <div className="col-12">
    <label for="inputAddress" className="form-label">Address</label>
    <textarea className="form-control" id="inputBillingAddress" name={"address"} value={billingAddress} onChange={handleBillingFormChange} placeholder="Address"/>
    {billingFromError?.billingAddress?.length > 0 && (
        <span className="errorMessage">{billingFromError?.billingAddress}</span>
    )}
  </div>
  
  <div className="col-md-12">
    <label for="inputCity" className="form-label">City</label>
    <input type="text" className="form-control" id="inputBillingCity"  name={"city"} value={billingCity} onChange={handleBillingFormChange} placeholder='City'/>
    {billingFromError?.billingCity?.length > 0 && (
        <span className="errorMessage">{billingFromError?.billingCity}</span>
    )}
  </div>
  <div className="col-md-12">
    <label for="inputCity" className="form-label">Country</label>
    
    <select id="inputCountry" name={"country"} onChange={handleBillingFormChange} className="form-select">
      <option value={"India"} selected>India</option>
    </select>
    {billingFromError?.billingCountry?.length > 0 && (
        <span className="errorMessage">{billingFromError?.billingCountry}</span>
    )}
  </div>
  <div className="col-md-6 ">
    <label for="inputState"  className="form-label">State</label>
    <select id="inputBillingState" name={"state"} onChange={handleBillingFormChange} className="form-select">
      <option selected value="">Choose...</option>
      {allStateList?.length>0 && allStateList?.map((stateData)=>{
        return (<option key={"keyd_"+stateData.id} value={stateData.state_name}>
          {stateData?.state_name}
        </option>);
      })}
    </select>
    {billingFromError?.billingState?.length > 0 && (
        <span className="errorMessage">{billingFromError?.billingState}</span>
    )}
  </div>
  <div className="col-md-6 mb-3">
    <label for="inputZip" className="form-label">Pincode</label>
    <input type="text" className="form-control" name={"pinCode"} id="inputBillingZip" value={billingPinCode} onChange={handleBillingFormChange} placeholder='Pincode'/>
    {billingFromError?.billingPinCode?.length > 0 && (
        <span className="errorMessage">{billingFromError?.billingPinCode}</span>
    )}
  </div>
  {/*<div className="col-md-12">*/}
  {/*  <label for="inputCity" className="form-label">Company name</label>*/}
  {/*  <input type="text" className="form-control" name={"companyName"}  id="inputBillingCity" value={billingCompanyName} onChange={handleBillingFormChange} placeholder='Company name'/>*/}
  {/*  {billingFromError?.billingCompanyName?.length > 0 && (*/}
  {/*      <span className="errorMessage">{billingFromError?.billingCompanyName}</span>*/}
  {/*  )}*/}
  {/*</div>*/}
  {/*<div className="col-md-12">*/}
  {/*  <label for="inputCity" className="form-label">Gst no.</label>*/}
  {/*  <input type="text" className="form-control" name={"gstNo"} id="inputBillingGst" value={billingGstNo} onChange={handleBillingFormChange} placeholder='Gst no.'/>*/}
  {/*  {billingFromError?.billingGstNo?.length > 0 && (*/}
  {/*      <span className="errorMessage">{billingFromError?.billingGstNo}</span>*/}
  {/*  )}*/}
  {/*</div>*/}
  {/*<div className="col-md-12">*/}
  {/*  <label for="inputCity" className="form-label">Company address</label>*/}
  {/*  <textarea className="form-control" id="inputBillingCompanyAddress" name={"companyAddress"} value={billingCompanyAddress} onChange={handleBillingFormChange} placeholder='Company address'/>*/}
  {/*  {billingFromError?.billingCompanyAddress?.length > 0 && (*/}
  {/*      <span className="errorMessage">{billingFromError?.billingCompanyAddress}</span>*/}
  {/*  )}*/}
  {/*</div>*/}



      </form></>:""}

</section>
  </>
     :"" }
      <div>
        <button type="button" onClick={()=>setAddCoupon(!addCoupon)} className="common-btn mt-3 m-0">Apply Coupon</button>
            {/*<button type="button" className="common-btn">Apply discount</button>*/}
        {(addCoupon) ?
            <div className='gray-box-light p-3 mt-3 '>
              <div className="form-group">
              <input type={"text"} name="coupon" className={"form-control"} value={couponCode} onChange={(e)=>setCouponCode(e.target.value.trim())} placeholder={"Coupon Code"}/>
              <span>{couponValidationMessage}</span>
              <span>{couponInfoMessage}</span>
              </div>
              <div className="d-inline-flex pt-2">
              <button className={"common-btn m-0"} onClick={()=>applyCoupon()}>Apply</button>&nbsp; &nbsp;
              <button className={"cancel-btn m-0 ml-2"} onClick={()=>cancelApplyCoupon()}>Cancel</button>
              </div>
            </div>:""}
      </div>

      <div className="col-12 mt-5">
        {console.log(shippingAvailable,"shippingAvailable")}
        {(shippingAvailable === true) ?

            <>
              <div>
                {(codShippingAvailable)?
                    <>
        <button type="button" className="common-btn" onClick={(e)=>proceedPayment(e,'offline')}>Place Your Order and Pay Later</button></>:''}
        <button type="button" className="common-btn" onClick={(e)=>proceedPayment(e,'phonepay')}>Pay Using Phone Pay</button>
        <button type="button" className="common-btn" onClick={(e)=>proceedPayment(e,'razorpay')}>Pay Using Razor Pay</button>
              </div>
              </>
          :""}
        {/*<button type="button" className="common-btn" onClick={(e)=>proceedPayment(e)}>Continue To Payment</button>*/}
      </div>

    </div>
  )
}

export default CheckoutForm
