import React from 'react';

const RefundAndCacellation =()=>{

    return(
        <section >
            <div  className="container custom_container p0">
                {/*<h2><strong>Terms and Conditions</strong></h2>*/}

                <p>Welcome to Shikshak Solutions!</p>
                
                <p>Shikshak Solutions: Refund and Cancellation Policy</p>

                <p>At Shikshak Solutions, we are committed to providing the best possible educational services and products to our valued customers. However, we understand that there may be situations where you need to request a refund or cancel a service. </p>

                <p>To ensure transparency and fairness, we have established the following Refund and Cancellation Policy:</p>

                <h3><strong>1. Refund Policy:</strong></h3>
                
                <h5><strong>1.1 Course Refunds:</strong></h5>

                <p>Shikshak Solutions offers a 30-day money-back guarantee on all our courses. If you are not satisfied with a course you have purchased, you may request a full refund within 30 days from the date of purchase.</p>

                <h5><strong>1.2 Live Class Refunds:</strong></h5>

                <p>If you have enrolled in a live class and wish to cancel your registration, you may be eligible for a refund depending on the time of cancellation.</p>

                <p>Cancellation more than 48 hours before the scheduled class: Full refund.</p>

                <p>Cancellation within 48 hours of the scheduled class: No refund.</p>

                <h5><strong>1.3 Subscription Refunds:</strong></h5>

                <p>Subscriptions to our premium services are billed on a monthly or annual basis. You may cancel your subscription at any time, and you will not be billed for subsequent billing cycles.</p>

                <p>However, we do not offer refunds for partially used billing cycles.</p>

                <p>For example, if you cancel your monthly subscription halfway through the billing month, you will not receive a refund for the remaining days of that month.</p>

                <h5><strong>1.4 Refund Request Process:</strong></h5>

                <p>To request a refund, please contact our customer support team through the contact information provided on our website.</p>

                <p>Please include your order or transaction number and a clear explanation of the reason for your refund request.</p>

                <p>Refunds will be processed within 7-10 business days of receiving your request.</p>
            
                <h3><strong>2. Cancellation Policy:</strong></h3>
                
                <h5><strong>2.1 Course Cancellations:</strong></h5>

                <p>If you wish to cancel your enrollment in a course, you can do so at any time. </p>

                <p> However, please note that course access will be terminated immediately upon cancellation, and no refunds will be provided for the remaining duration of the course.</p>

                <h5><strong>2.2 Live Class Cancellations:</strong></h5>

                <p>To cancel your registration for a live class, please contact our customer support team at least 48 hours before the scheduled class.</p>

                <p>Cancellations made within 48 hours of the class will not be eligible for a refund.</p>

                <h5><strong>2.3 Subscription Cancellations:</strong></h5>

                <p>You can cancel your subscription at any time by visiting your account settings on our website.</p>

                <p>Once canceled, you will not be billed for future billing cycles.</p>

                <h3><strong>3. Exceptional Circumstances:</strong></h3>

                <p>Shikshak Solutions reserves the right to consider exceptional refund or cancellation requests on a case-by-case basis.</p> 
                
                <p>Such requests may be related to technical issues, medical emergencies, or other unforeseen circumstances.</p>

                <h3><strong>4. Contact Us:</strong></h3>

                <p>If you have any questions or need further clarification about our Refund and Cancellation Policy, please do not hesitate to contact our customer support team.</p>
                
                <p>We are here to assist you.</p>

                <h5>Note: This Refund and Cancellation Policy is subject to change without prior notice. Please review our policy regularly for any updates.</h5> 
                
                <p>Last updated on : <strong>01-Oct-2023</strong></p>
            </div>
        </section>
    )
}
export default RefundAndCacellation;
