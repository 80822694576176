import React, {useEffect, useState} from 'react';
import CustomNavbar from '../layout/CustomNavbar';
import Footer from '../layout/Footer';
import MyOrdercard from './MyOrdercard';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffectOnce} from "../../hooks/useEffectOnce";
import {
    actionGetOrderDetailsData,
    actionToGetTotalCartCount,
    actionToSendOrderPlacedEmail
} from "../../store/actions/shopAction";
import { ToastContainer,toast } from 'react-toastify';
import AuthService from "../services/auth.service";


function OrderPlaced() {
    let param = useParams();
    const navigate = useNavigate();
    let id = Object.values(param)[0];
    const [loading, setLoading] = useState(true);
    const [orderData, setOrderData] = useState([]);
    let dispatch = useDispatch();
    useEffectOnce( ()=>{
        if (id){
            const userInfo =  localStorage.getItem('user');
            let userRawData =   AuthService.parseJwt(JSON.parse(userInfo)?.accessToken);
            // Updating cart count
            dispatch(actionToGetTotalCartCount(userRawData?.user?.id));
             dispatch(actionGetOrderDetailsData({'id':id})).then((data) => {
                if(data.length) {
                    setOrderData(data[0]?.orderData);
                    setLoading(false);
                    if(data[0]?.orderData?.initial_order_email_send ==='0') {
                        dispatch(actionToSendOrderPlacedEmail(data[0]?.orderData));
                    }
                }
            });
        }
    });

    return (
        <>
            <CustomNavbar mClass="menu_four hosting_menu" nClass="w_menu m-auto" slogo="sticky_logo" hbtnClass="event_btn"/>
            {(!loading) ?
            
            <div>
                <section className={"sec_without_breadcrumb "}>
                <div className="container_account container-fluid">
                    <div className={"container"}>
                        <div className={"row"}>
                            <div className={"col-md-12 account-heading-title"}>
                                <h1>Order Status:</h1>
                            </div>
                        </div>
                    </div>
                </div>
                </section>
                <section className="col">
                    <div className={"container custom_container p0"}>
                        <div className={"row"}>
                            <div className={"col-md-12"}>
                                <MyOrdercard userCurrentOrderData={orderData} />
                            </div>
                        </div>
                    </div>
                </section>
            <ToastContainer/>
                <Footer/>
            </div>: "Loading Please Wait ..."
            }
        </>
    )
}

export default OrderPlaced
