import React from 'react'
import CustomNavbar from "../components/layout/CustomNavbar";
import Footer from "../components/layout/Footer";
import ForgotpasswordForm from '../components/Forgotpassword/ForgotpasswordForm';
import SignUpImage from "../components/signup/SignUpImage";




function Forgotpassword() {
  return (
    <>
    <CustomNavbar mClass="menu_four hosting_menu" nClass="w_menu m-auto" slogo="sticky_logo" hbtnClass="event_btn"/>
 
    <div>
        <section className={"sec_without_breadcrumb"}>
        <div className={"container_account container-fluid"}>
            <div className={"container"}>
                <div className={"row"}>
                    <div className={"col-md-12 account-heading-title"}>
                        <h1 className={"mb-0"}>Forgot your password</h1>
                    </div>
                </div>
            </div>
        </div>
        <div className={"container custom_container p0"}>
            <div className={"row"}>
                <div className='col-md-2'></div>
                <div className='col-md-8'>
                    <ForgotpasswordForm/>
                </div>
                <div className='col-md-2'></div>
            </div>
        </div>
    </section>
   </div>
    <Footer/>
    </>
  )
}

export default Forgotpassword
   


   
