import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Modal from "react-bootstrap/Modal";
import scanner from "../../image/payment/scanner.jpeg";
import {
    actionToClosePayAndUploadInvoiceModal,
    actionToGetAllUserOrdersByUserId,
    actionToUploadPaymentReceipt,
    actionGetOrderDetailsData
} from "../../store/actions/shopAction";
import AuthService from "../services/auth.service";
import CryptoJS from "crypto-js";
import {NotificationManager} from "react-notifications";
const ENCRYPTION_KEY = "XkhZG4fW2t2W";
function PayAndUploadInvoiceComponent() {
    const openClosePayUploadInvoiceModal = useSelector((state) => state.cartReducer.openClosePayUploadInvoiceModal);
    const [show, setShow] = useState(false);
    const [paymentReceipt, setPaymentReceipt] = useState('');
    const [fileError, setFileError] = useState('');
    const [orderData, setOrderData] = useState([]);
    const userInfo = localStorage.getItem('user');
    let userRawData =   AuthService.parseJwt(JSON.parse(userInfo)?.accessToken);

    const handleClose = () =>{
        dispatch(actionToClosePayAndUploadInvoiceModal());
    };
    const onFileChange = (event) => {
        if(event.target.files[0]) {
            setPaymentReceipt(event.target.files[0]);
            setFileError("");
        }else{
            NotificationManager.error('Please upload a file.');
        }
    };
    const dispatch = useDispatch();
    const uploadReceipt =()=>{
        if(paymentReceipt) {
            dispatch(actionToUploadPaymentReceipt({
                photo: paymentReceipt,
                id: orderData?.id
            })).then((response) => {
                if (response !== '') {
                    dispatch(actionToGetAllUserOrdersByUserId({'userId':userRawData?.user?.id}));
                    let encryptedId = CryptoJS.AES.encrypt(
                        JSON.stringify(orderData?.id),
                        ENCRYPTION_KEY
                    ).toString();
                    dispatch(actionGetOrderDetailsData({'id':encryptedId}));
                    NotificationManager.success('Payment receipt is uploaded successfully. Wait for admin approval.');
                    dispatch(actionToClosePayAndUploadInvoiceModal());
                }else{
                    setFileError('Something went wrong! Please try again.');
                    NotificationManager.error('Something went wrong! Please try again.');
                }
            });
        }else{
            setFileError('Please upload a file.')
        }
    }
    useEffect(()=>{
        setShow(openClosePayUploadInvoiceModal?.open);
        setOrderData(openClosePayUploadInvoiceModal?.orderData);
        console.log(openClosePayUploadInvoiceModal?.orderData,"orderData")
    },[openClosePayUploadInvoiceModal])
    return(
        <>
            <Modal show={show} onHide={handleClose} className={"invoice-modal-attachment"} size={"lg"} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Pay And Upload Invoice Or Receipt</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"container-fluid"}>
                        <div className={"row"}>
                            <div className={"col-md-12"}>
                                <div className={"note-text"}>Please pay the total amount using the QR code bellow; </div>
                                <div className={"note-text"}>After successfully payment please upload the invoice or receipt below. </div>
                                <div className={"note-text"}><b>Note: -</b>  Your order will be process after your uploaded invoice or receipt verified by the admin team. </div>
                            </div>
                        </div>
                        <div className={"row "}>
                            <div className={"col-md-4 text-center"}>
                                <div className={"captcha-border"}><img src={scanner} width={"210px"}  height={"210px"} className={""}/></div>
                            </div>
                            <div className={"col-md-8"}>
                                <div>&nbsp;</div>
                                <div className={"orderno"}><b>Order No</b> - {orderData?.order_number}</div>
                                <div className={"customername"}><b>Customer Email</b> - {userRawData?.user?.email}</div>
                                <div className={"customername"}><b>Customer Mobile No</b> - {userRawData?.user?.mobile}</div>
                                <div className={"totalitem"}><b>Total Items</b> - {orderData?.products?.length}</div>
                                <div className={"totalamount"}><b>Total Amount</b> - ₹{parseFloat(orderData?.netAmount).toFixed(2)}</div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-md-12"}>
                                <div>Upload Invoice Attachment</div>
                                <div> <input type="file" required className="form-control" name="image" onChange={(e)=>onFileChange(e)} />
                                    <span className={"errorMessage"}>{fileError}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='common-btn btn-sm' variant="primary" onClick={uploadReceipt}>
                        Upload
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )

}
export default PayAndUploadInvoiceComponent;