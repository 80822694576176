import React from 'react'
import Robo from '../../image/signup.png';
function SignUpImage() {
    return (
        <section>
          <div className='loginimg'>
            <img src={Robo} alt="..."/>
            </div>    
        </section>
    )
}

export default SignUpImage;
