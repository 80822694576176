import React from 'react';
import './Feedback.css';
import BpRadio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
function FeedbackForm() {
    return (
        <div className="feedback-form-container">

            <form>
                <h4>Trainer's Details</h4>

                <div className="form-group">
                    <label htmlFor="trainerName">Trainer's Name</label>
                    <input type="text" id="trainerName" name="trainerName" required />
                </div>

                <div className="form-group">
                    <label htmlFor="trainingDate">Completion Date of Training</label>
                    <input type="date" id="trainingDate" name="trainingDate"/>
                </div>

                <h4>Training Session Details</h4>

                <div className="form-group">
                    <label htmlFor="schoolName">Name of the School/Institution</label>
                    <input type="text" id="schoolName" name="schoolName" required />
                </div>

                {/*<div className="form-group">*/}
                {/*    <label htmlFor="studentsGrade">Grade Level of Students</label>*/}
                {/*    <input type="number" id="studentsGrade" name="studentsGrade" required />*/}
                {/*</div>*/}

                <div className="form-group">
                    <FormControl>
                        <FormLabel id="demo-customized-radios">Subject/Area of Training</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-customized-radios"
                            name="customized-radios"
                        >
                            <FormControlLabel value="robotics" control={<BpRadio />} label="Robotics" />
                            <FormControlLabel value="stem" control={<BpRadio />} label="STEM" />
                            <FormControlLabel value="iot" control={<BpRadio />} label="IoT" />
                            <FormControlLabel value="ai" control={<BpRadio />} label="AI" />
                            <FormControlLabel value="others" control={<BpRadio />} label="Others" />
                        </RadioGroup>
                    </FormControl>
                </div>

                <div className="form-group">
                    <label htmlFor="duration">Duration of Training Session(in Days)</label>
                    <input type="text" id="duration" name="duration"/>
                </div>

                <h5>1. Overall Rating</h5>
                <div className="form-group">
                    <label>Scale from 1 to 5 for overall satisfaction with the trainer's performance</label>
                    <Stack>
                        <Rating size="large" />
                    </Stack>
                </div>

                <h5>2. Punctuality of the Trainer</h5>
                <div className='form-group'>
                    <FormControl>
                        <FormLabel id="demo-customized-radios">What was the reporting time scheduled for the training session?</FormLabel>
                        <textarea rows="2" cols="80" required className="form-control" name="sortDesc" />
                    </FormControl>
                    <FormControl>
                        <FormLabel id="demo-customized-radios">Was the trainer reached on time during the training sessions?</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-customized-radios"
                            name="customized-radios">
                            <FormControlLabel value="yes" control={<BpRadio />} label="Yes" />
                            <FormControlLabel value="no" control={<BpRadio />} label="No" />
                        </RadioGroup>
                        <FormLabel id="demo-customized-radios">If no, please provide details:</FormLabel>
                        <textarea rows="2" cols="80" required className="form-control" name="sortDesc" />
                    </FormControl>
                </div>

                <h5>3. Discipline</h5>
                <div className='form-group'>
                    <FormControl>
                        <FormLabel id="demo-customized-radios">Did the trainer maintain discipline during the sessions?</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-customized-radios"
                            name="customized-radios">
                            <FormControlLabel value="yes" control={<BpRadio />} label="Yes" />
                            <FormControlLabel value="no" control={<BpRadio />} label="No" />
                        </RadioGroup>
                        <FormLabel id="demo-customized-radios">If no, please provide details:</FormLabel>
                        <textarea rows="2" cols="80" required className="form-control" name="sortDesc" />
                    </FormControl>
                </div>

                <h5>4. Content Delivery</h5>
                <div className='form-group'>
                    <FormControl>
                        <FormLabel id="demo-customized-radios">Clarity of Explanation:</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-customized-radios"
                            name="customized-radios"
                        >
                            <FormControlLabel value="excellent" control={<BpRadio />} label="Excellent" />
                            <FormControlLabel value="good" control={<BpRadio />} label="Good" />
                            <FormControlLabel value="fair" control={<BpRadio />} label="Fair" />
                            <FormControlLabel value="bad" control={<BpRadio />} label="Bad" />
                        </RadioGroup>
                    </FormControl>
                </div>

                {/*<div className='form-group'>*/}
                {/*    <FormControl>*/}
                {/*        <FormLabel id="demo-customized-radios">Engagement with Students/Teachers:</FormLabel>*/}
                {/*        <RadioGroup*/}
                {/*            row*/}
                {/*            aria-labelledby="demo-customized-radios"*/}
                {/*            name="customized-radios"*/}
                {/*        >*/}
                {/*            <FormControlLabel value="excellent" control={<BpRadio />} label="Excellent" />*/}
                {/*            <FormControlLabel value="good" control={<BpRadio />} label="Good" />*/}
                {/*            <FormControlLabel value="fair" control={<BpRadio />} label="Fair" />*/}
                {/*            <FormControlLabel value="bad" control={<BpRadio />} label="Bad" />*/}
                {/*        </RadioGroup>*/}
                {/*    </FormControl>*/}
                {/*</div>*/}

                <div className='form-group'>
                    <FormControl>
                        <FormLabel id="demo-customized-radios">Relevance of Content:</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-customized-radios"
                            name="customized-radios"
                        >
                            <FormControlLabel value="excellent" control={<BpRadio />} label="Excellent" />
                            <FormControlLabel value="good" control={<BpRadio />} label="Good" />
                            <FormControlLabel value="fair" control={<BpRadio />} label="Fair" />
                            <FormControlLabel value="bad" control={<BpRadio />} label="Bad" />
                        </RadioGroup>
                    </FormControl>
                </div>

                <h5>5. Communication Skills</h5>
                <div className='form-group'>
                    <FormControl>
                        <FormLabel id="demo-customized-radios">Ability to Communicate Complex Concepts:</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-customized-radios"
                            name="customized-radios">
                            <FormControlLabel value="yes" control={<BpRadio />} label="Yes" />
                            <FormControlLabel value="no" control={<BpRadio />} label="No" />
                        </RadioGroup>
                        <FormLabel id="demo-customized-radios">If no, please provide details:</FormLabel>
                        <textarea rows="2" cols="80" required className="form-control" name="sortDesc" />
                    </FormControl>
                </div>

                <div className='form-group'>
                    <FormControl>
                        <FormLabel id="demo-customized-radios">Interaction with Students/Teachers:</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-customized-radios"
                            name="customized-radios"
                        >
                            <FormControlLabel value="excellent" control={<BpRadio />} label="Excellent" />
                            <FormControlLabel value="good" control={<BpRadio />} label="Good" />
                            <FormControlLabel value="fair" control={<BpRadio />} label="Fair" />
                            <FormControlLabel value="bad" control={<BpRadio />} label="Bad" />
                        </RadioGroup>
                    </FormControl>
                </div>

                <div className='form-group'>
                    <FormControl>
                        <FormLabel id="demo-customized-radios">Listening Skills:</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-customized-radios"
                            name="customized-radios"
                        >
                            <FormControlLabel value="excellent" control={<BpRadio />} label="Excellent" />
                            <FormControlLabel value="good" control={<BpRadio />} label="Good" />
                            <FormControlLabel value="fair" control={<BpRadio />} label="Fair" />
                            <FormControlLabel value="bad" control={<BpRadio />} label="Bad" />
                        </RadioGroup>
                    </FormControl>
                </div>

                <h5>6. Technical Knowledge</h5>
                <div className='form-group'>
                    <FormControl>
                        <FormLabel id="demo-customized-radios">Depth of Knowledge in Robotics/STEM/IoT/AI or particular Subject:</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-customized-radios"
                            name="customized-radios"
                        >
                            <FormControlLabel value="excellent" control={<BpRadio />} label="Excellent" />
                            <FormControlLabel value="good" control={<BpRadio />} label="Good" />
                            <FormControlLabel value="fair" control={<BpRadio />} label="Fair" />
                            <FormControlLabel value="bad" control={<BpRadio />} label="Bad" />
                        </RadioGroup>
                    </FormControl>
                </div>

                <div className='form-group'>
                    <FormControl>
                        <FormLabel id="demo-customized-radios">Ability to Answer Student/Teacher's Questions:</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-customized-radios"
                            name="customized-radios"
                        >
                            <FormControlLabel value="excellent" control={<BpRadio />} label="Excellent" />
                            <FormControlLabel value="good" control={<BpRadio />} label="Good" />
                            <FormControlLabel value="fair" control={<BpRadio />} label="Fair" />
                            <FormControlLabel value="bad" control={<BpRadio />} label="Bad" />
                        </RadioGroup>
                    </FormControl>
                </div>

                <div className='form-group'>
                    <FormControl>
                        <FormLabel id="demo-customized-radios">Familiarity with Tools and Technologies:</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-customized-radios"
                            name="customized-radios"
                        >
                            <FormControlLabel value="excellent" control={<BpRadio />} label="Excellent" />
                            <FormControlLabel value="good" control={<BpRadio />} label="Good" />
                            <FormControlLabel value="fair" control={<BpRadio />} label="Fair" />
                            <FormControlLabel value="bad" control={<BpRadio />} label="Bad" />
                        </RadioGroup>
                    </FormControl>
                </div>

                <h5>7. Classroom Management</h5>
                <div className='form-group'>
                    <FormControl>
                        <FormLabel id="demo-customized-radios">Ability to Maintain Discipline in the classroom during training sessions:</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-customized-radios"
                            name="customized-radios">
                            <FormControlLabel value="yes" control={<BpRadio />} label="Yes" />
                            <FormControlLabel value="no" control={<BpRadio />} label="No" />
                        </RadioGroup>
                        <FormLabel id="demo-customized-radios">If no, please provide details:</FormLabel>
                        <textarea rows="2" cols="80" required className="form-control" name="sortDesc" />
                    </FormControl>
                </div>

                <div className='form-group'>
                    <FormControl>
                        <FormLabel id="demo-customized-radios">Time Management for syllabus during sessions:</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-customized-radios"
                            name="customized-radios"
                        >
                            <FormControlLabel value="excellent" control={<BpRadio />} label="Excellent" />
                            <FormControlLabel value="good" control={<BpRadio />} label="Good" />
                            <FormControlLabel value="fair" control={<BpRadio />} label="Fair" />
                            <FormControlLabel value="bad" control={<BpRadio />} label="Bad" />
                        </RadioGroup>
                    </FormControl>
                </div>

                <div className='form-group'>
                    <FormControl>
                        <FormLabel id="demo-customized-radios">Handling of Technical Issues:</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-customized-radios"
                            name="customized-radios"
                        >
                            <FormControlLabel value="excellent" control={<BpRadio />} label="Excellent" />
                            <FormControlLabel value="good" control={<BpRadio />} label="Good" />
                            <FormControlLabel value="fair" control={<BpRadio />} label="Fair" />
                            <FormControlLabel value="bad" control={<BpRadio />} label="Bad" />
                        </RadioGroup>
                    </FormControl>
                </div>



                <h5>8. Feedback Comments:</h5>
                <div className="form-group">
                    <textarea rows="4" cols="80" required className="form-control" name="sortDesc" />
                </div>

                <h5>9. Recommendations if any:</h5>
                <div className="form-group">
                    <textarea rows="2" cols="80" required className="form-control" name="sortDesc" />
                </div>

                <button type="submit">Submit</button>
            </form>
        </div>
    );
}

export default FeedbackForm;
