import React, {useState} from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import {useDispatch} from 'react-redux';
import {actionAddToCart} from "../../store/actions/shopAction";
import moment from "moment/moment";
import AuthService from "../services/auth.service";
import {NotificationManager} from "react-notifications";

function ProductComming({productDetailsData}) {
    const [quantity, setQuantity] = useState(1);
    const dispatch  = useDispatch();
    const userInfo =  localStorage.getItem('user');
    let userRawData =   AuthService.parseJwt(JSON.parse(userInfo)?.accessToken);
    const handleInputChange = (e) => {
        const value = e.target.value;
        // Allow only positive numbers
        if (/^[0-9]*$/.test(value) && (value>0 || value ==="")) {
            setQuantity(value);
        }
    };

    const addToCart = () => {
            if (quantity > 0) {
                let payload = {
                    'productId': productDetailsData?.id,
                    'qty': parseInt(quantity),
                    'userId': userRawData?.user?.id,
                    'createdAt': moment().format("YYYY-MM-DD HH:mm:ss"),
                    'name': productDetailsData?.name,
                    'photo': productDetailsData?.photo,
                    'price': productDetailsData?.price,
                }
                dispatch(actionAddToCart(payload)).then(async (responseAddToCart)=>{
                    if(responseAddToCart){
                        NotificationManager.success("Product has been added to cart.");
                    }else{
                        NotificationManager.success("Product can`t add to cart please try again later.");

                    }

                    setQuantity(1);
                })

            }
    }

  return (

    <div>
     <section>
         <div className='product-head'>
             <h4 className='mb-1'>{productDetailsData?.name}</h4>
             <h6 className='mb-4'>SKU:DGYT000{productDetailsData?.id}</h6>
          </div>
        <h4 className='mb-4 price'>₹ {productDetailsData?.price}</h4>
        <div className="d-inline-flex border-bottom pb-4 w-100">
            <label className="visually-hidden" htmlFor="specificSizeInputName">Name</label>
            <input type="text" className="pform-control" value={quantity} onChange={(e)=>handleInputChange(e)} id="specificSizeInputName" placeholder="Qty"/>
            <button type="button" onClick={() => addToCart()} className="common-btn1">Add To Cart</button>
        </div>
         <p className='p-description' dangerouslySetInnerHTML={{__html:productDetailsData?.sortDesc}}></p>
     </section>
        <div className={"border-bottom pb-4"}>
        <h5>Product's Description:</h5>
        <p className='p-description mb-0' dangerouslySetInnerHTML={{__html:productDetailsData?.description}}></p>
        </div>
    </div>
  )
}

export default ProductComming;

