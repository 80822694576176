import React from 'react';
import {useNavigate} from 'react-router-dom';
import CryptoJS from "crypto-js";
import moment from "moment";

function ProductReviewsImagesComponent({reviewPhotos}) {
    return (
        (reviewPhotos && Object.values(reviewPhotos)?.length>0) ?
            <>
                <div>
                    <div className="gray-box-light mt-5">
                            <div className="card-group col-md-12">
                                <>
                                    {Object.values(reviewPhotos)?.map((row,key) => (
                                        <div className="d-block" key={"photo_"+key}>
                                            <div className="card-body">
                                                <img className="playerProfilePic_home_tile"  style={{width:100,heigh:100}} src={row}></img>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            </div>
                    </div>
                </div>
            </>:""
    )
}

export default ProductReviewsImagesComponent
