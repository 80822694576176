import React from 'react'

function PdfPreview() {
  return (
    <div>
      <h2 className='mb-4 container fw-bold text-dark'>INVOICE</h2>
    </div>
  )
}

export default PdfPreview
