import React, {useEffect, useState} from 'react';
import './OrderTracking.css';

const OrderTracking = ({status}) => {
  const [orderStatus, setOrderStatus] = useState('processing');

  useEffect(()=>{
    setOrderStatus(status);
  },[status]);

  return (
    <div className="order-tracking">
      <div className="status-container">
          <div className={`status ${orderStatus === 'Ordered' ? 'active' : ''}`}>
              Ordered
          </div>
        <div className={`status ${orderStatus === 'processing' ? 'active' : ''}`}>
            Processing
        </div>
        <div className={`status ${orderStatus === 'shipping' ? 'active' : ''}`}>
            Shipped
        </div>
        <div className={`status ${orderStatus === 'delivered' ? 'active' : ''}`}>
            Delivered
        </div>

        {(orderStatus === 'cancel') ?
            <>
              <div className={`status ${orderStatus === 'cancel' ? 'active' : ''}`}>
                Canceled
              </div>
            </>:''
        }
      </div>
      <div className={"order-tracking-value"}>
        <div className={`status-value ${orderStatus === 'Ordered' ? 'active' : ''}`}></div>
        <div className={`status-value ${orderStatus === 'Ordered' ? 'active' : ''}`}></div>
        <div className={`status-value ${orderStatus === 'Ordered' ? 'active' : ''}`}></div>
        <div className={`status-value ${orderStatus === 'Ordered' ? 'active' : ''}`}></div>
      </div>
    </div>
  );
};

export default OrderTracking;
