import React from 'react'

function InvoiceTC() {
  return (
    <div>
      <h6 className='mb-4 container fw-bold'>TERMS AND CONDITIONS</h6>
      <h6 className='mb-4 container text-dark '>1. jhdc sjjd sjhbf whefb whgevf whgevfe wewjd3h34 hehbd whbd hwe3 jhwefe ueh</h6>
      <h6 className='mb-4 container text-dark'>2. dhbceh ehfh wedjb jhed wejjhj wureru3 qwshw wdhbh hd</h6>
      <h6 className='mb-4 container text-dark'>3. hdf jdn dvgweg qjsiuqw gvgvsf qqwg3g qhbd qqwdvg gsb</h6>
    </div>
  )
}

export default InvoiceTC
