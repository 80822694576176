import React, {useState} from 'react'
import {useEffectOnce} from "../../hooks/useEffectOnce";
import AuthService from "../services/auth.service";
import {NotificationManager,NotificationContainer} from "react-notifications";
function MyAccountInfo() {
  const userInfo =  localStorage.getItem('user');
  const [userData, setUserData] = useState([]);
  const [inputDisable, setInputDisable] = useState(true);
  const [name,setName]= useState(null);
  const [email,setEmail]= useState(null);
  const [gender,setGender]= useState(null);
  const [mobileNumber,setMobileNumber]= useState(null);
  const [disableActionButton, setDisableActionButton] = useState(false);
  const [formErrors,setFormErrors] = useState({
    name:"",
    email: "",
    mobileNumber:"",
    gender:""
  });
  const [serverErrors,setServerErrors] = useState({
    name:"",
    email: "",
    mobileNumber:"",
    gender:""
  });

  const emailRegex = RegExp(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  );
  const pwdRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
  useEffectOnce(()=>{
    let userRawData =   AuthService.parseJwt(JSON.parse(userInfo)?.accessToken);
    setUserData(userRawData?.user)
    setName(userRawData?.user?.name);
    setEmail(userRawData?.user?.email);
    setGender(userRawData?.user?.gender);
    setMobileNumber(userRawData?.user?.mobile);
    setDisableActionButton(false);
  },[]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(formValid()){
      let data = {id:userData?.id,name: name,  email: email,gender:gender, mobile: mobileNumber,password:userData.password,role:userData.role}
      try {
        setDisableActionButton(true);
        await AuthService.updateUserPersonalInfo(data).then(
            async (response) => {
              // check for token and user already exists with 200
              if(response?.errors){
                let serverError = { ...serverErrors };
                serverError.email = response?.errors[0]?.msg;
                setServerErrors(serverError);
              }else{
                let user =  await AuthService.parseJwt(response.accessToken);
                let value  = user.user
                setInputDisable(true);
                NotificationManager.success("Profile updated successfully.");

              }
              setDisableActionButton(false);

            },
            (error) => {
              console.log(error);
              setDisableActionButton(false);
            }
        );
      } catch (err) {
        console.log(err);
        setDisableActionButton(false);
      }

    }

  }
  const handelCancelUpdate = async (e)=>{
    e.preventDefault();
    setName(userData?.name);
    setEmail(userData?.email);
    setGender(userData?.gender);
    setMobileNumber(userData?.mobile);
    setInputDisable(true);
    setFormErrors({
      name:"",
      email: "",
      mobileNumber:"",
      gender:""
    });
  }
  const formValid = () => {
    let valid = true;
    // validate form errors being empty
    Object.values(formErrors).forEach(val => {
      val.length > 0 && (valid = false);
    });
    let inputArray = {
      name:name,
      email: email,
      mobileNumber:mobileNumber,
      gender:gender
    }
    let errorArray = {
      name:"Please Fill Name",
      email: "Please Fill Email",
      mobileNumber:"Please Fill Mobile Number",
      gender:"Please Select your gender",
    }
    let formError ={...formErrors};
    // validate the form was filled out
    Object.keys(inputArray).map((key) => {
      let val = inputArray[key];
      key === 'agree' ? val === false && (valid = false) :
          val === null && (valid = false);
      if((key === 'agree' && val === false) || val ===null)
        formError[key] = errorArray[key]
    });
    setFormErrors(formError);
    return valid;
  };
  const handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    let formError = { ...formErrors };

    switch (name) {
      case "name":
        setName(value);
        formError.name = value.length <1 ? "Please Fill First name" :
            value.length < 3 ? "Minimum 3 Characters required" : "";
        break;
      case "email":
        setEmail(value);
        formError.email = value.length <1 ? "Please Fill Email" : emailRegex.test(value)
            ? ""
            : "Invalid Email Address";
        break;
      case "mobileNumber":
        setMobileNumber(value);
        formError.mobileNumber = value.length <1 ? "Please Fill Mobile number" :
            value.length <10  ? "Please enter Valid Mobile Number" : "";
        break;
      case "gender":
        setGender(value);
        formError.gender = value.length <1 ? "Please select your gender" : "";
        break;
      default:
        break;
    }
    setFormErrors(formError);
  };
  return (
    <div>
      <div className={"d-inline-flex border-bottom pb-3 w-100"}><h5 className='container m-0'>Personal Information </h5> <div className={"d-inline-flex"}>{(inputDisable) ? <a className={"common-btn"} href="javascript:void(0);" onClick={()=>setInputDisable(false)}> Edit</a>:<div className={'d-inline-flex'}><button type="submit" className="common-btn m-0" disabled={disableActionButton}  onClick={handleSubmit}>Update</button> <a className={"cancel-btn ml-3"} href="javascript:void(0);" onClick={(e)=>handelCancelUpdate(e)}> Cancel</a></div>}</div></div>
      <div className="row mb-3">
        <div className="col-sm-5">
          <div className="card">
            <div className="card-body">
              <div className="card-title">Name</div>
              <input className="card-text form-control" type={"text"} name="name" placeholder="Name" value={name} disabled={inputDisable} onChange={handleChange}></input>
              {formErrors.name.length > 0 && (
                  <span className="errorMessage">{formErrors.name}</span>
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-5">
          <div className="card">
            <div className="card-body">
              <div className="card-title">Mobile Number</div>
              <input className="card-text form-control" type={"text"} name="mobileNumber" placeholder="Mobile Number" value={mobileNumber} disabled={inputDisable} onChange={handleChange}></input>
              {formErrors.mobileNumber.length > 0 && (
                  <span className="errorMessage">{formErrors.mobileNumber}</span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-sm-5">
          <div className="card">
            <div className="card-body">
              <div className="card-title">Email</div>
              <input className="card-text form-control" type={"text"} name="email" placeholder="Email"  value={email} disabled={inputDisable} onChange={handleChange}></input>
              {formErrors.email.length > 0 && (
                  <span className="errorMessage">{formErrors.email}</span>
              )}
              {serverErrors.email.length > 0 && (
                  <span className="errorMessage">{serverErrors.email}</span>
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-5">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Gender</h5>
              <select className="card-text form-control" name={"gender"} value={gender} disabled={inputDisable}  onChange={handleChange}>
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
              {formErrors.gender.length > 0 && (
                  <span className="errorMessage">{formErrors.gender}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    
  )
}

export default MyAccountInfo
