import React, { useEffect, useState } from 'react'
import Footer from "../components/layout/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavLink } from 'react-router-dom';
import CustomNavbar from "../components/layout/CustomNavbar";
import CheckoutForm from '../components/CheckOut/CheckoutForm';
import Checkouttable from '../components/CheckOut/Checkouttable';
import PreLoader from "../components/product/PreLoader";
import {useDispatch, useSelector} from "react-redux";
import {useEffectOnce} from "../hooks/useEffectOnce";
import AuthService from "../components/services/auth.service";
import {
    actionToGetAllStateList,
    getAllPincodeDetails,
    getUserCartDetails,
    getUserSavedAddress
} from "../store/actions/shopAction";


function Checkout() {

    const dispatch  = useDispatch();
    useEffectOnce(()=>{
        const userInfo =  localStorage.getItem('user');
        let userRawData =   AuthService.parseJwt(JSON.parse(userInfo)?.accessToken);
        dispatch(getUserCartDetails(userRawData?.user?.id));
        dispatch(getUserSavedAddress(userRawData?.user?.id));
        dispatch(getAllPincodeDetails());
        dispatch(actionToGetAllStateList());
    });

    const userAllCartData = useSelector((state) => state.cartReducer.userCartData);
    const allPinCodeData = useSelector((state) => state.cartReducer.allPinCodeData);
    const userSavedAddressesData = useSelector((state) => state.cartReducer.userSavedAddressesData);
    const allStateList = useSelector((state) => state.cartReducer.allStateList);
    const [couponDiscountPercentage, setCouponDiscountPercentage] =useState(0);
    const [couponMaximumDiscountAmount, setCouponMaximumDiscountAmount] =useState(0);
    const [couponCode, setCouponCode] =useState("");
    const [freeShipping, setFreeShipping] = useState(true);
    const [shippingCharge, setShippingCharge] = useState(0);
    const [calculatedShippingCharge, setCalculatedShippingCharge] = useState(0);
    const [minimumOrderAmountToFreeShipping, setMinimumOrderAmountToFreeShipping] = useState(0);
        // useEffect(()=>{
        //     console.log(allStateList,"allStateList")
        // },[allStateList])

  return (
    <>
    <CustomNavbar mClass="menu_four hosting_menu" nClass="w_menu m-auto" slogo="sticky_logo" hbtnClass="event_btn"/>
      
    <section className="sec_without_breadcrumb">
        <div className="container_account container-fluid">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 account-heading-title"><h1 className="mb-0">Checkout</h1></div>
                </div>
            </div>
        </div>
        <div className={"container custom_container p0"}>
            <div className={"row"}>
                {(userAllCartData?.loading) ? <PreLoader /> :
                    <>
                        {(userAllCartData?.data?.length) ?
                            <>
                <div className={"col-md-3"}>
                    <CheckoutForm userAllCartData={userAllCartData?.data} userSavedAddressesData={userSavedAddressesData} setCouponDiscountPercentage={setCouponDiscountPercentage} setCouponMaximumDiscountAmount={setCouponMaximumDiscountAmount} setCouponCode={setCouponCode} couponCode={couponCode} couponDiscountPercentage={couponDiscountPercentage} couponMaximumDiscountAmount={couponMaximumDiscountAmount} allPinCodeData={allPinCodeData} setFreeShipping={setFreeShipping} setShippingCharge={setShippingCharge} setMinimumOrderAmountToFreeShipping={setMinimumOrderAmountToFreeShipping} calculatedShippingCharge={calculatedShippingCharge} allStateList={allStateList}/>
                </div>
                <div className={"col-md-9"}>
                    <Checkouttable userAllCartData={userAllCartData?.data} couponDiscountPercentage={couponDiscountPercentage} couponMaximumDiscountAmount={couponMaximumDiscountAmount} couponCode={couponCode} freeShipping={freeShipping} shippingCharge={shippingCharge} minimumOrderAmountToFreeShipping={minimumOrderAmountToFreeShipping} setCalculatedShippingCharge={setCalculatedShippingCharge}/>
                    {/*<div className="col-12 mt-5">
   <a href='#'><button type="submit" className="btn btn-primary">Continue to payment</button></a> 
  </div> */}
                </div></>:" Your Cart is empty"}
                    </>}
               
            </div>
           
        </div>
    </section>

    <Footer/>
    </>
  )
}

export default Checkout


    
