import React, {useEffect, useState} from 'react';
import CustomNavbar from '../layout/CustomNavbar';
import Footer from '../layout/Footer';
import MyOrdercard from './MyOrdercard';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffectOnce} from "../../hooks/useEffectOnce";
import {
    actionGetOrderDetailsData,
    actionToGetTotalCartCount,
    actionToSendOrderPlacedEmail
} from "../../store/actions/shopAction";
import { ToastContainer,toast } from 'react-toastify';
import AuthService from "../services/auth.service";


function PaymentFailure() {
    let param = useParams();
    const navigate = useNavigate();
    let id = Object.values(param)[0];
    let dispatch = useDispatch();
    useEffectOnce( ()=>{
        if (id){

        }
    });

    return (
        <>
            <CustomNavbar mClass="menu_four hosting_menu" nClass="w_menu m-auto" slogo="sticky_logo" hbtnClass="event_btn"/>

                <div>
                    <section className={"sec_without_breadcrumb "}>
                        <div className="container_account container-fluid">
                            <div className={"container"}>
                                <div className={"row"}>
                                    <div className={"col-md-12 account-heading-title"}>
                                        <h1>Payment Failed</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="col">
                        <div className={"container custom_container p0"}>
                            <div className={"row"}>
                                <div className={"col-md-12"}>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer/>
                </div>
            }
        </>
    )
}

export default PaymentFailure
