import React from 'react';
import CustomNavbar from '../layout/CustomNavbar';
import Footer from '../layout/Footer';
import MyAccountProfile from './MyAccountProfile';
import MyAccountInfo from './MyAccountInfo';
import AccountSettings from "./AccountSettings";
import BillingPayments from "./BillingPayments";
import TrackOrders from './TrackOrders';
import GiftCards from './GiftCards';
import {useNavigate} from 'react-router-dom';
import {useEffectOnce} from "../../hooks/useEffectOnce";
import {  Route,Routes } from "react-router-dom";
import ListOfOrders from "../my order/ListOfOrders";
import MyWallet from './MyWallet';

function MyAccount() {
    const navigate = useNavigate();
    useEffectOnce(()=>{
        if(!localStorage.getItem('user')){
            navigate("/login");
        }

    });
  return (
    <>
        <CustomNavbar mClass="menu_four hosting_menu" nClass="w_menu m-auto" slogo="sticky_logo" hbtnClass="event_btn"/>
        <section className="sec_without_breadcrumb ">
            <div className='container_account container-fluid'>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col-md-12 account-heading-title"}>
                        <h1>My Account Details</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"container custom_container p0"}>
                 <h4 className='mb-5 container fw-bold mt-5'></h4>
                    <div className={"row"}>
                        <div className={"col-md-3"}>

                            <MyAccountProfile/>

                        </div>
                        <div className={"col-md-9"}>
                            <Routes>
                                <Route exact path="/personal-info" element={<MyAccountInfo/>}/>
                                <Route exact path="/order-history" element={<ListOfOrders/>}/>
                                <Route exact path="/account-settings" element={<AccountSettings />}/>
                                <Route exact path="/billing-payments" element={<BillingPayments />}/>
                                <Route exact path="/my-wallet" element={<MyWallet />}/>
                                <Route exact path="/track-orders" element={<TrackOrders />}/>
                                <Route exact path="/gift-cards" element={<GiftCards />}/>
                            </Routes>
                        </div>
                    </div>
                </div>
        </section>
    <Footer/>
    
    </>

  )
}

export default MyAccount
