import React, { useState } from 'react';

function GiftCards() {
  const [voucherCode, setVoucherCode] = useState('');
  const [voucherPin, setVoucherPin] = useState('');
  const [voucherBalance, setVoucherBalance] = useState(null);

  const handleVoucherCodeChange = (e) => {
    setVoucherCode(e.target.value);
  };

  const handleVoucherPinChange = (e) => {
    setVoucherPin(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (voucherCode === 'EGIFT123' && voucherPin === '1234') {
        setVoucherBalance(100); // Set the balance to 100 (replace with the actual balance)
      } else {
        setVoucherBalance(null); // Set balance to null for invalid voucher code/pin
      }
    };

  return (
    <div className="form-group text_box">
      <div class={"d-inline-flex border-bottom pb-3 w-100"}>
        <h5 className='m-0'>Check E-Gift Voucher Balance</h5>
      </div>

        <form onSubmit={handleFormSubmit}>
          <div className={"mt-3 gray-box-light"}>
        <div><input className='form-control'
          type="text"
          value={voucherCode}
          onChange={handleVoucherCodeChange}
          placeholder="Enter Voucher Code"
        /></div>
        <div className={"mt-3"}>
          <input className='form-control'
            type="password"
            value={voucherPin}
            onChange={handleVoucherPinChange}
            placeholder="Enter Voucher Pin"
          />
        </div>
          </div>
         <button className='common-btn m-0 mt-3' type="submit">Check Balance</button>
        </form>

    {voucherBalance !== null && (
      <p>
        Voucher Balance: <strong>Rs.{voucherBalance}</strong>
      </p>
    )}
  </div>
  );
}

export default GiftCards;
